import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io'
import "../styles/SettingsPage.css";
import CheckboxToggle from '@bit/nexxtway.react-rainbow.checkbox-toggle';
import { LINKS } from '../util/data';
import { updateNotificationSetting, sendEmail, useProfile } from '../util/util';
import { useSelector, useDispatch } from 'react-redux';
import { REQUEST_LOGOUT } from '../servicelayer/servicelayer-actions';
const SettingsPage = () => {
  // const requireFollowRequests = useSelector(state => state.requireFollowRequests);
  // const notifNotifications = useSelector(state => state.notifNotifications);
  // const notifMessages = useSelector(state => state.notifMessages);
  // const notifEvents = useSelector(state => state.notifEvents);
  // const notifInvites = useSelector(state => state.notifInvites);
  // const notifFollowRequests = useSelector(state=>state.notifFollowRequests);
  // const notifLikesComments = useSelector(state =>state.notifLikesComments);
  const dispatch = useDispatch();
  const [requireFollowRequests, setRequireFollowRequests] = useState(false);
  const [notifNotifications, setNotifNotifications] = useState(false);
  const [notifMessages, setNotifMessages] = useState(false);
  const [notifEvents, setNotifEvents] = useState(false);
  const [notifInvites, setNotifInvites] = useState(false);
  const [notifFollowRequests, setNotifFollowRequests] = useState(false);
  const [notifLikesComments, setNotifLikesComments] = useState(false);
  const loggedInUser = useSelector(state => state.user);

  //console.log('loggedinUser:', loggedInUser);

  const [profile] = useProfile(loggedInUser ? loggedInUser.uid : null);

  useEffect(_ => {
    //console.log('profile:', profile)
    if(profile){
      loadSetting();
    }

  }, [profile]);
  const loadSetting = () => {
    let Noti = profile.isNotification ? true : false;
    let msgNoti = profile.isMsgNotification ? true : false;
    let eventNoti = profile.isEventNotification ? true : false;
    let inviteNoti = profile.isInviteNotification ? true : false;
    let followNoti = profile.isFollowNotification ? true : false;
    let likeNoti = profile.isLikeNotification ? true : false;
    let allowNoti = profile.allowNotification ? true : false;

    //console.log('loadSetting()', Noti, msgNoti, eventNoti, inviteNoti, followNoti, likeNoti);

    if (Noti) setNotifNotifications(true);
    else setNotifNotifications(false);

    if (msgNoti) setNotifMessages(true);
    else setNotifMessages(false);

    if (eventNoti) setNotifEvents(true);
    else setNotifEvents(false);

    if (inviteNoti) setNotifInvites(true);
    else setNotifInvites(false);

    if (followNoti) setNotifFollowRequests(true);
    else setNotifFollowRequests(false);

    if (likeNoti) setNotifLikesComments(true);
    else setNotifLikesComments(false);

    if(allowNoti) setRequireFollowRequests(true);
    else setRequireFollowRequests(false);


  }

  const updateSetting = (key, value) => {
    updateNotificationSetting(key, value, result => {
      console.log('notification setting was updated.. result:', result);
    });
  }

  const sendEmailSupport = () => {
    sendEmail(
      'support@turnup.us',
      'TurnUp support',
      ''
    ).then(() => {
      console.log('Our email successful provided to device mail ');
    });
  }
  const onClickLogOut = () => {
    console.log('onClickLogout.');
     dispatch({type : REQUEST_LOGOUT, payload : {} });

  }
  return (
    <Container className="settings">
      <Row>
        <Col><h1>Settings</h1></Col>
      </Row>
      <Row xs={1} lg={3}>
        <Col className="toggles">
          <Row xs={1}>
            <Col><Button>REFER A FRIEND</Button></Col>
            <Col>
              <CheckboxToggle
                label="Require Follow Requests"
                value={requireFollowRequests}
                onChange={() => {
                  updateSetting('allowFollowRequest',!requireFollowRequests)
                  setRequireFollowRequests(!requireFollowRequests)
                }
              }
              />
            </Col>
            <Col><Button>RESET PASSWORD</Button></Col>
            <Col><p>Notifications</p></Col>
            <Col>
              <CheckboxToggle
                label="Notifications"
                value={notifNotifications}
                onChange={() => {
                  updateSetting('isNotification',!notifNotifications)
                  setNotifNotifications(!notifNotifications);
                }}
              />
            </Col>
            <Col>
              <CheckboxToggle
                label="Messages"
                value={notifMessages}
                onChange={() => {
                  updateSetting('isMsgNotification',!notifMessages)
                  setNotifMessages(!notifMessages)}
                }
              />
            </Col>
            <Col>
              <CheckboxToggle
                label="Events"
                value={notifEvents}
                onChange={() => {
                  updateSetting('isEventNotification',!notifEvents)
                  setNotifEvents(!notifEvents)}
                }
              />
            </Col>
            <Col>
              <CheckboxToggle
                label="Invites"
                value={notifInvites}
                onChange={() => {
                  updateSetting('isInviteNotification',!notifInvites)
                  setNotifInvites(!notifInvites)}
                }
              />
            </Col>
            <Col>
              <CheckboxToggle
                label="Follow Requests"
                value={notifFollowRequests}
                onChange={() => {
                  updateSetting('isFollowNotification',!notifFollowRequests)
                  setNotifFollowRequests(!notifFollowRequests)}
                }
              />
            </Col>
            <Col>
              <CheckboxToggle
                label="Likes &amp; Comments"
                value={notifLikesComments}
                onChange={() => {
                  updateSetting('isLikeNotification',!notifLikesComments)
                  setNotifLikesComments(!notifLikesComments)}
                }
              />
            </Col>
            <Col><hr></hr></Col>
            <Col><Button className="log-out" onClick={() => onClickLogOut()}>LOG OUT</Button></Col>
            <Col><Button className="delete-account">DELETE ACCOUNT</Button></Col>
            <Col>
              <a href={LINKS.URL_REQUEST_GOOGLE_FORM} target="_blank" rel="noopener noreferrer">
                <Button>REQUEST TO BECOME AN ORG</Button>
              </a>
            </Col>
          </Row>
        </Col>
        <Col className="actions">
          <Button className="hide">
            <p>App Walkthrough</p>
            <p>Set the app walkthrough again</p>
            <IoIosArrowForward />
          </Button>
          <Button onClick={() => sendEmailSupport()}>
            <p>Email Support</p>
            <p>Click to email support</p>
            <IoIosArrowForward />
          </Button>
          <Button>
            <p>Block Users</p>
            <p>Manage your blocked users</p>
            <IoIosArrowForward />
          </Button>
          <Button>
            <p>Share Profile to Social Media</p>
            <p>Share profile to social media</p>
            <IoIosArrowForward />
          </Button>
          <a href={LINKS.URL_PRIVATE_POLICY} target="_blank" rel="noopener noreferrer">
            <Button>
              <p>Privacy Policy &amp; Terms</p>
              <IoIosArrowForward />
            </Button>
          </a>
          <a href={LINKS.URL_OUR_VALUE} target="_blank" rel="noopener noreferrer">
            <Button>
              <p>Our Values</p>
              <IoIosArrowForward />
            </Button>
          </a>
          <a href={LINKS.URL_GIVE_FEEDBACK} target="_blank" rel="noopener noreferrer">
            <Button>
              <p>Give Feedback</p>
              <IoIosArrowForward />
            </Button>
          </a>
          <a href={LINKS.URL_DONATE} target="_blank" rel="noopener noreferrer">
            <Button>
              <p>Donate</p>
              <IoIosArrowForward />
            </Button>
          </a>
        </Col>
        <Col className="actions">
          <a href={LINKS.URL_JOIN_TURNUP} target="_blank" rel="noopener noreferrer">
            <Button>
              <p>Join TurnUp's Team</p>
              <IoIosArrowForward />
            </Button>
          </a>
          <a href={LINKS.URL_VERIFICATION_REQUEST} target="_blank" rel="noopener noreferrer">
            <Button>
              <p>Verification Request</p>
              <IoIosArrowForward />
            </Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default SettingsPage;
