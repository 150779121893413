import * as QueryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_PROFILES as ACTION_REQUEST_PROFILES } from '../servicelayer/servicelayer-actions';
import "../styles/ProfileList.css";
import ProfileListItem from './ProfileListItem';
const ProfileList = (props) => {

    const dispatch = useDispatch();
    let query = QueryString.parse(props.location.search);

    const [profiles, setProfiles] = useState([]);
    const profileFetchResult = useSelector(state => state.fetchProfileResult);

    const getTitle = (query) => {
        // console.log('getTitle query:', query.type);
        if(query.type === 'follower') return 'Followers';
        else if(query.type === 'following') return 'Following';
        else if(query.type === 'organization') return 'Organizations';
    }


    const title = getTitle(query);

    const buildFilter = () => {
        let filter = {
            type: query.type,
            uid: query.uid,
            last: profileFetchResult && profileFetchResult.last,
            isOrganization: query.type === 'organization'
        };
        return filter;
    }

    
    useEffect(() => {
        //loading follower.. 
        let filter = buildFilter();
        dispatch({ type: ACTION_REQUEST_PROFILES, payload: filter });

    }, [dispatch]);

    useEffect(() => {

        if (profileFetchResult && profileFetchResult.profiles) {
            const newProfileSet = [...profiles, ...profileFetchResult.profiles];

            setProfiles(newProfileSet);
        }
    }, [profileFetchResult]);

    const fetchMoreProfiles = () => {
        const filter = buildFilter();
        if(filter.last){
            dispatch({ type: ACTION_REQUEST_PROFILES, payload: filter });
        }
    };

    const onProfileDetail = (profile) => {
        console.log('profile detail: profile=>', profile);
        window.open(`/ProfileDetail?id=${profile.id}`,'_self');
    }

    return (<Container className={'profile-page'}>
        <Row>
            <div className={'title'}>{title}</div>
        </Row>
        <Row style={{marginTop : 15}}>
            <Col lg={9} xs={12}>
                <InfiniteScroll initialLoad={false} loadMore={fetchMoreProfiles} hasMore={true}
                    loader={(<div className={'loading'}><ReactLoading type={'bubbles'} color={'#402366'} height={50} width={50} /></div>)}>
                    {profiles.map((profile, i) => {
                        //console.log('Event:', Event);
                        return (
                            <ProfileListItem profile={profile} onClickProfile={(profile) => onProfileDetail(profile)}/>
                        );
                    })}
                </InfiniteScroll>
            </Col>
        </Row>
    </Container>)
}
export default ProfileList;