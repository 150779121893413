import React, { useState, useEffect} from 'react';
import {  Button, Container, FormControl, Form, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import * as ACTION from '../servicelayer/servicelayer-actions'
import {setSessionData} from '../util/util'

const LoginComponent = ({onSuccesCallback}) => 
{
    const user = useSelector(state => state.user);
    //console.log('LoginComponent user:', user);
    const requestResponse = useSelector(state => state.loginError);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('')
    const dispatch = useDispatch();
    const handleSubmit = (e)=>
    {

        e.preventDefault();
        let payload = {username:username,password};
        let _dispatchObj = {type:ACTION.REQUEST_LOGIN, payload:payload}
        dispatch(_dispatchObj);
    }

    useEffect(()=> {
        if(user) //indicates a successful login
        {
            setSessionData(user);
            onSuccesCallback(user); //a callback, likely navigating to a different component
        }
    });
    

    const showRequestResponse = () => 
    {
        if (requestResponse)
        {
            return (<Form.Row><Alert variant="danger">{requestResponse}</Alert></Form.Row>)
        }
    }


    return (<Container >
        <Form onSubmit={handleSubmit}>
            {showRequestResponse()}
            <Form.Row>
            <Form.Group>
            <Form.Label className="form-label">Email</Form.Label>
                <FormControl className="form-input" type="email" placeholder="email@address.com" value={username} onChange={(e)=> setUsername(e.target.value)} />
            </Form.Group>
            </Form.Row>
           <Form.Row>
           <Form.Group>
                <Form.Label className="form-label">Password</Form.Label>
                <FormControl className="form-input" type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} ></FormControl>
            </Form.Group>
           </Form.Row>
        <Button className={'button-submit'} type="submit" >Log In</Button>
        </Form>
    </Container>)
}


export default LoginComponent