/**
 * @description  Handles uploading images to google cloud bucket
 * @author - Amonte
 */
import {storage} from '../util/util';
import uuid from 'uuid-random';

/**
 * Uploads an image to google cloud and returns a url to that image.
 * @param {*} imageFile - A File object 
 */
async function uploadImage(imageFile, path)
{
    
    let randomName = uuid();
    return await storage.ref(`/${path}/${randomName}.jpg`).put(imageFile).then(result => {
        return result.ref.getDownloadURL().then(url => { return url;});
    }).catch(e=>{throw new Error(e);})
}
 const ImageService = {
     uploadImage:uploadImage
 };

 export default ImageService