import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { getSessionData } from '../util/util';
const PrivateRoute = ({ component: Component, ...rest }) => {
    
    const session = getSessionData();
    const loggedIn = session !== undefined && session !== null;
    return (
        <Route {...rest} render={props =>
            loggedIn ?
                (<Component {...props} />)
                :
                (<Redirect
                    to={{
                        pathname: "/Login",
                        state: { from: props.location }
                    }}
                />
                )
        }
        />
    );
};

export default PrivateRoute;

