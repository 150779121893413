import React, {useState, useRef, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Image from 'react-bootstrap/Image';
import Input from 'react-phone-number-input/input'
import '../styles/PhoneConfirm.css';
import signUpImage from '../resources/phoneconfirm.png'
import { BEGIN_PHONE_CONFIRM, BEGIN_PHONE_CONFIRM_COMPLETE} from "../servicelayer/servicelayer-actions";
import {getFirebaseCaptchaVerifier} from '../util/util';


const PhoneConfirm = () => {
    const [phone, setPhone] = useState('');
    const [confirmCode, setConfirmCode] = useState('');
    const newUser = useSelector(state => state.newUser); //initial parts of new user data, which was created during the sign up process
    const confirmCodeError = useSelector(state=>state.phoneConfirmError); //i.e. incorrect code, expiration of verification id 
    const verificationId = useSelector(state =>{ return state.verificationId;}); 
    const phoneConfirmed = useSelector(state => state.phoneConfirmed); //the phone number confirm process is complete. The phone number has been linked with the newly created user.
    const dispatch = useDispatch();
    const captcha = useRef(null); //reference to an element is required to make a captcha in the phone verification proess
    const history = useHistory();
    
    function sendCode (e) {
        e.preventDefault();
        //the verifier must be passed to firebase for security purposes. It requires an element on the page within which it will render a captcha
        if(!window.appVerifier){
        window.appVerifier = getFirebaseCaptchaVerifier(captcha.current, 
            {'size':'invisible'
            // , 'callback':function(){console.log('callback')}} 
            });
        }
         dispatch({type:BEGIN_PHONE_CONFIRM, payload:{phone:phone,appVerifier:window.appVerifier}});
    }

    /**
     * 
     * @param {*} e 
     */
    const handleConfirmPhone = (e) =>
    {
        e.preventDefault();
        console.log('phone:', phone,':newUser:', newUser);
         newUser.phone = phone; //add phone data to new user
        dispatch({type:BEGIN_PHONE_CONFIRM_COMPLETE, payload:{verificationId:verificationId, verificationCode:confirmCode, newUser:newUser}});
        
    }



    const showConfirmCodeError = () => 
    {
        if(confirmCodeError)
        {
            return (<Alert variant="danger">{confirmCodeError}</Alert>)
        }
    }

    useEffect(() => {
        if(phoneConfirmed)
        {
            history.push('/CreateAccount');
        }
    },[phoneConfirmed,history]);

    return (
        <div className="phone-confirm">
            <Row>
                {showConfirmCodeError()}
            </Row>
            <Row>
                <Col className="welcome">
                    <div className={'title'}>Welcome to TurnUp!</div>
                    {
                        <div className="desc-welcome">TurnUp believes in People's Rights are Human Rights<br/>and Human Rights are People's Rights.</div>
                    }
                </Col>
            </Row>
            <Row>
                {/* <Col>
                    <Image src={signUpImage} alt="protester"></Image>
                </Col> */}
                <Col>
                    <Form onSubmit={handleConfirmPhone} >
                        <Form.Group>
                            <Form.Label className="form-title">Confirm Your Phone Number</Form.Label>
                            <Form.Label className="form-label">Phone number</Form.Label>
                            <Input className="form-control" placeholder="Enter phone number" value={phone} country="US" onChange={setPhone}/>
                            <Button className={'button-submit'} type="submit" onClick={sendCode}>Receive Code</Button>
                            <div className="resend-code">
                                <p>Didn't receive a confirmation code?</p>
                                <p><span onClick={sendCode} >Resend Code</span></p>
                            </div>
                            {/** Captcha required for firebase phone confirm */}
                            <div id="captcha" ref={captcha}></div>
                        </Form.Group>
                        <Form.Group hidden={!verificationId} > 
                            <Form.Label className="form-label">Confirmation Code</Form.Label>
                            <Form.Control type="text" placeholder="234211" onChange={(e)=>setConfirmCode(e.target.value)} />
                            <Button className={'button-submit'} type="submit">CONTINUE</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>

        </div>
    )
}

export default PhoneConfirm;
