/**
 * @file CreatePost Component
 * @author Shayan A.
 */

import React, { useState } from 'react';
import { Alert, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FaCamera, FaVideo } from 'react-icons/fa';
import { IoMdDocument } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import Link from "react-router-dom/Link";
import { REQUEST_CREATE_POST } from '../servicelayer/servicelayer-actions';
import '../styles/CreatePost.css';
import Icon from './Icon';

const CreatePost = (props) => {
  //viewmodel = CreatePostModel
  const [content, setContent] = useState('');
  const [uploads, setUploads] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const validationError = useSelector(state=>state.createPostError);
  

  const dispatch = useDispatch();
  let self = {};
 
  //logged in user
  const loggedInUser = useSelector(state => state.user);

  const createPost = () => 
  {
    let post = {
       content : content
      ,uid : (loggedInUser && loggedInUser.uid) ?? "LRV1l3GxkEchni2fpLxwAiq2Bml1"
      ,likesCount :0
      ,commentsCount : 0
      ,uploads :uploads
    };
    dispatch({type:REQUEST_CREATE_POST, payload:post})
  }

  const handleFileChange = (e)=>
  {
    let files = e.target.files; 
    if(files) {
      let _previous = uploads;
        console.log(files);
        for(let i = 0; i < files.length;i++) {
          let file = files[i];
          _previous.push(file);
        } 
        setUploads(_previous)
      };
       
  }

  const showValidationError = () => {
    if (validationError) {
        return (<Row style={{textAlign:"center"}}><Col lg={12} xs={12}><Alert variant="danger">{validationError}</Alert></Col></Row>)
    }
}

  return (
      <div className="create-post-layout">
        <Row className={'layout-center'}>
              <div className="create-events-btn"><Link  to='/CreateEvent'>CREATE POST</Link> </div>
        </Row>
      <Container className='create-post-wrapper'>
          
         {showValidationError()}
      <Form.Group>
        <Form.Control as="textarea" placeholder="What's on your mind?" rows="3" 
          onChange={(event)=> {
              setContent(event.target.value);
              if(event.target.value.length > 0)  setDisabled(false);
              else setDisabled(true);
            }
          } />
      </Form.Group>
      <Form.Group>
        <Row className={'layout-post'}>
          <Col className="post-icon" onClick={() => { self.fileUpload.click(); }} >
          <Form.File  accept="image/png, image/jpeg" hidden onChange={handleFileChange}  ref={(me) => { self.fileUpload = me }} multiple/>
            <Icon color="indigo"><FaCamera size={30} /></Icon>
            <Icon color="indigo" ><FaVideo size={30} /></Icon>
            <Icon color="indigo" ><IoMdDocument size={30} /></Icon>
          </Col>
        </Row>
      </Form.Group>
      </Container>
      <Row className={'layout-center'}>
          <Button className="btn-create-post" onClick={()=> createPost() } disabled={disabled}>POST</Button>
       </Row>
      </div>
  );
};

export default CreatePost;