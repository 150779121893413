import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { BiSearch } from 'react-icons/bi';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import 'react-tiny-fab/dist/styles.css';
import { REQUEST_PROFILES as ACTION_REQUEST_PROFILES } from '../servicelayer/servicelayer-actions';
import '../styles/ConnectPage.css';
import CONSTANTS, { MESSAGES } from '../util/data';
import CitySearchComponent from './CitySearchComponent';
import ProfileListItem from './ProfileListItem';

const ConnectPage = () => {

    const dispatch = useDispatch();

    const [state, setState] = useState(CONSTANTS.STATES[0].value);
    const [city, setCity] = useState('');
    const [tags, setTags] = useState(CONSTANTS.TAGS[0].value);
    const [searchTerm, setSearchTerm] = useState('');
    
    const profileFetchResult = useSelector(state => state.fetchProfileResult);
    // const [loaded, setLoaded] = useState(false);

    const [isProfile, setIsProfile] = useState(true);
    const isProfileRef = useRef(true);
    const [profiles, setProfiles] = useState(null);

    const buildFilter = () => {
        let filter = {
             state: state !== CONSTANTS.STATES[0].value ? state : null
            , city: city
            , tags: tags !== CONSTANTS.TAGS[0].value ? tags : null
            , searchTerm: searchTerm !== '' ? searchTerm : null
            , last: profileFetchResult && profileFetchResult.last
        };

        if(!isProfileRef.current) filter.isOrganization = true;
        return filter;
    }

    const fetchMoreProfiles = () => {
        const filter = buildFilter();
        //console.log('fetchMoreEvents: filters:', filter);
        if (filter.last) {
            dispatch({ type: ACTION_REQUEST_PROFILES, payload: filter });
        }
    };

    const handleSubmit = event => {
        event && event.preventDefault();
        setProfiles([]);
        const filter = buildFilter();
        //console.log('handleSubmit: filters:', filter);
        dispatch({ type: ACTION_REQUEST_PROFILES, payload: filter });
    };

    useEffect(() => {
        const filter = buildFilter();
        dispatch({ type: ACTION_REQUEST_PROFILES, payload: filter });
        // eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     console.log('Events were set.');
    //     if (profiles.length > 0) {
    //         console.log('event:', profiles);
    //     }
    // }, [profiles]);
    useEffect(() => {
         console.log('profileFetchResult:', profileFetchResult);
        if(profileFetchResult && profileFetchResult.profiles) {
            const newProfileSet = [...(profiles || []), ...profileFetchResult.profiles];
            setProfiles(newProfileSet);
        }
        // eslint-disable-next-line
    }, [profileFetchResult]);

    const renderOptions = (items) => {
        if (!items) return;
        return items.map((item, i) => (<option key={i} >{item.value}</option>))
    }

    
    const handleCitySelect = (selection) => {

        if (!selection.address_components) return;
        let _city = null, _state = null;
        selection.address_components.forEach(address => {
            if (address.types.some(x => x === "locality")) {
                _city = address.long_name;
            }
            if (address.types.some(x => x === "administrative_area_level_1")) {
                _state = address.short_name;
            }
        });

        if (_city) setCity(_city);
        if (_state) setState(_state);

    }

    const handleClearFilters = () => {
        setState(CONSTANTS.STATES[0].value);
        setCity('');
        setTags(CONSTANTS.TAGS[0].value);
    }
    
    const onClickProfile = () => {    
        setIsProfile(true);
        isProfileRef.current = true;
        setProfiles(null);
        loadProfile();
    }
    const onClickOrganization = () => {
        setIsProfile(false);
        //profileFetchResult = null;
        isProfileRef.current = false;
        setProfiles(null);
        loadProfile();
    }
    const loadProfile = () => {
        setProfiles(null);
        const filter = buildFilter();
        filter.last = undefined;
        dispatch({ type: ACTION_REQUEST_PROFILES, payload: filter });
    }
    const onProfileDetail = (profile) => {
        // console.log('profile detail: profile=>', profile);
        window.open(`/ProfileDetail?id=${profile.id}`,'_self');
    }
    const onKeyPressed = (e) => {
        console.log('onKeyPressed :e:', e.charCode);
        if(e.charCode === 13) {
            handleSubmit();
        }
    }

return (<Container className="connect-page">
            <Row className={'search-header'}>
                <Col className={'search-input'}>
                    <InputGroup>
                        <FormControl placeholder="Search People" onChange={(e) => setSearchTerm(e.target.value)} onKeyPress={onKeyPressed}/>
                        <InputGroup.Append>
                            <Button type="submit" variant="outline-secondary" onClick={() => handleSubmit()}><BiSearch /></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            <div className="tab-header">
                <Button onClick={() => onClickProfile()}><p className={isProfile ? 'tab-text-selected' : 'tab-text'}>PROFILE</p></Button>
                <Button onClick={() => onClickOrganization()}><p className={isProfile ? 'tab-text' : 'tab-text-selected'}>ORGANIZATION</p></Button>
            </div>
            <Row>
                <Col lg={9} xs={12}>
                    {
                    (profiles || []).length === 0 ? (<div className={"no-event"}>
                    {profiles === null ? 'Loading..' : MESSAGES.NoResults}</div>) : ""}
                    <InfiniteScroll initialLoad={false} loadMore={fetchMoreProfiles} hasMore={true}
                        loader={(<div className={'loading'}><ReactLoading type={'bubbles'} color={'#402366'} height={50} width={50} /></div>)}>
                        {( profiles || [] ).map((profile, i) => {
                            //console.log('Event:', Event);
                            return (
                                <ProfileListItem profile = {profile} onClickProfile = {(profile) => onProfileDetail(profile)} />
                            );
                        })}
                    </InfiniteScroll>
                </Col>
                <Col >
                    <Form className="form d-lg-block d-none">
                        <Form.Group>
                        </Form.Group>
                        <h3>Filter:</h3>
                        <Form.Group>
                            <Form.Label>State</Form.Label>
                            <Form.Control as="select" size="sm" value={state} onChange={e => setState(e.target.value)} >
                                {renderOptions(CONSTANTS.STATES)}
                            </Form.Control>
                        </Form.Group>
                        <CitySearchComponent onChange={handleCitySelect}></CitySearchComponent>
                        <Form.Group>
                            <Form.Label>Tags</Form.Label>
                            <Form.Control as="select" size="sm" value={tags} onChange={e => setTags(e.target.value)}>
                                {renderOptions(CONSTANTS.TAGS)}
                            </Form.Control>
                        </Form.Group>            
                        <Form.Group className="text-center">
                            <Button className="apply-filter" onClick={handleSubmit}  >Apply Filters</Button>
                            <br />
                            <Button variant="link" className="clear-filter" onClick={handleClearFilters}>Clear Filters</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>       
        </Container>
    );
}

export default ConnectPage;