
import * as ACTION from './servicelayer-actions'
import {call, put} from 'redux-saga/effects'
import {auth, getPhoneAuthProvider, getPhoneAuthCredential, firebase} from '../util/util'
import {getUserProfile} from './servicelayer-dal'
import { Normalize } from './normalizer';
import { setSessionData } from '../util/util';
function _login(username,password)
{
    firebase.auth().onAuthStateChanged(user => {
        console.log('auth changed:', user);
    });
    return auth.signInWithEmailAndPassword(username, password).then(user =>  {return {user:auth.currentUser.uid}})
        .catch(error => {  return error; });   
}

function* login(dispatchObject) 
{
    let payload = dispatchObject.payload;
    let result = yield call(_login, payload.username, payload.password);
    if(result.message)
    {
        yield put({
            type:ACTION.RESPONSE_LOGINERROR,
            data:result.message
        });    
    }
    else
    {
        console.log('result:', result);
        //fetch profile
        let userProfileResult = yield call(getUserProfile, result.user);
        console.log('login().. userProfileResult:', userProfileResult);
        // let profile = userProfileResult[0];
        let profile = Normalize.profiles([userProfileResult])[0];
        yield put({
            type:ACTION.RESPONSE_LOGIN,
            data:profile
        });
    }
}
function _logout(){
    return auth.signOut().then(() => {
        return true;
    })
    .catch(err => {
        return err;
    })
}

function* logout(){
    let result = yield call (_logout);
    console.log('logout result:', result);
    if(result){
        setSessionData(null);
        yield put({
            type:ACTION.RESPONSE_LOGOUT,
            data:{}
        });    
    }
}

function _createAccount(email,password) {
    //creates and logs in user if successful
    return auth.createUserWithEmailAndPassword(email, password)
        .then(()=>{return true;})
        .catch(function(error) { return error;});
}


function* signUp(dispatchObject)
{
    let email = dispatchObject.payload.email;
    let password = dispatchObject.payload.password;
    let signUpResult = yield call(_createAccount, email,password);
    if(signUpResult === true)
    {   
        yield put({type:ACTION.RESPONSE_SIGNUP, data:{uid:auth.currentUser.uid, email:email, password:password}});
    }
    else
    {
        yield put({type:ACTION.RESPONSE_SIGNUP_ERROR, data:signUpResult})
    }
}
function* restoreSession(dispatchObject){
    console.log('restoreSession.. payload:', dispatchObject.payload);
    let userSession = dispatchObject.payload;
    yield put({type : ACTION.UPDATE_SESSION, data : userSession });
    return;
}
async function _beginPhoneConfirm(number, appVerifier)
{
    return getPhoneAuthProvider().verifyPhoneNumber(number, appVerifier).then(verificationId => {return verificationId});
}

function* beginPhoneConfirm(dispatchObject)
{
    let number = dispatchObject.payload.phone;
    let appVerifier = dispatchObject.payload.appVerifier;
    let verificationId = yield call(_beginPhoneConfirm, number, appVerifier);
    yield put({type:ACTION.PHONE_CONFIRM_PENDING, data:verificationId});

}

async function _linkCredential(verificationId, verificationCode)
{
    var credential = getPhoneAuthCredential(verificationId, verificationCode);
    return auth.currentUser.linkWithCredential(credential).then(x=> {return x}).catch(e=> { throw new Error(e);});
}

function* completePhoneConfirm(dispatchObject)
{
    let verificationId = dispatchObject.payload.verificationId;
    let verificationCode = dispatchObject.payload.verificationCode;
    let newUser = dispatchObject.payload.newUser;
    try{
    let result = yield call(_linkCredential, verificationId,verificationCode);
    yield put({type:ACTION.PHONE_CONFIRM_COMPLETE, data:newUser, additionalData:result})
    }
    catch(e)
    {   console.error(e);
        yield put({type:ACTION.PHONE_CONFIRM_COMPLETE_ERROR, data:e.message});
    }
}

const AuthService = {
    login:login
    ,logout : logout
    ,signup:signUp
    ,beginPhoneConfirm:beginPhoneConfirm
    ,completePhoneConfirm:completePhoneConfirm
    ,restoreSession : restoreSession
};

export default AuthService