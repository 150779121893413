import * as QueryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import { ChatList, Input, MessageList } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { BiSearch } from 'react-icons/bi';
import { FiSend } from "react-icons/fi";
import { BsArrowLeft } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import ScrollArea from 'react-scrollbar';
import placeholder from '../resources/placeholder.png';
import * as ACTION from '../servicelayer/servicelayer-actions';
import "../styles/MessageScreen.css";
import { firestoreDB, newConversation, sendMessage, useConversations } from '../util/util';
import { isMobile } from 'react-device-detect';
const MessageScreen = (props) => {

    const dispatch = useDispatch();

    let query = QueryString.parse(props.location.search);
    //const chatList = useSelector(state => state.chats);
    const messageProfile = useSelector(state => state.fetchProfileResult);
    const loggedInUser = useSelector(state => state.user);
    //getting conversation list
    const [conversations] = useConversations(query.uid);
    const [messageList, setMessageList] = useState([]);
    const [chatList, setChatList] = useState([]);

    const [message, setMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    const listenersRef = useRef([]);
    const startRef = useRef(null);
    const initialStartRef = useRef(null);
    const messagesRef = useRef([]);
    const currentMessageChangedRef = useRef(false);

    const selectedCov = useRef();
    const inputRef = useRef();

    //new conversation info
    const newConvRef = useRef();
    const userId = query.uid ?? null;
    // button enabled/disabled
    //const [enabled, setEnabled] = useState(false);
    const [isDetail, setIsDetail] = useState(false);

    useEffect(_ => {
        //get messaging profile
        if (!messageProfile) {
            dispatch({ type: ACTION.REQUEST_FETCH_PROFILE, payload: { docId: query.mid, isFullInfo: true } });
        }
    }, [query, loggedInUser]);

    useEffect(() => {
        if (messageProfile) {
            // console.log('messageProfile:', messageProfile);
            let profiles = [];
            profiles.push(messageProfile);
            //console.log('newConversation().. profiles:', profiles)
            newConversation('', profiles, query.uid, conversation => {
                newConvRef.current = conversation;
                onChatClicked(newConvRef.current);
            });
        }
    }, [messageProfile]);

    useEffect(() => {
        //console.log('conversations:', conversations);
        let messageList = [];
        (conversations || []).forEach((conversation) => {
            //console.log('conversation :', conversation);

            messageList.push({
                id: conversation.id,
                avatar: conversation.imageUrl ?? placeholder,
                alt: placeholder,
                title: conversation.isGroup ? getGroupName(conversation) : conversation.participants.find(p => p.id !== query.uid) ? conversation.participants.find(p => p.id !== query.uid).name ? conversation.participants.find(p => p.id !== query.uid).name : conversation.participants.find(p => p.id !== query.uid).username : 'Unknown User',
                subtitle: conversation.lastContent,
                date: conversation.lastTimestamp.toDate(),
                unread: 0
            });

            //console.log('messageList :', messageList);
            setMessageList(messageList);
        });
    }, [conversations])

    useEffect(() => {
        // console.log('messageList:', messageList);
    }, [messageList]);

    useEffect(() => {
        // console.log('messageList:', messageList);
    }, [searchTerm]);

    const getGroupName = conversation => {
        //console.log('Getting the group name:', conversation);
        if (conversation.groupName) {
            return conversation.groupName;
        } else {
            let names = [];
            conversation.participants.filter(p => p.id !== query.uid).forEach(p => {
                names.push(p.name);
            })
            return `${names.join(', ')}`
        }
    };
    const onChatClicked = (item) => {
        selectedCov.current = item;
        messagesRef.current = [];
        loadMessage(item.id);
        if (isMobile) setIsDetail(true);
    }
    const onSendMessage = () => {
        // console.log('sending message: message=>', message,':query:', query);
        if (selectedCov.current) {
            sendMessage(message, selectedCov.current.id, query.uid, null);
        }
        else {
            if (newConvRef.current) {
                sendMessage(message, newConvRef.current.id, query.uid, null);
                onChatClicked(newConvRef.current);
            }
        }
    }
    const loadMessage = (conversationID) => {
        // console.log('loadMessage: convID:', conversationID);
        let query = firestoreDB.collection('conversations').doc(conversationID)
            .collection('messages');

        query.orderBy('timestamp', 'desc')
            .limit(10)
            .get().then(snap => {
                //console.log('snap length:', snap.docs.length);
                if (snap.docs.length === 0) return;
                startRef.current = snap.docs[snap.docs.length - 1];
                initialStartRef.current = snap.docs[snap.docs.length - 1];
                //console.log('startPoint:', startRef.current);        
                let listener = query.orderBy('timestamp')
                    .startAt(initialStartRef.current)
                    .onSnapshot((messages) => {
                        if (messages.metadata.hasPendingWrites) {
                            // console.log('hadPendingWrites ignoring..');
                        }
                        else {
                            // console.log('snap changed.. messages.length=>', messages.docs.length);
                            messages.docs.forEach((message) => {
                                //console.log('messages: ID:', message.id,':messgesData:', messagesRef.current);
                                // filter out any duplicates (from modify/delete events)         
                                messagesRef.current = messagesRef.current.filter(x => x.id !== message.id)

                                let _message = { ...message.data() };
                                _message.id = message.id;

                                messagesRef.current.push(_message);
                            })
                            currentMessageChangedRef.current = true;
                            //console.log('message status before invert:', messagesRef.current);
                            let _messages = [...messagesRef.current];
                            //console.log('_messages', _messages);
                            let chatList = [];
                            _messages.forEach(message => {
                                // console.log('message:', message,':uid:', userId,':position:',message.senderID === query.uid ? 'left' : 'right');
                                chatList.push({
                                    position: message.senderID === userId ? 'right' : 'left',
                                    type: 'text',
                                    text: typeof message.content === 'string' ? message.content : 'Live Data',
                                    date: message.timestamp.toDate()
                                });
                            });

                            setChatList(chatList);
                            //setMessages(_messages.reverse());
                        }


                    });

                listenersRef.current.push(listener);

            })
    }

    // const loadMessageMore = (conversationID) => {
    //     console.log('loadMessageMore: convID:', conversationID);
    //     //setIsLoadingMessage(true);
    //     let query = firestoreDB.collection('conversations').doc(conversationID)
    //         .collection('messages');

    //     query.orderBy('timestamp', 'DESC')
    //         .startAt(startRef.current)
    //         .limit(10).get().then(snap => {

    //             endRef.current = startRef.current;
    //             startRef.current = snap.docs[snap.docs.length - 1];

    //             let listener = query.orderBy('timestamp')
    //                 .startAt(startRef.current)
    //                 .endBefore(endRef.current)
    //                 .onSnapshot((messages) => {

    //                     messages.docs.reverse().forEach((message) => {
    //                         // filter out any duplicates (from modify/delete events)         
    //                         messagesRef.current = messagesRef.current.filter(x => x.id !== message.id)
    //                         let _message = { ...message.data() };
    //                         _message.id = message.id;

    //                         messagesRef.current.unshift(_message);

    //                     })
    //                     currentMessageChangedRef.current = false;
    //                     let _messages = [...messagesRef.current];
    //                     //console.log('messages:', _messages);
    //                     //setMessages(_messages.reverse());
    //                     //setIsLoadingMessage(false);
    //                 });

    //             listenersRef.current.push(listener);

    //         });
    // }

    const onKeyPressed = (e) => {
        if (e.charCode === 13) {
            onSendMessage();
            setMessage('');
        }
    }
    const handleSubmit = () => {
    }
    return (!isMobile ? <Container className={'message-screen'}>
        <Col className={'chat-nav'}>
            <ScrollArea
                speed={0.8}
                className="area"
                contentClassName="list-chats"
                horizontal={false}
            >
                <InputGroup>
                    <FormControl placeholder="Search for contact" onChange={(e) => setSearchTerm(e.target.value)} />
                    <InputGroup.Append>
                        <Button type="submit" variant="outline-secondary" onClick={() => handleSubmit()}><BiSearch /></Button>
                    </InputGroup.Append>
                </InputGroup>
                <ChatList
                    className={'chat-list'}
                    dataSource={messageList}
                    onClick={(item) => onChatClicked(item)}
                />
            </ScrollArea>
        </Col>
        <Col className={'message-nav'}>
            <Row>
                {/* <ProfileListItem profile={}></ProfileListItem> */}
            </Row>
            <ScrollArea
                speed={0.8}
                className="area"
                contentClassName="list-messages"
                horizontal={false}
            >
                <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={chatList} />
            </ScrollArea>
            {/* <InfiniteScrollReverse
                className="itemsContainer"
                hasMore={isLoading}
                isLoading={isLoading}
                loadMore={getItems}
                loadArea={30}
            >
               <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={chatList} /> 
            </InfiniteScrollReverse> */}
            <Input
                placeholder="Type here..."
                multiline={false}
                ref={inputRef}
                className={'message-input'}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={onKeyPressed}
                rightButtons={
                    <Button
                        color='transparent'
                        className={'button-send'}
                        text='Send'
                        onClick={() => onSendMessage()}
                        disabled={message.length > 0 ? false : true}
                    >
                        <FiSend />
                    </Button>
                } />
        </Col>
    </Container>
        :
        <Container className={'mobile-message-screen'}>
            {
                isDetail ?
                    <div>
                        <Row className={'post-profile'}>
                            <div>
                                <Button className={"back-button"} onClick={() => {setIsDetail(false)}}><span><BsArrowLeft /></span></Button>
                            </div>
                        </Row>
                        <ScrollArea
                            speed={0.8}
                            className="area"
                            contentClassName="list-messages"
                            horizontal={false}
                        >
                            <MessageList
                                className='message-list'
                                lockable={true}
                                toBottomHeight={'100%'}
                                dataSource={chatList} />
                        </ScrollArea>
                        {/* <InfiniteScrollReverse
                className="itemsContainer"
                hasMore={isLoading}
                isLoading={isLoading}
                loadMore={getItems}
                loadArea={30}
            >
               <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={chatList} /> 
            </InfiniteScrollReverse> */}
                        <Input
                            placeholder="Type here..."
                            multiline={false}
                            ref={inputRef}
                            className={'mobile-message-input'}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyPress={onKeyPressed}
                            rightButtons={
                                <Button
                                    color='transparent'
                                    className={'button-send'}
                                    text='Send'
                                    onClick={() => onSendMessage()}
                                    disabled={message.length > 0 ? false : true}
                                >
                                    <FiSend />
                                </Button>
                            } />
                    </div>
                    :
                    <div>
                        <ScrollArea
                            speed={0.8}
                            className="area"
                            contentClassName="list-chats"
                            horizontal={false}
                        >
                            <InputGroup>
                                <FormControl placeholder="Search for contact" onChange={(e) => setSearchTerm(e.target.value)} />
                                <InputGroup.Append>
                                    <Button type="submit" variant="outline-secondary" onClick={() => handleSubmit()}><BiSearch /></Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <ChatList
                                className={'chat-list'}
                                dataSource={messageList}
                                onClick={(item) => onChatClicked(item)}
                            />
                        </ScrollArea>
                    </div>
            }
        </Container>
    )
}
export default MessageScreen;