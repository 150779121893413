/**
 * This file should only contain logic for catching and passing dispatched events aka types
 * @author - Amonte
 */
import {all, takeEvery, takeLatest} from 'redux-saga/effects'
import * as ACTION from './servicelayer-actions'
import EventsPostsService from './eventPostService'
import ResourceService from './resourceService'
import AuthService from './authService'
import ProfileService from './profileService'

export function* ServiceLayerSaga(){
    yield all([takeLatest(ACTION.REQUEST_FEED, EventsPostsService.fetchFeed )
    , takeLatest(ACTION.REQUEST_LOGIN, AuthService.login)
    , takeLatest(ACTION.REQUEST_LOGOUT, AuthService.logout)
    , takeLatest(ACTION.REQUEST_SIGNUP, AuthService.signup)
    , takeEvery(ACTION.REQUEST_SESSION_RESTORE, AuthService.restoreSession)
    , takeLatest(ACTION.REQUEST_EVENTS, EventsPostsService.queryEvents)
    , takeLatest(ACTION.REQUEST_RESOURCECATEGORIES, ResourceService.fetchResourceCategories )
    , takeLatest(ACTION.REQUEST_RESOURCES, ResourceService.fetchResources )
    ,takeLatest(ACTION.REQUEST_CREATE_EVENT, EventsPostsService.createEvent)
,takeLatest(ACTION.REQUEST_CREATE_POST, EventsPostsService.createPost)
,takeLatest(ACTION.REQUEST_CREATE_PROFILE, ProfileService.createProfile)
,takeLatest(ACTION.BEGIN_PHONE_CONFIRM, AuthService.beginPhoneConfirm)
,takeLatest(ACTION.BEGIN_PHONE_CONFIRM_COMPLETE, AuthService.completePhoneConfirm)
,takeLatest(ACTION.REQUEST_EVENT_DETAILS, EventsPostsService.getEventDetails)
,takeLatest(ACTION.REQUEST_FETCH_PROFILE, ProfileService.fetchProfile )
,takeLatest(ACTION.REQUEST_HANDLE_RSVP, EventsPostsService.handleRSVP)
,takeLatest(ACTION.REQUEST_FOLLOWER_PROFILE, ProfileService.fetchFollower)
,takeLatest(ACTION.REQUEST_PROFILES, ProfileService.fetchProfiles)
,takeLatest(ACTION.REQUEST_USER_FOLLOW, ProfileService.followUser)
,takeLatest(ACTION.REQUEST_NOTIFICATIONS, ProfileService.getNotifications)
]);
    
}

