import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import {toShortMonthName} from "../util/data";
import '../styles/EventList.css';


const EventList = ({events}) => {

  const showLocation = (event) => 
  {
    if(event.city && event.state)
    {
      return `${event.city}, ${event.state}`;
    }
    if (event.state)
    {
      return event.state;
    }
  }
  return (
    <Container>
      <Col>
        {events.map((event) => (
          <Row key={event.name} data-testid="eventListRow" className="event-list-row align-items-center text-muted pb-4">
            <Col xs sm={2}>
            <span className="text-center" height="100%">
                <div className="font-weight-light">{toShortMonthName(event.eventDate)}</div>
                <p className="h2 mt-n1">{event.eventDate.getDate()}</p>
              </span>
            </Col>
            <Col xs className="h3">{event.name}</Col>
        <Col xs className="h4 text-right font-weight-light">{showLocation(event)}</Col>
          </Row>
        ))}
      </Col>
    </Container>
  );
};

export default EventList;
