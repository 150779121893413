

import { Event, Post, Profile, ResourceCategory, Resource } from './types'


function normalizePosts(posts: any[]): Post[] {
    let retval: Post[] = [];
    posts.forEach(post => {
        // console.log('normalize post:', post);
        let _post: Post =
        {
            id: post.uid
            , stats: { comments: post.commentsCount ?? 0, likes: post.likesCount ?? 0, favorites: 0, reposts: 0 }
            , content: post.content ?? ""
            , imgs: post.images ?? []
            , images : post.images ?? []
            , time: post.timestamp
            , type: post.type ?? ""
            , event: post.event ? Normalize.event(post.event) : null
            , prof : post.prof ? post.prof : null
            , likes : post.likesArray ?? []
            , post_id : post.id
            , ref : post.ref
            , profileID : post.uid
            , postID : post.id
            , idEvent : post.event ? post.event.id : ''
            , eventTitle : post.event ? post.event.eventTitle : ''
            , uid : post.event ? post.event.uid : ''

        };  
        retval.push(_post);
    });
    return retval;
}

function normalizeEvents(events: any[]): Event[] {
    let retval: Event[] = [];
    events.forEach(event => {
        if (!event) return;
        console.log('normalizeEvent: ')
        let _event: Event = {
            browserUrl: event.browser_url ?? "",
            category: event.category ?? "",
            city: event.city ?? "",
            description: event.eventAbout ?? "",
            eventDate: new Date(event.eventDate),
            eventEndDate: event.eventEndDate ? new Date(event.eventEndDate) : null,
            eventId: event.eventId ?? -1
            , location: event.eventLocation !== null && event.eventLocation !== undefined ? { lat: event.eventLocation.lat ?? 0, lng: event.eventLocation.lng ?? 0 } : { lat: null, lng: null }
            , name: event.eventTitle ?? ""
            , eventType: event.eventType ?? ""
            , isVirtual: event.eventType === "Virtual"
            , zipCode: event.eventZipCode
            , compressedImg: event.imageUrl ?? ""
            , importDate: event.importDate ? new Date(event.importDate) : null
            , modified: event.modified_date ? new Date(event.modified_date) : null
            , img: event.originalImageUrl ?? ""
            , state: event.state ?? ""
            , tags: event.tags !== [undefined] ? event.tags : []
            , eventProfileId: event.eventProfileId
            , id: event.id
            , rsvps: event.rsvps ?? []
            , ref : event.ref ?? {}
            , idEvent : event.id
            , eventTitle : event.eventTitle
            , uid : event.eventProfileId
        };
        // if (event.tag !== "All" && event.tag !== "" && event.tag !== undefined) _event.tags.push(event.tag);
        retval.push(_event);
    });
    return retval;
}
function normalizeEvent(event: any): Event {
    let _event: Event = {
        browserUrl: event.browser_url ?? "",
        category: event.category ?? "",
        city: event.city ?? "",
        description: event.eventAbout ?? "",
        eventDate: new Date(event.eventDate),
        eventEndDate: event.eventEndDate ? new Date(event.eventEndDate) : null,
        eventId: event.eventId ?? -1
        , location: event.eventLocation !== null && event.eventLocation !== undefined ? { lat: event.eventLocation.lat ?? 0, lng: event.eventLocation.lng ?? 0 } : { lat: null, lng: null }
        , name: event.eventTitle ?? ""
        , eventType: event.eventType ?? ""
        , isVirtual: event.eventType === "Virtual"
        , zipCode: event.eventZipCode
        , compressedImg: event.imageUrl ?? ""
        , importDate: event.importDate ? new Date(event.importDate) : null
        , modified: event.modified_date ? new Date(event.modified_date) : null
        , img: event.originalImageUrl ?? ""
        , state: event.state ?? ""
        , tags: []
        , eventProfileId: event.eventProfileId
        , id: event.id
        , rsvps: event.rsvps ?? []
        , ref : event.ref ?? {}
        , idEvent : event.id
        , eventTitle : event.eventTitle
        , uid : event.eventProfileId
    };
    if (event.tag !== "All" && event.tag !== "") _event.tags.push(event.tag);

    return _event;
}
function normalizeResourcesCategories(categories: any[]): ResourceCategory[] {
    let retval: ResourceCategory[] = [];
    categories.forEach(category => {
        let _category: ResourceCategory =
        {
            name: category.name
            , category: category.category ?? ""
            , description: category.description ?? ""
            , image: category.image ?? ""
            , id: category.id

        };
        retval.push(_category);
    });
    return retval;
}

function normalizeResources(resources: any[]): Resource[] {
    let retval: Resource[] = [];
    resources.forEach(resource => {
        let _resource: Resource =
        {
            name: resource.name
            , category: resource.category ?? ""
            , description: resource.description ?? ""
            , image: resource.image ?? ""
            , id: resource.id
            , website: resource.website ?? ""
            , likesCount: resource.likes ?? 0
        };
        retval.push(_resource);
    });
    return retval;
}

function normalizeProfiles(profiles: any[]): Profile[] {

    let retval: Profile[] = [];
    console.log('normalize profile:', profiles);
    profiles.forEach(profile => {
        if (!profile.location_info) {
            profile.location_info = { lat: 0, lng: 0 }
        }
        let _profile: Profile =
        {
            name: profile.name
            , city: profile.city
            , img: profile.imageUrl
            , uid: profile.docId ?? profile.id
            , email: profile.email
            , locationInfo: { lat: profile.location_info.latitude ?? 42, lng: profile.location_info.longitude ?? 53}
            , blocklist : profile.blocklist ? profile.blocklist : []
            , state : profile.state ?? 'MA'
        };
        retval.push(_profile);
    })

    return retval;
}

export const Normalize = {
    events: normalizeEvents
    , event : normalizeEvent
    , posts: normalizePosts
    , profiles: normalizeProfiles
    , resourceCategories: normalizeResourcesCategories
    , resources: normalizeResources
}

