import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, ResponsiveEmbed, Row } from "react-bootstrap";
import { BsArrowLeft } from "react-icons/bs";
import { HiAnnotation, HiFlag, HiReply, HiThumbUp } from "react-icons/hi";
import Iframe from 'react-iframe';
import { useSelector } from 'react-redux';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import placeholder from '../resources/placeholder.png';
import '../styles/PostDetail.css';
import { dislikePost, getComments, likePost, postComment } from '../util/util';
import PeopleComponent from './PeopleComponent';
const PostDetail = (props) => {
    const post = props.post;

    const [likes, setLikes] = useState(0);
    const [reposts, setReposts] = useState(0);
    const [commentsCount, setCommentsCount] = useState(0);
    const [favorites, setFavorites] = useState(0);

    //comment
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);

    //redux
    // const dispatch = useDispatch();
    const loggedInUser = useSelector(state => state.user);

    useEffect(() => {

        if (post) {
            console.log('post:', post);
            setLikes(post.stats.likes);
            setReposts(post.stats.reposts);
            setCommentsCount(post.stats.comments);
            setFavorites(post.stats.favorites);

            //loading comments
            getComments(post.id, post.post_id, comments => {
                setComments(comments);
            });
        }

    }, [post]);

    const renderedMedia = [];
    if (post.imgs) {
        console.log('post images exist: image length:', post.imgs.length);
        let width = 12 / ((post.imgs.length % 4 === 0) ? 4 : post.imgs.length % 4);
        if (post.imgs.length > 1) {

            post.imgs.map((img, i) => {
                return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src={img} alt="post media" /></div>;
            }).forEach(imgCol => {
                renderedMedia.push(imgCol)
            });

        }
        else {
            post.imgs.map((img, i) => {
                return <Col key={i} xs={width === 12 ? width : 6} md={width} className="media-col"><img src={img} alt="post media" /></Col>;
            }).forEach(imgCol => {
                renderedMedia.push(imgCol)
            });
        }

    } else if (post.video) {
        renderedMedia.push(<Col xs={12} className="media-col"><ResponsiveEmbed aspectRatio="16by9"><Iframe src={post.video} title="post media" allowFullScreen /></ResponsiveEmbed></Col>);
    }

    //comments Related Functions
    const onCommentChange = (e) => {
        setComment(e.target.value);
    }

    const postC = () => {
        console.log('posting a comment:', comment);

        postComment(post.id, post.post_id, comment, success => {
            console.log('success:', success);

        });
        setComment('');

    }

    const renderComments = () => {
        return (<Container className="layout-comment">
            {
                comments.map((comment, i) => {
                    return (<Row key={i}>
                        <Comment comment={comment}></Comment>
                    </Row>)
                }
                )}
        </Container>)
    }

    const Comment = ({ comment }) => {
        console.log('comment:', comment);
        return (<div class="comment">
            <Image className="profile-picture" src={comment && comment.profile ? comment.profile.imageUrl === "" ? placeholder : comment.profile.imageUrl : placeholder} />
            <Col className="comment-text">
                <p>{comment.content}</p>
            </Col>
        </div>);
    }
    const likedPost = (liked) => {
        if (liked) post.stats.likes++;
        else post.stats.likes--;

        setLikes(post.stats.likes);
    }
    const onReport = () => {
        props.onReport();
    }
    const ActionRow = ({ post, user, onLiked, onReported }) => {

        //state
        const [liked, toggleLike] = useState(false)
        const [activeAction, setActiveAction] = useState("none")

        useEffect(() => {

            if (post && user) {
                toggleLike(post.likes.indexOf(user.uid) !== -1);
            }

        }, [post, user]);

        const like = () => {
            if (post && user) {
                if (post.likes.indexOf(user.uid) !== -1) {
                    //already liked.
                    dislikePost(post.id, post.post_id);
                    post.likes = post.likes.filter(id => id !== user.uid);
                    toggleLike(false);
                    onLiked(false);
                }
                else {
                    likePost(post.id, post.post_id);
                    post.likes.push(user.uid);
                    toggleLike(true);
                    onLiked(true);
                }
            }
        }

        const onActiveActionChange = (action) => {
            console.log(`On->ActiveActionChange`, action);
            switch (action) {
                case 'flag':
                    onReported();
                    break;
                case 'share':
                    break;
                default:

            }
            setActiveAction(action);
        }

        return (<Row className="action-row">
            <div className="button-center">
                {/** Will probably create a component */}
                {/*<IconButton> */}
                <Button variant="Light"
                    onClick={() => { like(); }} >
                    <HiThumbUp className={liked ? "active" : ""} /></Button>
                {/** </IconButton> */}
            </div>
            <div className="button-center">
                <Button variant="Light"
                    onClick={() => { onActiveActionChange("flag") }}>
                    <HiFlag className={activeAction === "flag" ? "active" : ""} /></Button>
            </div>
            <div className="button-center">
                <Button variant="Light"
                    onClick={() => { onActiveActionChange("share") }}>
                    <HiReply className={activeAction === "share" ? "active" : ""} /></Button>
            </div>
        </Row>)
    }

    return (
        <Container className={"post-detail"}>
            <Row className={'post-profile'}>
                <div>
                    <Button className={"back-button"} onClick={() => props.onGoBack()}><span><BsArrowLeft /></span></Button>
                </div>
                <div class="profile-layout">
                    {
                        post.prof &&
                        <PeopleComponent profile={post.prof} />
                    }
                </div>
            </Row>
            <div className="offst"></div>
            <Row className="media">
                {post && (post.imgs || []).length < 2 ? renderedMedia :
                    <Slide
                        style={{ width: '100%', alignItems: 'center', justifyContent: 'center', paddingLeft: 20, paddingRight: 20 }}
                        cssClass="slider-wrapper"
                    > {renderedMedia}</Slide>}
            </Row>
            {
                post && post.type && post.type === "event" && post.event &&
                <Row className="title">
                    <Col>
                        <p>{post.event.name}</p>
                    </Col>
                </Row>
            }
            <Row className="content">
                {
                    post && post.type && post.type !== "event" &&
                    <Col>
                        <p>{post.content}</p>
                    </Col>
                }
                {
                    post && post.type && post.type === "event" && post.event &&
                    <Col>
                        <p>{post.event.description && post.event.description.substring(0, post.event.description.length < 80 ? post.event.description.length : 250)}...</p>
                    </Col>
                }

            </Row>
            <Row className="stats">
                <Col className="icon-wrapper">
                    <p><span><HiThumbUp /></span>{likes}</p>
                    <p><span><HiAnnotation /></span>{reposts}</p>
                    <p><span><HiFlag /></span>{commentsCount}</p>
                    <p><span><HiReply /></span>{favorites}</p>
                </Col>
            </Row>
            <hr />
            <ActionRow post={post} user={loggedInUser} onLiked={(liked) => likedPost(liked)} onReported={() => onReport()}></ActionRow>
            {/*rendering comments*/}
            {renderComments()}

            <div className="input-container">
                <input type="text" onChange={onCommentChange} placeholder="Add your comments" />
                <Button className={"post-button"} onClick={() => postC()}><span>POST</span></Button>
            </div>
        </Container>
    );
};

export default PostDetail;