import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Image, Button } from 'react-bootstrap'
import "../styles/AppDownloadPage.css"
import AppIcon from '../resources/app_icon.png';
import ReactStars from 'react-rating-stars-component';
import { AiOutlineClose } from 'react-icons/ai';
const AppDownloadPage = (props) => {
    const [appInstallBar, setAppInstallBar] = useState(true);
    const onCloseBar = () => {
        setAppInstallBar(false);
    }

    const onInstall = () => {
        const url = 'https://turnupactivism.page.link/download';
        var win = window.open(url, '_self');
    }
    const onGetApp = () => {
        const url = 'https://turnupactivism.page.link/download';
        var win = window.open(url, '_self');
    }

    const onExplore = () => {
        console.log('onExplore');
        props.onAllowWeb();
    }

    return (<div>
           {
               appInstallBar && 
               <div className="bar-app-install">
                  <Button onClick={onCloseBar} className={'button-close'}>
                        <AiOutlineClose />
                  </Button>
                  <Image src={AppIcon} width={50} height={50} className="image-app-icon"></Image>
                  <div className="layout-between">
                        <div className="app-name">
                            <p style={{marginBottom:0}}>TurnUp Activism</p>
                            <ReactStars
                                count={5}
                                value={5}
                                size={24}
                                isHalf={true}
                                edit={false}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                                activeColor="#ffd700"
                            />
                        </div>
                        <Button className="button-install" onClick={() => onInstall()}>Open</Button>
                  </div>
         
            </div> 
              }
            <div className={'content'}>
                 <p className="text-header">TurnUp</p>
                 <p className="text-description">TurnUp belives in Social Justice</p>
                 <Button className={'btn-get-app'} onClick={() => onGetApp()}>Get the App</Button>
                 <Button className={'btn-explorer'} onClick={() => onExplore()}>Explore TurnUp</Button>
            </div>
    </div>);
}

export default AppDownloadPage;