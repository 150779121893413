const Config = {
    //API Details
    API_KEY :'AIzaSyCxvbf42TyjatZDj4EoIJt3KeYRer5nT1Q',
    LIMIT_FOLLOWING : 10,
    LIMIT_FOLLOWER : 10,
    LIMIT_POST : 10,
    LIMIT_PER_EVENT : 30,
    LIMIT_GROUP_MEMBER : 20,
    LIMIT_CONVERSATION : 10,
    LIMIT_LIST_CONNECT : 10,
    LIMIT_LIST_EVENT : 20,
    LIMIT_CONFIG : 10,
    LIMIT_EVENT_ON_PROFILE : 5,
    LIMIT_NOTIFICATION : 10,
    SCORE_CREATE_ACCOUNT : 99,
    SCORE_LINK_ACCOUNT : 80,
    SCORE_REFERRAL : 99,
    SCORE_LIKE : 3,
    SCORE_COMMENT : 5,
    SCORE_CREATE_POST : 20,
    SCORE_LIKED_POST : 7,
    SCORE_COMMENTED_POST : 16,
    SCORE_JOIN_GROUP : 25,
    SCORE_MESSAGE : 1,
    SCORE_MESSAGE_GROUP : 4,
    SCORE_CREATE_EVENT : 80,
    SCORE_RSVP_EVENT : 25,
    SCORE_ATTEND_EVENT : 100,
    SCORE_FOLLOW_USER : 5,
    SCORE_FOLLOWED : 25,
    SCORE_VIEW_RESOURCE : 25,
    SCORE_SHARE_PLATFORM : 45,
    SCORE_INVITE_GROUP : 20,
    SCORE_INVITED_GROUP : 25,
    SCORE_FINISHED_TUTORIAL : 70,
    SCORE_INVITED_EVENT : 20,
    SCORE_INVITED_BY_REFERRAL : 100,
    TurnUp_Image : 'https://homepages.cae.wisc.edu/~ece533/images/airplane.png',
    TurnUp_logo1 : 'https://firebasestorage.googleapis.com/v0/b/rally-86ce1.appspot.com/o/images%2Flogo1.png?alt=media&token=28a2705a-99c5-4d95-a7ef-ee6d15e6e414',
    TurnUp_logo2 : 'https://firebasestorage.googleapis.com/v0/b/rally-86ce1.appspot.com/o/images%2Flogo2.png?alt=media&token=598d3292-302d-4f92-ac18-a9de34a58530',
    APP_LINK : 'https://apps.apple.com/us/app/turn-up/id1501461351',
    APP_STORE_LINK : 'itms-apps://itunes.apple.com/us/app/apple-store/id1501461351?mt=8',
    PLAY_STORE_LINK : 'market://details?id=com.turnupactivism',
    eventsCount : 9,     
    items : [
         {name : 'All', id : 'All', no : 0},
         {name : 'Black Lives Matter',id : 'Black Lives Matter', no : 1},
         {name : 'Civics Education & Sex Education',id : 'Civics Education & Sex Education', no : 2},
         {name : 'Climate Change & Environmental Justice',id : 'Climate Change & Environmental Justice', no : 3},
         {name : 'Criminal Justice & Policing Reform',id : 'Criminal Justice & Policing Reform', no : 4},
         {name : 'Education & Student Loans',id : 'Education & Student Loans', no : 5},
         {name : 'Gun Violence',id : 'Gun Violence', no : 6},
         {name : 'Healthcare & Mental Health',id : 'Healthcare & Mental Health', no : 7},
         {name : 'Human Rights',id : 'Human Rights', no : 8},
         {name : 'Immigration',id : 'Immigration', no : 9},
         {name : 'Intersectionality and Allyship',id : 'Intersectionality and Allyship', no : 10},
         {name : 'Labor',id : 'Labor', no : 11},
         {name : 'LGBTQIA+',id : 'LGBTQIA+', no : 12},
         {name : 'Persons with Disabilities',id : 'Persons with Disabilities', no : 13},
         {name : 'Racial Justice',id : 'Racial Justice', no : 14},
         {name : 'Spanish Language Event',id : 'Spanish Language Event', no : 15},
         {name : 'Veterans and Military Community',id : 'Veterans and Military Community', no : 16},
         {name : 'Vote at Age 16',id : 'Vote at Age 16', no : 17},
         {name : 'Voter Registration, Civics, and Fair Elections',id : 'Voter Registration, Civics, and Fair Elections', no : 18},
         {name : 'Women’s Rights and Reproductive Rights',id : 'Women’s Rights and Reproductive Rights', no : 19},
         {name : 'Others', id : 'Others', no : 20}
    ],
    items_issues : [
         {name : 'All',id : 'All', no : 0},
         {name : 'Black Lives Matter' , id : 'Black Lives Matter', no : 1},
         {name : 'Climate Change & Environmental Justice', id : 'Climate Change & Environmental Justice', no : 2},
         {name : 'Criminal Justice', id : 'Criminal Justice', no : 3},
         {name : 'Education', id : 'Education', no : 4},
         {name : 'Gun Violence', id : 'Gun Violence', no : 5},
         {name : 'Healthcare', id : 'Healthcare', no : 6},
         {name : 'Immigration', id : 'Immigration', no : 7},
         {name : 'Jobs and the Economy', id : 'Jobs and the Economy', no : 8},
         {name : 'Labor', id : 'Labor', no : 9},
         {name : 'LGBTQIA+', id : 'LGBTQIA+', no : 10},
         {name : 'Official Campaign Event', id : 'Official Campaign Event', no : 11},
         {name : 'Racial Justice', id : 'Racial Justice', no : 12},
         {name : 'Spanish Language Event', id : 'Spanish Language Event', no : 13},
         {name : 'Veterans and Military Community', id : 'Veterans and Military Community', no : 14},
         {name : 'Voter Registration, Civics, and Fair Elections', id : 'Voter Registration, Civics, and Fair Elections', no : 15},
         {name : 'Women’s Rights and Reproductive Rights',id : 'Women’s Rights and Reproductive Rights', no : 16},
         {name : 'Youth Organizing', id : 'Youth Organizing', no : 17},
         {name : 'Persons with Disabilities',id : 'Persons with Disabilities', no : 18},
    ],
    US_REGION : {

         latitude: 37.0902,
         longitude: -95.7159,
         latitudeDelta: 60.0922,
         longitudeDelta: 20.0421
     },
     statesList : [
         {value : 'AK'},
         {value : 'AL'},
         {value : 'AR'},
         {value : 'AZ'},
         {value : 'CA'},
         {value : 'CO'},
         {value : 'CT'},
         {value : 'DC'},
         {value : 'DE'},
         {value : 'FL'},
         {value : 'GA'},
         {value : 'HI'},
         {value : 'ID'},
         {value : 'IL'},
         {value : 'IN'},
         {value : 'IA'},
         {value : 'KS'},
         {value : 'KY'},
         {value : 'LA'},
         {value : 'MA'},
         {value : 'MD'},
         {value : 'ME'},
         {value : 'MI'},
         {value : 'MN'},
         {value : 'MO'},
         {value : 'MT'},
         {value : 'NC'},
         {value : 'ND'},
         {value : 'NE'},
         {value : 'NH'},
         {value : 'NJ'},
         {value : 'NM'},
         {value : 'NV'},        
         {value : 'NY'},        
         {value : 'OH'},
         {value : 'OK'},
         {value : 'OR'},
         {value : 'PA'},
         {value : 'PR'},
         {value : 'RI'},
         {value : 'SC'},
         {value : 'SD'},
         {value : 'TN'},
         {value : 'TX'},
         {value : 'UT'},
         {value : 'VT'},
         {value : 'VA'},
         {value : 'WA'},
         {value : 'WI'},
         {value : 'WV'},
         {value : 'WY'} 
     ],
     election_type : [
          { value : 'All'},
          { value : 'municipal'},
          { value : 'school'}
     ]
}

export default Config;