import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiSearch } from 'react-icons/bi';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import Link from "react-router-dom/Link";
import 'react-tiny-fab/dist/styles.css';
import { Profiles } from '../data/dummyData';
import { REQUEST_EVENTS as ACTION_REQUEST_EVENTS } from '../servicelayer/servicelayer-actions';
import '../styles/EventsPage.css';
import CONSTANTS, { MESSAGES } from '../util/data';
import CitySearchComponent from './CitySearchComponent';
import DisplayEvent from "./DisplayEvent";




const EventsPage = () => {

    const dispatch = useDispatch();

    const [type, setType] = useState(CONSTANTS.EVENTS_TYPE[0].value);
    const [state, setState] = useState(CONSTANTS.STATES[0].value);
    const [city, setCity] = useState('');
    const [tags, setTags] = useState(CONSTANTS.TAGS[0].value);
    const [sortby, setSortBy] = useState(CONSTANTS.EVENTS_SORTBY[0].value);
    const [category, setCategory] = useState(CONSTANTS.EVENTS_CATEGORY[0].value);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(CONSTANTS.TOMORROW);
    const [dateFilterType, setDateFilterType] = useState(CONSTANTS.EVENTS_DATEFILTER[0].value);
    const [searchTerm, setSearchTerm] = useState('');
    const eventFetchResult = useSelector(state => state.eventFetchResult);
    const [events, setEvents] = useState(null);

    const buildFilter = () => {
        let filter = {
            type: type !== CONSTANTS.EVENTS_TYPE[0].value ? type : null
            , state: state !== CONSTANTS.STATES[0].value ? state : null
            , city: city
            , tags: tags !== CONSTANTS.TAGS[0].value ? tags : null
            , sortby: sortby
            , category: category !== CONSTANTS.EVENTS_CATEGORY[0].value ? category : null
            , searchTerm: searchTerm !== '' ? searchTerm : null
            , last: eventFetchResult && eventFetchResult.last
        };
        // console.log('date filter type:', dateFilterType);
        if (dateFilterType !== CONSTANTS.EVENTS_DATEFILTER[0].value) //all
        {
            filter.startDate = startDate;
            if (dateFilterType === CONSTANTS.EVENTS_DATEFILTER[1].value) //ensure the start/end date range is one day
            {
                let d = new Date(filter.startDate);
                d.setDate(d.getDate() + 1);
                filter.endDate = d;
            }
            else {
                filter.endDate = endDate;
            }
        }
        return filter;
    }

    const fetchMoreEvents = () => {
        const filter = buildFilter();
        //console.log('fetchMoreEvents: filters:', filter);
        if (filter.last) {
            dispatch({ type: ACTION_REQUEST_EVENTS, payload: filter });
        }
    };

    const handleSubmit = event => {
        event && event.preventDefault();
        setEvents(null);
        const filter = buildFilter();
        //console.log('handleSubmit: filters:', filter);
        dispatch({ type: ACTION_REQUEST_EVENTS, payload: filter });
    };

    useEffect(() => {
        const filter = buildFilter();
        dispatch({ type: ACTION_REQUEST_EVENTS, payload: filter });
        // eslint-disable-next-line
    }, []);
    // useEffect(() => {
    //     console.log('Events were set.');
    //     if (events.length > 0) {
    //         console.log('event:', events);
    //     }
    // }, [events]);
    useEffect(() => {
        console.log('eventFetchResult:', eventFetchResult);
        if (eventFetchResult && eventFetchResult.events) {
            const newEventSet = [...(events || []), ...eventFetchResult.events];
            //console.log('Event Page: newEvents=>', newEventSet);
            setEvents(newEventSet);
        }

        // eslint-disable-next-line
    }, [eventFetchResult])

    const renderOptions = (items) => {
        if (!items) return;
        return items.map((item, i) => (<option key={i} >{item.value}</option>))
    }

    const showDateOptions = () => {
        let range = (dateFilterType === CONSTANTS.EVENTS_DATEFILTER[2].value);
        switch (dateFilterType) {

            case CONSTANTS.EVENTS_DATEFILTER[1].value: //Date
            case CONSTANTS.EVENTS_DATEFILTER[2].value: //Date Range
                return (<>
                    <Form.Group>
                        <Form.Label>{range ? "Start " : ""}Date</Form.Label>
                        <br />
                        <DatePicker className="form-control" selected={startDate} onChange={date => {
                            let d = new Date(date);
                            d.setHours(0, 0, 0);
                            setStartDate(d);
                        }} />
                    </Form.Group>
                    {range === true ? (<Form.Group  >
                        <Form.Label>End Date</Form.Label>
                        <br />
                        <DatePicker className="form-control" selected={endDate} onChange={date => {
                            let d = new Date(date);
                            d.setHours(0, 0, 0);
                            setEndDate(d)
                        }} />
                    </Form.Group>) : ""}
                </>
                )
            default:
                break;
        }

    }

    const handleCitySelect = (selection) => {

        if (!selection.address_components) return;
        let _city = null, _state = null;
        selection.address_components.forEach(address => {
            if (address.types.some(x => x === "locality")) {
                _city = address.long_name;
            }
            if (address.types.some(x => x === "administrative_area_level_1")) {
                _state = address.short_name;
            }
        });

        if (_city) setCity(_city);
        if (_state) setState(_state);

    }

    const handleClearFilters = () => {
        setType(CONSTANTS.EVENTS_TYPE[0].value);
        setState(CONSTANTS.STATES[0].value);
        setCity('');
        setTags(CONSTANTS.TAGS[0].value);
        setSortBy(CONSTANTS.EVENTS_SORTBY[0].value);
        setCategory(CONSTANTS.EVENTS_CATEGORY[0].value);
        setDateFilterType(CONSTANTS.EVENTS_DATEFILTER[0].value);
        setStartDate(new Date());
        setEndDate(CONSTANTS.TOMORROW);

        setTimeout(() => {
            handleSubmit();
        }, 500);
    }
    const onKeyPressed = (e) => {
        console.log('onKeyPressed:', e.charCode);
        if(e.charCode === 13) {
            handleSubmit();
        }
    }
    return (
        <Container className="events-search">
            <Row>
                <Col lg={9} xs={12} ><Button className="create-event"><Link className="nav-link" to='/CreateEvent'>CREATE EVENT</Link></Button></Col>
                <Col className={'layout-search'}>
                    <InputGroup>
                        <FormControl className={'search-input'} placeholder="Search for events" onChange={(e) => setSearchTerm(e.target.value)} onKeyPress={onKeyPressed}/>
                        <InputGroup.Append>
                            <Button type="submit" variant={'outline-secondary'} onClick={() => handleSubmit()}><BiSearch /></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col lg={9} xs={12}>
                    {(events || []).length === 0 ? (<div className={"no-event"}>{events === null ? 'Loading...' : MESSAGES.NoResults}</div>) : ""}
                    <InfiniteScroll initialLoad={false} loadMore={fetchMoreEvents} hasMore={true}
                        loader={(<div className={'loading'}><ReactLoading type={'bubbles'} color={'#402366'} height={50} width={50} /></div>)}>
                        {(events || []).map((Event, i) => {
                            //console.log('Event:', Event);
                            return (
                                <DisplayEvent key={Event.eventId} event={Event} profile={Profiles.amonte} />
                            );
                        })}
                    </InfiniteScroll>
                </Col>
                <Col >
                    <Form className="form d-lg-block d-none">
                        <Form.Group>

                        </Form.Group>
                        <h3>Filter:</h3>
                        <Form.Group>
                            <Form.Label>Event Type</Form.Label>
                            <Form.Control as="select" size="sm" value={type} onChange={e => setType(e.target.value)}>
                                {renderOptions(CONSTANTS.EVENTS_TYPE)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>State</Form.Label>
                            <Form.Control as="select" size="sm" value={state} onChange={e => setState(e.target.value)} >
                                {renderOptions(CONSTANTS.STATES)}
                            </Form.Control>
                        </Form.Group>
                        <CitySearchComponent onChange={handleCitySelect}></CitySearchComponent>
                        <Form.Group>
                            <div className="text-center radio">
                                {CONSTANTS.EVENTS_DATEFILTER.map((item, i) => {
                                    return (<Form.Check key={i} inline label={item.value} type="radio" name="formHorizontalRadios" checked={item.value === dateFilterType} onChange={() => { setDateFilterType(item.value); }} />)
                                })}
                            </div>
                        </Form.Group>
                        {
                            /** Will show if the date filter is not all */
                            showDateOptions()
                        }
                        <Form.Group>
                            <Form.Label>Tags</Form.Label>
                            <Form.Control as="select" size="sm" value={tags} onChange={e => setTags(e.target.value)}>
                                {renderOptions(CONSTANTS.TAGS)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Sort by</Form.Label>
                            <Form.Control as="select" size="sm" value={sortby} onChange={e => setSortBy(e.target.value)}>
                                {renderOptions(CONSTANTS.EVENTS_SORTBY)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Category</Form.Label>
                            <Form.Control size="sm" as="select" value={category} onChange={e => setCategory(e.target.value)}>
                                {renderOptions(CONSTANTS.EVENTS_CATEGORY)}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group className="text-center">
                            <Button className="apply-filter" onClick={handleSubmit}  >Apply Filters</Button>
                            <br />
                            <Button variant="link" className="clear-filter" onClick={handleClearFilters}>Clear Filters</Button>
                        </Form.Group>

                    </Form>
                </Col>
            </Row>
           
        </Container>
    );
};

export default EventsPage;
