import * as QueryString from 'query-string';
import React, { useEffect, useState, useRef } from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, Form, FormControl, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import "../styles/VoteInfo.css";
import VoteInfoHelper from '../util/VoteInfoHelper';
import placeholder from '../resources/placeholder.png';
import { getVotingInfoByState, getRegisterationInfoByState, loadElections } from '../util/util';
import Config from '../data/Config';
import { BsArrowLeft, BsFillGridFill, BsCalendar } from "react-icons/bs";
const moment = require('moment-timezone');
const VoteInfo = (props) => {

    const dispatch = useDispatch();

    const loggedInUser = useSelector(state => state.user);

    const [city, setCity] = useState('Cambridge');
    const [state, setState] = useState('MA');

    const stateRef = useRef('MA');
    const cityRef = useRef('Cambridge');

    const menuList = [{
        value: 'Representative'
    },
    { value: 'Voter Info' },
    { value: 'Registeration Info' },
    { value: 'Election Info' }];

    //representatives
    const [representatives, setRepresentatives] = useState([]);

    //voter information
    const [sections, setSections] = useState([]);
    const [votingInfo, setVotingInfo] = useState(null);
    //registration information
    const [sections_regist, setSectionsRegist] = useState([]);


    //elections
    const [elections, setElections] = useState([]);
    const [isElectionDetail, setIsElecitonDetail] = useState(false);
    
    const selectedElectionRef = useRef(null);
    const additionalInfoRef = useRef(null);

    useEffect(() => {
        console.log('loggedInUser:', loggedInUser);
        if (loggedInUser) {
            setCity(loggedInUser.city);
            setState(loggedInUser.state);

            cityRef.current = loggedInUser.city;
            stateRef.current = loggedInUser.state;

            processRepresentative();
        }
    }, [loggedInUser]);

    const [selectedIdx, setSelectedIdx] = useState(0);
    const onItemSelected = (index) => {
        console.log('onItemSelected index:', index);
        setSelectedIdx(index);
        switch (index) {
            case 0:
                processRepresentative();
                break;
            case 1:
                processVoteInfo();
                break;
            case 2:
                processRegistrationInfo();
                break;
            case 3:
                processElectionInfo();
                break;
            default:

        }
    }

    const processRepresentative = () => {
        console.log('getting users location');
        let address = `${cityRef.current}, ${stateRef.current}, USA`;
        VoteInfoHelper.getRepresentativeInfoByAddress(address)
            .then(res => res.data)
            .then(result => {
                console.log('offices:', result.offices);
                let array_rep = [];
                let offices = result.offices || [];
                offices.forEach(office => {

                    let indices = office.officialIndices;
                    (indices || []).forEach(index => {

                        let rep = {};
                        rep.title = office.name;
                        let official = result.officials[index];
                        rep.name = official.name;
                        rep.party = official.party;
                        rep.phones = official.phones;
                        rep.urls = official.urls;
                        rep.emails = official.emails;
                        rep.photoUrl = official.photoUrl;
                        array_rep.push(rep);
                    });

                });

                setRepresentatives(array_rep);

            })
            .catch(err => {
                console.log('err : ', err)
            });
    }
    const processVoteInfo = () => {
        console.log('vote info');
        getVotingInfoByState(stateRef.current, result => {
            //console.log('getVotingInfoByState result:',result);
            if (result) {
                // console.log('result:', result)
                setVotingInfo(result);
                let sections = [];

                for (var [key, value] of Object.entries(result)) {

                    let _key = key;

                    if (key === 'in-person') {
                        _key = 'In Person';
                    } else if (key == 'early-voting') {
                        _key = 'Early Voting';

                    } else if (key == 'by-mail') {
                        _key = 'By Mail';
                    }

                    let section = {};

                    section.title = _key;
                    section.content = value;

                    sections.push(section);
                }
                console.log('secionts:', sections);
                setSections(sections);
            }
            else {
                alert('No information available.');
            }
        });
    }
    const processRegistrationInfo = () => {
        console.log('registration info');
        getRegisterationInfoByState(stateRef.current, result => {
            if (result) {
                // console.log('result:', result)
                let sections = [];

                for (var [key, value] of Object.entries(result)) {

                    let _key = key;

                    if (key === 'in-person') {
                        _key = 'In Person';
                    } else if (key == 'online') {
                        _key = 'Online';

                    } else if (key == 'by-mail') {
                        _key = 'By Mail';
                    }

                    let section = {};

                    section.title = _key;
                    section.content = value;

                    sections.push(section);
                }
                console.log('section_regist:', sections);
                setSectionsRegist(sections);
            }
            else {
                alert('No information available.');
            }
        });
    }
    const processElectionInfo = () => {
        console.log('election info');
        let type = 'All';
        loadElections(stateRef.current, type, completion => {
            console.log('loadElections result:', completion);
            if (!completion) { alert('There are no upcoming elections.'); }
            else {
                setElections(completion);
            }
        });
    }
    const processInformation = (election) => {

        let additional = {};
        if (election['district-divisions']) {
            console.log('district-division:', election['district-divisions'].length);
            if ((election['district-divisions'] || []).length > 0) {
                let district_division = election['district-divisions'];
                console.log('district-division', district_division);
                if (district_division[0]['ocd-id']) additional['ocd-id'] = district_division[0]['ocd-id'];
                if (district_division[0]['primary-voting-method-source']) additional['primary-voting-method-source'] = district_division[0]['primary-voting-method-source'];
                if (district_division[0]['voter-registration-methods']) {
                    let methods = (district_division[0]['voter-registration-methods'] || []).length;
                    console.log('methods:', methods);
                    let registerMethods = [];
                    if (methods > 0) {
                        for (var i = 0; i < methods; i++) {
                            let method = {};
                            let registration_method = district_division[0]['voter-registration-methods'][i];
                            if (registration_method['deadline-online']) method['deadline-online'] = registration_method['deadline-online'];
                            if (registration_method['type']) method['type'] = registration_method['type'];
                            if (registration_method['url']) method['url'] = registration_method['url'];
                            if (registration_method['instructions']) {
                                if (registration_method['instructions']['registration']) {
                                    if (registration_method['instructions']['registration']['en']) {
                                        method['instructions'] = registration_method['instructions']['registration']['en'];
                                    }

                                }
                                if (registration_method['instructions']['idnumber']) {
                                    if (registration_method['instructions']['idnumber']['en']) {
                                        method['instructions_id'] = registration_method['instructions']['idnumber']['en'];
                                    }
                                }
                                if (registration_method['instructions']['signature']) {
                                    if (registration_method['instructions']['signature']['en']) {
                                        method['instructions_signature'] = registration_method['instructions']['signature']['en'];
                                    }
                                }
                                if (registration_method['instructions']['registration-form-url']) {
                                    if (registration_method['instructions']['registration-form-url']['en']) {
                                        method['instructions_form_url'] = registration_method['instructions']['registration-form-url']['en'];
                                    }
                                }
                            }

                            registerMethods.push(method);
                        }

                        additional['registerMethods'] = registerMethods;
                    }
                }
                if (district_division[0]['voting-methods']) {
                    let methods = (district_division[0]['voting-methods'] || []).length;
                    // console.log('methods:', methods);
                    let votingMethods = [];
                    if (methods > 0) {
                        for (var i = 0; i < methods; i++) {
                            let method = {};
                            let voting_method = district_division[0]['voting-methods'][i];
                            // console.log('voting method:', voting_method['excuse-required'])
                            if (voting_method['excuse-required'] !== undefined) method['excuse-required'] = voting_method['excuse-required'];
                            if (voting_method['type']) method['type'] = voting_method['type'];
                            if (voting_method['primary' !== undefined]) method['primary'] = voting_method['primary'];
                            if (voting_method['instructions']) {
                                if (voting_method['instructions']['voting-id']) {
                                    if (voting_method['instructions']['voting-id']['en']) {
                                        method['instructions'] = voting_method['instructions']['voting-id']['en'];
                                    }
                                }
                            }
                            if (voting_method['ballot-request-form-url']) {
                                if (voting_method['ballot-request-form-url']['en']) {
                                    method['ballot-request-form-url'] = voting_method['ballot-request-form-url']['en'];
                                }
                            }

                            votingMethods.push(method);
                        }

                        additional['votingMethods'] = votingMethods;
                    }
                }

            }
        }
        additionalInfoRef.current = additional;
        //setAdditionalInfo(additional);
    }

    useEffect(() => {

    }, [dispatch]);

    const renderRepresentative = () => {
        console.log('renderRepresentative');
        return <div>
            {
                representatives.map(representative => {
                    return (<div style={{ display: 'flex' }}>
                        <img className={'avatar'} src={representative.photoUrl ? representative.photoUrl : placeholder} ></img>
                        <div style={{ padding: 10 }}>
                            <p style={{ fontWeight: 'bold', fontSize: 15 }}>{representative.title}</p>
                            <p style={{ fontWeight: 'bold', fontSize: 20 }}>{representative.name}</p>
                            <p style={{ fontWeight: 'bold', fontSize: 15, color: 'blue' }}> {representative.party}</p>
                            <hr />
                        </div>
                    </div>)
                })
            }
        </div>
    }

    const renderVoteInfo = () => {
        return <div>
            <div className="state">
                {

                }
            </div>
            {
                sections.map(section => {
                    return <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}> {section.title} </p>
                        </div>
                        <div>
                            {
                                section.content.primary !== undefined &&
                                <div className={'content'}>
                                    <p className={'title'}>PRIMARY</p>
                                    <p className={'explain'}>{section.content.primary ? "Yes" : "No"}</p>
                                </div>
                            }
                            {
                                section.content['excuse-required'] !== undefined &&
                                <div className={'content'}>
                                    <p className={'title'}>Execuse Required</p>
                                    <p className={'explain'}>{section.content['excuse-required'] ? 'Yes' : 'No'}</p>
                                </div>
                            }
                            {
                                section.content['ballot-request-form-url'] !== undefined &&
                                <div className={'content'}>
                                    <p className={'title'}>Ballot Request Form URL</p>
                                    <a href={section.content['ballot-request-form-url']} >{section.content['ballot-request-form-url']}</a>
                                </div>
                            }
                            {
                                section.content.instructions !== undefined && section.content.instructions['voting-id'] !== undefined &&
                                <div className={"content"}>
                                    <p className={'title'}>Details</p>
                                    <p className={'explain'}>{section.content['instructions']['voting-id']}</p>
                                </div>
                            }
                        </div>
                    </div>
                })
            }</div>
    }
    const renderRegisterationInfo = () => {
        return <div>
            <div className="state">
                {

                }
            </div>
            {
                sections_regist.map(section => {
                    let array = [];
                    let data = section.content;
                    if (section.content.instructions) {
                        Object.keys(section.content.instructions).forEach(function (key) {
                            array.push({ key: key, value: section.content['instructions'][key] });
                        })
                    }

                    return <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}> {section.title} </p>
                        </div>
                        <div>
                            {
                                section.content.instructions !== undefined &&
                                <div>
                                    {
                                        array.map(item => {
                                            return <div className={"content"}>
                                                <p className={'title'}>{item.key}</p>
                                                <p className={'explain'}>{item.value}</p>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                            {
                                section.content['excuse-required'] !== undefined &&
                                <div className={'content'}>
                                    <p className={'title'}>Execuse Required</p>
                                    <p className={'explain'}>{data['excuse-required'] ? 'Yes' : 'No'}</p>
                                </div>
                            }
                            {
                                section.content['registration-form-url'] !== undefined &&
                                <div className={'content'}>
                                    <p className={'title'}>Registration Form URL</p>
                                    <p className={'explain'}>{section.content['registration-form-url']}</p>
                                </div>
                            }
                            {
                                section.content['url'] !== undefined &&
                                <div className={'content'}>
                                    <p className={'title'}>URL</p>
                                    <a href={section.content['url']}>{section.content['url']}</a>
                                </div>
                            }
                            {
                                section.content.instructions !== undefined && section.content.instructions['voting-id'] !== undefined &&
                                <div className={"content"}>
                                    <p className={'title'}>Ballot Request Form URL</p>
                                    <p className={'explain'}>{section.content['instructions']['voting-id']}</p>
                                </div>
                            }
                        </div>
                    </div>
                })
            }</div>
    }
    const renderElectionInfo = () => {
        //console.log('renderElectionsInfo()=> elections length:', elections.length);
        return (!isElectionDetail ? <div>
            {
                elections.map(election => {
                    return (<div onClick={() => { onClickElection(election) }}>
                        <p style={{ fontSize: 18, fontWeight: 'bold', marginTop: 8 }}>{election.description}</p>
                        <p style={{ fontSize: 15, marginTop: 8 }}>{moment(election.date).format('YYYY-MM-DD')}</p>
                        <hr />
                    </div>)
                })
            }
        </div>
            :
            <div style={{ paddingBottom: 30}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Button className={"back-button"} onClick={() => {
                        console.log('back button clicked...');
                        setIsElecitonDetail(false);
                    }}><span><BsArrowLeft /></span></Button>
                    <p style={{marginBottom : 0}}>{selectedElectionRef.current.description}</p>
                </div>
                <div style={{display: 'flex', paddingLeft : 15}}>
                    <BsCalendar />
                    <p style={{ color: '#303133', fontSize: 15, fontWeight: 'bold', marginLeft : 15 }}>{moment(selectedElectionRef.current.date).format('MMM ')}</p>
                    <p style={{ color: '#303133', fontSize: 15, fontWeight: 'bold' }}>{moment(selectedElectionRef.current.date).format('D,')}</p>
                    <p style={{ color: '#303133', fontSize: 15, fontWeight: 'bold' }}>{moment(selectedElectionRef.current.date).format('YYYY')}</p>
                </div>
                {
                    selectedElectionRef.current['polling-place-url'] !== undefined &&
                    <div>
                        <div className="header">
                            <p style={{ color: 'white', fontWeight: 'bold', marginBottom : 0 }}> Polling Place URL </p>
                        </div>
                        <div className={'content'}>
                            <a href={selectedElectionRef.current['polling-place-url']} >{selectedElectionRef.current['polling-place-url']}</a>
                        </div>
                    </div>
                }
                {
                    selectedElectionRef.current['polling-place-url-shortened'] !== undefined &&
                    <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}>Polling Place URL Shortened</p>
                        </div>
                        <div className={'content'}>
                            <a href={selectedElectionRef.current['polling-place-url-shortened']} >{selectedElectionRef.current['polling-place-url']}</a>
                        </div>
                    </div>
                }
                {
                    selectedElectionRef.current['population'] !== undefined &&
                    <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}>Population</p>
                        </div>
                        <div className={'content'}>
                            <p className={'explain'}>{selectedElectionRef.current['population']}</p>
                        </div>
                    </div>
                }
                {
                    selectedElectionRef.current['qa-status'] !== undefined &&
                    <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}>QA Status</p>
                        </div>
                        <div className={'content'}>                            
                            <p className={'explain'}>{selectedElectionRef.current['qa-status']}</p>
                        </div>
                    </div>
                }
                {
                    selectedElectionRef.current['website'] !== undefined &&
                    <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}>Website</p>
                        </div>
                        <div className={'content'}>
                            <a href={selectedElectionRef.current['website']} >{selectedElectionRef.current['website']}</a>
                        </div>
                    </div>
                }
                {
                    additionalInfoRef.current && additionalInfoRef.current['ocd-id'] !== undefined &&
                    <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}>OCD-ID</p>
                        </div>
                        <div className={'content'}>
                            <p className={'explain'}>{additionalInfoRef.current['ocd-id']}</p>
                        </div>
                    </div>
                }
                {
                    additionalInfoRef.current && additionalInfoRef.current['primary-voting-method-source'] !== undefined &&
                    <div>
                        <div className={'header'}>
                            <p style={{ color: 'white', fontWeight: 'bold' }}>Primary Voting Method Source</p>
                        </div>
                        <div className={'content'}>
                            <p className={'explain'}>{additionalInfoRef.current['primary-voting-method-source']}</p>
                        </div>
                    </div>
                }
                {
                    additionalInfoRef.current && additionalInfoRef.current['registerMethods'] !== undefined &&
                    <div>
                        <p style={{ fontWeight : 'bold'}}>Registeration Methods</p>
                        {
                            additionalInfoRef.current['registerMethods'].map(method => {
                                return method.type ?
                                    <div>
                                        <div className={'header'}>
                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{method.type}</p>
                                        </div>
                                        {
                                            method['instructions'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Instructions</p>
                                                <p className={'explain'}>{method['instructions']}</p>
                                            </div>
                                        }
                                        {
                                            method['deadline-online'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Online Deadline</p>
                                                <p className={'explain'}>{method['deadline-online']}</p>
                                            </div>
                                        }
                                        {
                                            method['url'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>URL</p>
                                                <a href={method['url']}>{method['url']}</a>
                                            </div>
                                        }
                                        {
                                            method['instructions_id'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Instructions</p>
                                                <p className={'explain'}>{method['instructions_id']}</p>
                                            </div>
                                        }
                                        {
                                            method['instructions_signature'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Signature</p>
                                                <p className={'explain'}>{method['instructions_signature']}</p>
                                            </div>
                                        }
                                    </div>
                                    :
                                    null
                            })
                        }
                    </div>
                }
                {
                    additionalInfoRef.current && additionalInfoRef.current['votingMethods'] !== undefined &&
                    <div>
                        <p style={{fontWeight: 'bold'}}>Voting Methods</p>
                        {
                            additionalInfoRef.current['votingMethods'].map(method => {
                                return method.type ?
                                    <div>
                                        <div className={'header'}>
                                            <p style={{ color: 'white', fontWeight: 'bold' }}>{method.type}</p>
                                        </div>
                                        {
                                            method['instructions'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Instructions</p>
                                                <p className={'explain'}>{method['instructions']}</p>
                                            </div>
                                        }
                                         {
                                            method['excuse-required'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Excuse Required</p>
                                                <p className={'explain'}>{method['excuse-required'] ? 'Yes' : 'No'}</p>
                                            </div>
                                        }
                                         {
                                            method['ballot-request-form-url'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Ballot Request Form URL</p>
                                                <a href={method['url']}>{method['ballot-request-form-url']}</a>
                                            </div>
                                        }
                                          {
                                            method['instructions_id'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Instructions</p>
                                                <p className={'explain'}>{method['instructions_id']}</p>
                                            </div>
                                        }
                                        {
                                            method['instructions_signature'] &&
                                            <div className={'content'}>
                                                <p className={'title'}>Signature</p>
                                                <p className={'explain'}>{method['instructions_signature']}</p>
                                            </div>
                                        }

                                    </div>
                                    :
                                    null
                            })
                        }
                    </div>

                }

            </div>
        )
    }
    const onClickElection = (election) => {
        console.log('onClickElection: election=>', election);
        selectedElectionRef.current = election;
        processInformation(election);

        setIsElecitonDetail(true);
    }

    return (
        <div>
            <Container className={'vote-info'}>
                <Row className={'vote-info-container'}>
                    <Col lg={3} xs={12} className={'menu-list'}>
                        {
                            menuList.map((menu, idx) => {
                                return <div className={'menu-item'} onClick={() => { onItemSelected(idx) }}>
                                    <p style={{ color: idx === selectedIdx ? 'indigo' : 'gray', fontWeight: 'bold' }}>{menu.value}</p>
                                    <hr />
                                </div>
                            })
                        }
                    </Col>
                    <Col>
                        {selectedIdx === 0 && renderRepresentative()}
                        {selectedIdx === 1 && renderVoteInfo()}
                        {selectedIdx === 2 && renderRegisterationInfo()}
                        {selectedIdx === 3 && renderElectionInfo()}

                    </Col>
                </Row>
            </Container>
        </div>)
}
export default VoteInfo;