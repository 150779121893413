import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormControl, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FaEdit, FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import placeholder from '../resources/placeholder.png';
import "../styles/UserProfile.css";
import { getBadges, getOrganizations, getPostCount, updateInfluenceGoal, useProfile } from '../util/util';
import Icon from './Icon';
import ProfileListItem from './ProfileListItem';
const UserProfile = () => {
  //dummy profile data

  // const Profile = {
  //   profileId: "1"
  //   , name: "Dummy Name"
  //   , imageUrl: "https://cdn.ebaumsworld.com/mediaFiles/picture/730195/86187705.jpg"
  //   , city: "Los Vegas"
  //   , state: "NV"
  //   , followerCount: 36
  //   , followingCount: 48
  //   , postCount: 5
  //   , biography: "Dummy description for dummy profile | We are all in this together"
  //   , impactScore: 3000
  //   , facebookUsername: "dummyfb"
  //   , snapchatUsername: "dummysc"
  //   , instagramUsername: "dummyin"
  //   , badge: 8
  //   , location: {
  //     lat: 2
  //     , lng: 20
  //   }
  // }
  const loggedInUser = useSelector(state => state.user);
  const [ profile ] = useProfile(loggedInUser ? loggedInUser.uid : null);
  const [postCount, setPostCount] = useState(0);
  const [influencePercent, setInfluentPercent] = useState(0);
  const [isShowGoalDlg, setIsShowGoalDlg] = useState(false);
  const [score, setScore] = useState(0);
  // const [badges, setBadges] = useState([]);
  const [badgeImage, setBadgeImage] = useState('');
  const [badgeText, setBadgeText] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const history = useHistory();

  useEffect(_ => {
    if (profile) {
      updatePostCount();
      //update influent percent
      let percent = Math.floor((profile.influenceScore / profile.influenceGoal) * 100) > 100 ? 100 : Math.floor((profile.influenceScore / profile.influenceGoal) * 100);
      console.log('percent:', percent, ':score:', profile.influenceScore)
      setInfluentPercent(percent);
      setScore(profile.influenceGoal);

      //Getting badges
      getBadges(_badges => {
        // setBadges(_badges);

        var breakException = {};
        let tempBadge = (_badges || []).length > 0 ? _badges[0].image : 'BronzeBadge';
        let textBadge = (_badges || []).length > 0 ? _badges[0].name : 'BronzeBadge';

        console.log('getBadges:', _badges);
        try {

          _badges.forEach(badge => {
            console.log('badge goal:', badge.goal);
            if (parseInt(badge.goal) > profile.influenceScore)
              throw breakException;
            tempBadge = badge.image;
            textBadge = badge.name;
          });

        } catch (e) {

        }
        //console.log('temp:', tempBadge, ':text:', textBadge);
        setBadgeImage(tempBadge === 'BronzeBadge' ? null : tempBadge);
        setBadgeText(textBadge);
      });

        //Getting organizations
        getOrganizations(profile.id, orgs => {
          if(orgs){
            setOrganizations(orgs);
          }
        });


    }
  }, [profile]);

  const updatePostCount = _ => {
    getPostCount(profile.id, count => {
      setPostCount(count)
    });
  }

  const statDisplay = (label, num) => {
    return (
      <Row xs={1}>
        <Col className={'label-profile'}>{label}</Col>
        <Col>{num}</Col>
      </Row>
    );
  }

  const renderTooltip = (props) => {
    let type;
    if (props.popper.state) {
      type = props.popper.state.options.type;
    }
    let username;
    switch (type) {
      case "fb":
        username = profile ? profile.facebook : '';
        break;
      case "ig":
        username = profile ? profile.instagram : '';
        break;
      case "sc":
        username = profile ? profile.snapchat : '';
        break;
      default:
        username = "No username found";
    }
    return (
      <Tooltip {...props}>{username}</Tooltip>
    );
  };
  const closeModal = () => {
    setIsShowGoalDlg(false);
  }
  const onChangeScore = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setScore(e.target.value);
    }
  }
  const updateScore = () => {
    setIsShowGoalDlg(false);
    updateInfluenceGoal(score, result => {

    });
  }
  const onSeeAllBadges = () => {
    history.push('/badge');
  }

  const onProfileDetail = (profile) => {
    window.open(`/ProfileDetail?id=${profile.id}`,'_self');
  }

  const onClickFollowers = () => {
    // console.log('onClickFollowers');
    window.open(`/ProfileList?type=follower&uid=${profile.id}`,'_self');
  }
  const onClickFollowings = () => {
    // console.log('onClickFollowings');
    window.open(`/ProfileList?type=following&uid=${profile.id}`,'_self');
  }

  const onClickPosts = () => {
    console.log('onClickPosts...');
    window.open(`/PostList?type=mypost&uid=${profile.id}`,'_self');
  }

  const renderGoalDialog = () => {
    return <Modal show={isShowGoalDlg} onHide={closeModal}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Container>
          <Form.Row>
            <Form.Group as={Col} controlId="score_edit">
              <Form.Label>Please input the influence goal.</Form.Label>
              <FormControl autoFocus placeholder="Influence goal" size="lg" type="text"
                value={score} onChange={onChangeScore} />
            </Form.Group>
          </Form.Row>
          <Row>
            <Col >
              <div className={'dialog-button'}>
                <Button onClick={updateScore}>OK</Button>
              </div>
            </Col>
            <Col>
              <div className={'dialog-button'}>
                <Button onClick={closeModal}>Cancel</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  }

  const onSeeAll = () => {
    console.log('onSeeAll..');
    window.open(`/ProfileList?type=organization&uid=${profile.id}`,'_self');
  }
  const onEditProfile = () => {
    console.log('onEditProfile..')
    window.open('/EditProfile', '_self');
  }
  const onSetting = () => {
    console.log('onSetting..')
    window.open('/SettingsPage', '_self');
  }
  return (
    <div className="user-profile-page">
      <Container className="profile-info">
        <Row>
          <Col xs={{ span: 12, order: 1 }} lg={{ span: 2, order: 1 }}>
            <Image fluid roundedCircle src={profile && profile.imageUrl ? profile.imageUrl : placeholder} />
            <div className="name-location">
              <p>{profile ? profile.name ? profile.name : profile.username ? profile.username : 'Loading..' : 'Loading..'}</p>
              <p>{profile ? profile.city : 'cambridge'}, {profile ? profile.state : 'MA'}</p>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 5 }} lg={{ span: 5, order: 2 }}>
              <Row className="profile-stats">
                <Col onClick={() => onClickFollowers()}>{statDisplay("FOLLOWERS", profile ? profile.followerCount : 0)}</Col>
                <Col onClick={() => onClickFollowings()}>{statDisplay("FOLLOWING", profile ? profile.followingCount : 0)}</Col>
                <Col onClick={() => onClickPosts()}>{statDisplay("POSTS", postCount)}</Col>
              </Row>
          </Col>
          <Col className="edit-profile" xs={{ span: 12, order: 4 }} lg={{ span: 2, order: 3 }}>
            <Button onClick={() => onEditProfile()} className={'btn-edit'}>Edit Profile</Button>
            <Button onClick={() => onSetting()} className={'btn-settings'}>Settings</Button>
          </Col>
          {/* <Col xs={{ span: 12, order: 6 }} lg={{ span: 3, order: 4 }} className="action-buttons">
            <Button>+<p>Follow</p></Button>
            <Button><BsFillChatSquareFill /><p>Message</p></Button>
          </Col> */}
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 12, order: 5 }} className="profile-bio">
            <p>{profile ? profile.bio : ''}</p>
          </Col>
          <Col xs={{ span: 12, order: 3 }} lg={{ span: 12, order: 6 }} className="social-media">
            {
              profile && profile.facebook != null && profile.facebook.length > 0 &&
              <OverlayTrigger popperConfig={{ type: "fb" }} overlay={renderTooltip}><span>
                <Icon><div className="circle-icon"><FaFacebookF /></div></Icon>
              </span></OverlayTrigger>
            }
            {
              profile && profile.instagram != null && profile.instagram.length > 0 &&
              <OverlayTrigger popperConfig={{ type: "ig" }} overlay={renderTooltip}><span>
                <Icon><div className="circle-icon"><FaInstagram /></div> </Icon>
              </span></OverlayTrigger>
            }
            {
              profile && profile.snapchat != null && profile.snapchat.length > 0 &&
              <OverlayTrigger popperConfig={{ type: "tw" }} overlay={renderTooltip}><span>
                <Icon><div className="circle-icon"><FaTwitter /></div></Icon>
              </span></OverlayTrigger>
            }
          </Col>
        </Row>
      </Container>
      <Container className="profile-subsections">
        <Container>Impact Score
          <Row>
            <Col>
              <ProgressBar striped now={influencePercent} variant="success" key={1} />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{influencePercent}% of your goal</p>
            </Col>
            <Col className="right-align action-buttons">
              <Button onClick={() => setIsShowGoalDlg(true)}>Edit</Button>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={'auto'} className={'badgeImage'}>
              <Image width="60px" height="60px" roundedCircle src={badgeImage} />
            </Col>
            <Col xs={'auto'}>
              <Row>
                <Col><p className={'badge-text'}>{badgeText}</p></Col>
              </Row>
              <Row>
                <Col className={'action-buttons'}>
                  <Button className={'btn-transparent'} onClick={() => onSeeAllBadges()}>See All</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container>Event's I'm attending</Container>
        <Container className={'container-organization'}>
          <p>My Organizations</p>
          <Row className={'list-organization'}>
            {
              organizations.map(organization => {
                return (
                  <div className={'org-list-item'}>
                    <ProfileListItem profile={organization} onClickProfile={(profile) => onProfileDetail(profile)} isVertical={true}></ProfileListItem>
                  </div>
                );

              })
            }
          </Row>
          <Row onClick={() => onSeeAll()}><p className={'see-all'}>See All</p></Row>
        </Container>
        <Container>Previous Events</Container>
      </Container>
      {renderGoalDialog()}
    </div>
  );
};

export default UserProfile;
