import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from "react-redux";
import { REQUEST_NOTIFICATIONS as ACTION_REQUEST_NOTIFICATIONS } from "../servicelayer/servicelayer-actions";
import '../styles/NotificationsPage.css';
import { MESSAGES } from '../util/data';
import NotificationItem from './NotificationItem';
const NotificationsPage = (props) => {
    const dispatch = useDispatch();

    const notificationFetchResults = useSelector(state => state.notificationFetchResult);
    const [notifications, setNotifications] = useState(null);

    const loggedInUser = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if (loggedInUser && loggedInUser.uid) {
            let param = 
            { 
                last: notificationFetchResults && notificationFetchResults.last,
                uid : loggedInUser.uid
            };
            //console.log('param:', param);
            setLoading(true);
            dispatch({ type: ACTION_REQUEST_NOTIFICATIONS, payload: param });
        }
    }, [loggedInUser]);

    useEffect(() => {
        //console.log('notificationResults: last=>', notificationFetchResults);
        if (notificationFetchResults && notificationFetchResults.notifications) {
            console.log('last:', notificationFetchResults.last);
            const newNotifications = [...(notifications || []), ...notificationFetchResults.notifications];
            setNotifications(newNotifications);
            setLoading(false);
        }
    }, [notificationFetchResults]);

    const fetchMoreNotifications = () => {
        //console.log('fetchMoreNotifications:');
        if (notificationFetchResults && notificationFetchResults.last) {
            let param = 
            { 
                last: notificationFetchResults && notificationFetchResults.last,
                uid : loggedInUser.uid
            };
            //console.log('param :', param);
            setLoading(true);
            dispatch({ type: ACTION_REQUEST_NOTIFICATIONS, payload: param })
        }
    }

    return (<Container className={'notification-page'}>
        <Row>
            <Col>
                {(notifications || []).length === 0 ? (<div className={"no-notification"}>{notifications === null ? 'Loading...' : MESSAGES.NoResults}</div>) : ""}
                <InfiniteScroll initialLoad={false} loadMore={fetchMoreNotifications} hasMore={true}
                    loader={
                        (
                        loading ?     
                        <div className={'loading'}><ReactLoading type={'bubbles'} color={'#402366'} height={50} width={50} /></div>
                        :
                        <div></div>
                        )}>
                    {(notifications || []).map((Notification, i) => {
                        //console.log('Notification:', Notification);
                        return (
                            <NotificationItem notification={Notification} />
                        );
                    })}
                </InfiniteScroll>
            </Col>
        </Row>
    </Container>)
}

export default NotificationsPage;