/**
 *
 * @description Static data to be shared across application.
 */

 const states = [
    {value : 'All'},
    {value : 'AK'},
    {value : 'AL'},
    {value : 'AR'},
    {value : 'AZ'},
    {value : 'CA'},
    {value : 'CO'},
    {value : 'CT'},
    {value : 'DC'},
    {value : 'DE'},
    {value : 'FL'},
    {value : 'GA'},
    {value : 'HI'},
    {value : 'ID'},
    {value : 'IL'},
    {value : 'IN'},
    {value : 'IA'},
    {value : 'KS'},
    {value : 'KY'},
    {value : 'LA'},
    {value : 'MA'},
    {value : 'MD'},
    {value : 'ME'},
    {value : 'MI'},
    {value : 'MN'},
    {value : 'MO'},
    {value : 'MT'},
    {value : 'NC'},
    {value : 'ND'},
    {value : 'NE'},
    {value : 'NH'},
    {value : 'NJ'},
    {value : 'NM'},
    {value : 'NV'},
    {value : 'NY'},
    {value : 'OH'},
    {value : 'OK'},
    {value : 'OR'},
    {value : 'PA'},
    {value : 'PR'},
    {value : 'RI'},
    {value : 'SC'},
    {value : 'SD'},
    {value : 'TN'},
    {value : 'TX'},
    {value : 'UT'},
    {value : 'VT'},
    {value : 'VA'},
    {value : 'WA'},
    {value : 'WI'},
    {value : 'WV'},
    {value : 'WY'}

];

const tags = [
    {value : 'All'},
    {value : 'Black Lives Matter'},
    {value : 'Climate Change & Environmental Justice'},
    {value : 'Criminal Justice'},
    {value : 'Education'},
    {value : 'Gun Violence'},
    {value : 'Healthcare'},
    {value : 'Immigration'},
    {value : 'Jobs and the Economy'},
    {value : 'Labor'},
    {value : 'LGBTQIA+'},
    {value : 'Official Campaign Event'},
    {value : 'Racial Justice'},
    {value : 'Spanish Language Event'},
    {value : 'Veterans and Military Community'},
    {value : 'Voter Registration, Civics, and Fair Elections'},
    {value : 'Women’s Rights and Reproductive Rights'},
    {value : 'Youth Organizing'},
    {value : 'Persons with Disabilities'}

];


const addDays =  (day, days) => {
    let date = new Date(day.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

const CONSTANTS = {
    STATES:states
    ,TAGS:tags
    ,EVENTS_SORTBY:[ {value:"Soonest"}, {value:"Closest"},{value:"Popularity"}]
    ,EVENTS_CATEGORY: [{value:"Both"}, {value:"Political Campaign"},{value:"Activism"}]
    ,EVENTS_TYPE:[{value:"Both"}, {value:"Virtual"},{value:"In Person"}]
    ,EVENTS_DATEFILTER:[{value:"All"}, {value:"Date"},{value:"Date Range"}]
    ,TOMORROW:()=> { let d = new Date();d.setHours(0,0,0);  return addDays(d, 1);}
    ,TODAY:()=>{ let d = new Date();d.setHours(0,0,0);  return d;}
    }


/**
 * The mobile app creates arrays to work around firestore text search limitations. A better approach needs to be taken in the future.
 * @param {*} input
 */
export const toFirebaseSearchArray = (input) =>
{
    let retval =[];
    let inputSplit =  input.toLowerCase().split(" ");
    inputSplit.forEach((word) => {
      let temp = [];
      for (var i = 0; i < word.length; i++) {
        temp.push(word.charAt(i));
        if (i > 0) {
          retval.push(temp.join(""));
        }
      }
    });
    for (var j = 0; j < input.length; j++) {
      if (j > 1) {
        retval.push(input.substring(0, j + 1).toLowerCase());
      }
    }
    return retval;
}

export const MESSAGES = {NoResults:"No results were found matching your query."};

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

/**
 * Returns short month name, i.e. "Jan"
 * @param {Date} date
 */
export const toShortMonthName = (date) =>
{
  return months[date.getMonth()];
}

export default CONSTANTS;


export const LINKS = {
  URL_PRIVATE_POLICY: "https://www.turnup.us/privacy-policy-and-terms/"
  ,URL_OUR_VALUE: "http://www.turnup.us/our-values/"
  ,URL_REQUEST_GOOGLE_FORM: "https://forms.gle/qVRy2GQCRb1nodhr6"
  ,URL_GIVE_FEEDBACK: "https://forms.gle/LLC23Wh6GB69LmJ47"
  ,URL_DONATE: "https://secure.actblue.com/donate/turnup-activism-1"
  ,URL_JOIN_TURNUP: "https://docs.google.com/forms/d/1bEGTHL2Gi6vVseYm4gkZdNBPlkvtTSDwMYefGtbVBzA/edit?usp=drive_web"
  ,URL_VERIFICATION_REQUEST: "https://docs.google.com/forms/d/12SeMPa_FfUJcG7Cstcdw-mF0VRObhCnjMTZSuSPrLXE/viewform?edit_requested=true"
}