/**
 * @file Nav Bar Component.
 * @author - Adil Merribi
 */
import React, { useEffect } from "react";
import { Form, Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { AiFillBell, AiFillHome } from "react-icons/ai";
import { BiCalendarEvent } from "react-icons/bi";
import { FaBook, FaCog, FaUserFriends, FaHandsHelping, FaComment, FaRegBell } from "react-icons/fa";
import { MdBook } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import Link from "react-router-dom/Link";
import logo from '../resources/logo.png';
import placeholder from '../resources/placeholder.png';
import { REQUEST_LOGOUT } from '../servicelayer/servicelayer-actions';
import '../styles/NavBarComponent.css';
import { isMobile } from 'react-device-detect';

const NavBarComponent = ({ img, loggedIn, user }) => {

    const dispatch = useDispatch();
    // console.log('image:', img);
    const logout = () => {
        // console.log('loging out..');
        dispatch({type : REQUEST_LOGOUT, payload : {} });
    }
    return (
        <Navbar collapseOnSelect className="turnup-nav" expand="lg" sticky="top">
            {/* <Navbar.Brand href="/TurnupHome">Brand</Navbar.Brand> */}
            <Navbar.Brand href="/HomePage"><Image className="logo" alt="TurnUp Activism" src={logo}/></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav  className="mr-auto main-nav">
                    <Nav.Link href="/HomePage" className="nav-item"><div>{!isMobile &&<AiFillHome />}{!isMobile &&<br/>}<span> Home</span></div></Nav.Link>
                    <Nav.Link href="/EventsPage" className="nav-item"><div>{!isMobile &&<BiCalendarEvent />}{!isMobile &&<br/>}<span>  Events</span></div></Nav.Link>
                    <Nav.Link href="/ConnectPage" className="nav-item"><div>{!isMobile &&<FaHandsHelping />}{!isMobile &&<br/>}<span>  Connect</span></div></Nav.Link>
                    {/* <Nav.Link href="/ResourcePage"><FaBook /><span>Resources</span></Nav.Link> */}
                    {
                     !isMobile && <div style={{display: 'flex', flexDirection : 'column', alignItems: 'center', marginTop : 5}}>
                        <MdBook className="e" />
                        <NavDropdown title="Resources" className="nav-item-resource">
                            <Link className="dropdown-item" to='/ResourcePage'>ISSUES & ACTIVISM</Link>
                            <Link className="dropdown-item" to="/VoteInfo" >ELECTIONS & VOTE</Link>
                        </NavDropdown>
                    </div>
                    }
                    <Nav.Link href={user ?`/Message?uid=${user.uid}` : `/Message`} className="nav-item"><div>{!isMobile &&<FaComment />}{!isMobile &&<br/>}<span> Messages</span></div></Nav.Link>
                    {/* <NavDropdown title="Interns" id="basic-nav-dropdown"><FaComment />
                    <Link className="dropdown-item" to='/playground'>Playground</Link>
                        <Link className="dropdown-item" to='/interns/reacttask2'>React Task 2</Link>
                        <Link className="dropdown-item" to="/interns/soniaPage">Sonia's Page</Link>
                        <Link className="dropdown-item" to="/interns/adilPage">Adil's Page</Link>
                        <Link className="dropdown-item" to="/CreateAccount">Create Account</Link>
                        <Link className="dropdown-item" to="/TestingForm">Form Test</Link>
                        <Link className="dropdown-item" to="/ReactFormikTest">React Bootstrap Formik Example</Link>
                        <Link className="dropdown-item" to="/ResourcePage">Resource Page</Link>
                        <Link className="dropdown-item" to="/PhoneConfirm">Phone Confirm</Link>
                        <Link className="dropdown-item" to="/EventDetails">Event Details</Link>
                        <Link className="dropdown-item" to="/UserProfile">User Profile</Link>
                        <Link className="dropdown-item" to="/SettingsPage">Settings</Link>
                    </NavDropdown> */}

                    {
                        isMobile && 
                            <Nav.Link href="/SettingsPage" className="nav-item"><div><span> Settings</span></div></Nav.Link>
                    }
                     {   isMobile && 
                            <Nav.Link href="/notifications" className="nav-item"><div><span> Notifications</span></div></Nav.Link>
                    }
                    {   isMobile && 
                            <Nav.Link href="/UserProfile" className="nav-item"><div><span> View Profile</span></div></Nav.Link>
                    }
                    {   isMobile && 
                            <Nav.Link href="/UserProfile" className="nav-item"><div><span> Logout</span></div></Nav.Link>
                    }
                   
                </Nav>
                 {
                     !isMobile && 
                     <Form className="side-nav  col-lg-3 col-sm-4" inline hidden={!loggedIn}>
                        <Link className={'side-icon'} to="/notifications"><FaRegBell/></Link>
                        <div>
                                <Link to="/UserProfile"><Image className="profile-img" src={img ? img : placeholder}  roundedCircle /></Link>
                                <NavDropdown title="" className="nav-item-profile">
                                        <Link className={'dropdown-item'} onClick={logout}>Log Out</Link>
                                        <Link className={'dropdown-item'} to="/SettingsPage">Settings</Link>
                                        <Link className={'dropdown-item'} to="/UserProfile">View Profile</Link>
                                </NavDropdown>
                        </div>
                    </Form>
                }
            </Navbar.Collapse>
            {/* <Navbar.Collapse id="responsive-navbar-nav"> */}
                {/* <Form className="main-nav col-lg-9 col-sm-8 justify-content-center" inline hidden={!loggedIn} >
                    
                    <Link to="/HomePage"><AiFillHome /><span>Home</span></Link>
                    <Link to="/EventsPage"><BiCalendarEvent /><span>Events</span></Link>
                    <Link to="/Connect"><FaUserFriends /><span>Connect</span></Link>
                    <Link to="/ResourcePage"><FaBook /><span>Resources</span></Link>
                    <NavDropdown title="Interns" id="basic-nav-dropdown">
                    <Link className="dropdown-item" to='/playground'>Playground</Link>
                        <Link className="dropdown-item" to='/interns/reacttask2'>React Task 2</Link>
                        <Link className="dropdown-item" to="/interns/soniaPage">Sonia's Page</Link>
                        <Link className="dropdown-item" to="/interns/adilPage">Adil's Page</Link>
                        <Link className="dropdown-item" to="/CreateAccount">Create Account</Link>
                        <Link className="dropdown-item" to="/TestingForm">Form Test</Link>
                        <Link className="dropdown-item" to="/ReactFormikTest">React Bootstrap Formik Example</Link>
                        <Link className="dropdown-item" to="/ResourcePage">Resource Page</Link>
                        <Link className="dropdown-item" to="/PhoneConfirm">Phone Confirm</Link>
                        <Link className="dropdown-item" to="/EventDetails">Event Details</Link>
                        <Link className="dropdown-item" to="/UserProfile">User Profile</Link>
                        <Link className="dropdown-item" to="/SettingsPage">Settings</Link>
                    </NavDropdown>
                </Form> */}

                {/* <Form className="side-nav  col-lg-3 col-sm-4" inline hidden={!loggedIn}>
                    <Link to="/notifications"><AiFillBell /></Link>
                    <Link to="/SettingsPage"><FaCog /></Link>
                    <Link to="/UserProfile"><Image className="profile-img" src={img} roundedCircle /></Link>
                    <Link onClick={logout} className="logout">Log Out</Link>
                </Form> */}
            {/* </Navbar.Collapse> */}
        </Navbar>
    );
};

export default NavBarComponent;
