import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { BsBellFill, BsCheck, BsCompass, BsX } from 'react-icons/bs';
import '../styles/NotificationItem.css';
import { acceptFollowRequest, acceptGroupRequest, acceptInvitationForEvent, acceptInvitationForGroup, declineFollowRequest, declineGroupRequest, rejectInvitationForEvent, rejectInvitationForGroup } from '../util/util';
const NotificationItem = ({ notification }) => {    
    const { type } = notification;

    // let isActive = (
    //     type === 'eventInvitation' ||
    //     type === 'groupInvitation' ||
    //     type === 'followRequest' ||
    //     type === 'groupRequest' ||
    //     !read);

    //console.log('isActive:', isActive);
    const renderNotificationIcon = (type, url = null) => {
        //console.log('type:', type, ':url:', url);
        switch (type) {
            case 'eventInvitation':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />
            case 'groupInvitation':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />
            case 'followRequest':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />
            case 'groupRequest':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />                
            case 'followed':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />
            case 'display_event':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />
            case 'display_profile':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />
            case 'display_post':
                return url && url !== 'none' ?
                    <img src={url} style={{ width: 30, height: 30, borderRadius: 22 }} alt={'notification'}/>
                    :
                    <BsBellFill className={'icon-notification'} />
            case 'display_badge':
                return <BsCompass className={'icon-notification'} />
            default:
                return <BsBellFill className={'icon-notification'} />

        }
    }
    const renderNotificationButton = (type) => {
        return (
            <div>
                {
                    type === 'groupRequest' ?
                        <Row className={'button-layout'}>
                            <Button className={'button-confirm'} onClick={() => {
                                acceptGroupRequest(notification.groupID, notification.id, notification.uid);
                            }}>
                                <BsCheck />
                            </Button>
                            <Button className={'button-confirm'} onClick={() => {
                                declineGroupRequest(notification.groupID, notification.id, notification.uid);
                            }}>
                                <BsX />
                            </Button>
                        </Row>
                        :
                        null
                }
                {
                    type === 'followRequest' ?
                        <Row className={'button-layout'}>
                            <Button className={'button-confirm'} onClick={() => {
                                acceptFollowRequest(notification.followerID, notification.ref, notification.id, completion => {

                                });
                            }}>
                                <BsCheck />
                            </Button>
                            <Button className={'button-confirm'} onClick={() => {
                                declineFollowRequest(notification.followerID, notification.id, completion => {

                                });
                            }}>
                                <BsX />
                            </Button>
                        </Row>
                        :
                        null
                }
                {
                    type === 'eventInvitation' ?
                        <Row className={'button-layout'}>
                            <Button className={'button-confirm'} onClick={() => {
                                acceptInvitationForEvent(notification.eventId, notification.id, completion => {

                                });
                            }}>
                                <BsCheck />
                            </Button>
                            <Button className={'button-confirm'} onClick={() => {
                                rejectInvitationForEvent(notification.eventId, notification.id, completion => {

                                });
                            }}>
                                <BsX />
                            </Button>
                        </Row>
                        :
                        null
                }
                {
                    type === 'groupInvitation' ?
                        <Row className={'button-layout'}>
                            <Button className={'button-confirm'} onClick={() => {
                                acceptInvitationForGroup(notification.groupId, notification.id, completion  => {

                                });
                            }}>
                                <BsCheck />
                            </Button>
                            <Button className={'button-confirm'} onClick={() => {
                                rejectInvitationForGroup(notification.groupId, notification.id, completion => {

                                });
                            }}>
                                <BsX />
                            </Button>
                        </Row>
                        :
                        null
                }

            </div>
        );
    }

    return (
        <div>
    <Row className={'notification-item'}>
        {renderNotificationIcon(notification.type, notification.url)}
        <div className={'notification-content'} onClick={() => {
            if (type === "eventInvitation") {
                // props.navigation.navigate('EventDetail', {
                //     profileID: notification.eventUserId,
                //     eventID: notification.eventId,
                //     eventName: notification.eventName ? notification.eventName : ''
                // });
                window.open(`/EventDetails?event=${notification.eventId}`, '_self');
            }
            else if (type === 'groupInvitation') {
                // props.navigation.push('GroupProfile', { groupID: notification.groupId });
            }
            else if (type === 'followRequest') {
                console.log('pushed to profile. notification ID:', notification.followerID);
                // props.navigation.push('Profile', { profileId: notification.followerID });
                window.open(`/ProfileDetail?id=${notification.followerID}`,'_self');
            }
            else if (type === 'followed') {
                console.log('pushed to profile. notification ID:', notification.followerID);
                //props.navigation.push('Profile', { profileId: notification.followerID });
                window.open(`/ProfileDetail?id=${notification.followerID}`,'_self');
            }
            else if (type === 'display_event') {
                console.log('clicked_event', notification);
                if (notification.profileID && notification.eid && notification.eventName) {
                    // props.navigation.navigate('EventDetail', {
                    //     profileID: notification.profileID,
                    //     eventID: notification.eid,
                    //     eventName: notification.eventName ? notification.eventName : ''
                    // });

                    window.open(`/EventDetails?event=${notification.eid}`, '_self');
                }
            }
            else if (type === 'display_profile') {
                console.log('clicked_profile');
                // props.navigation.push('Profile', { profileId: notification.uid });
                window.open(`/ProfileDetail?id=${notification.uid}`,'_self');
            }
            else if (type === 'display_badge') {
                console.log('clicked_profile');
                //props.navigation.push('Profile', { profileId: notification.uid });
                window.open(`/ProfileDetail?id=${notification.uid}`,'_self');
            }
            else if (notification.type === 'display_post') {
                console.log('clicked_post');
                if (notification.profileID && notification.pid && notification.postType) {
                    // props.navigation.navigate('PostDetail', {
                    //     profileID: notification.profileID,
                    //     postID: notification.pid,
                    //     type: notification.postType === "event" ? "event" : "post",
                    //     fromNoti: true
                    // });
                }

            }
        }}>
            <p>{notification.content}</p>
            {renderNotificationButton(type)}
        </div>
    </Row>
    <hr />
    </div>);
}

export default NotificationItem;