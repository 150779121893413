/**
 * @description  Services are responsible for normalizing data returned from the DAL and publishing the payload along with an identifying action. 
 * @author - Amonte
 */
// import moment from 'moment';
import moment from 'moment-timezone';
import { call, put } from 'redux-saga/effects';
import uuid from 'uuid-random';
import ImageService from './imageService';
import { Normalize } from './normalizer';
import * as ACTION from './servicelayer-actions';
import * as DAL from './servicelayer-dal';
import { CreateEventModel, CreatePostModel } from './types';

function* fetchFeed(dispatchObject:any)
{
    
    try{
        let payload = dispatchObject.payload;
        // console.log('fetchFeed payload :', payload);
        let feedResult = yield call(DAL.getFeed, payload.uid, payload.after, payload.view);
        // console.log('fetchResult:', feedResult);
        //let events = Normalize.events(feedResult.events);
        let posts = Normalize.posts(feedResult.posts);
        let lastRef = feedResult.last;
        yield put({
            type: ACTION.RESPONSE_FEED,
            data: {events:[], posts:posts, last:lastRef}
        });

    }
    catch(e){
        console.log('fetchFeed Error e:', e);
        yield put({
            type:ACTION.RESPONSE_FEED,
            data:{error:e, payload:dispatchObject},
            isError:true
        });
    }
}

function* queryEvents(dispatchObject:any)
{
    try {
        let filter = dispatchObject.payload;
        let result = yield call(DAL.fetchEvents, filter);
        if (result.events) {
            
            result.events = Normalize.events(result.events);
            yield put({ type: ACTION.RESPONSE_EVENTS, data: result })
        } else {
        }
    }
    catch (e) {
        console.log('queryEvents : error:', e);
        yield put({ type: ACTION.RESPONSE_EVENTS_ERROR, data: { error: e, message: "Failed to retrieve events." } });
    }
}

function validateEvent(event:CreateEventModel)
{
    let errorMessage = "";
    let valid = true;
    if(!event.state || event.state === "")
    {
        valid = false;
        if(errorMessage.length >0) errorMessage+=" ";
        errorMessage+="A state must be provided."
    }

    if(!event.eventTitle || event.eventTitle.length === 0)
    {
        valid = false;
        if(errorMessage.length >0) errorMessage+=" ";
        errorMessage+="An event title must be provided.";
    }
    if(!event.eventLocation || !event.eventLocation.lat || !event.eventLocation.lng)
    {
        valid = false;
        if(errorMessage.length >0) errorMessage+=" ";
        errorMessage+="Event location data must be provided.";
    }
    //additional validation may be needed
    return {valid:valid,errorMessage:errorMessage};
    
}

function* createEvent(dispatchObject:any)
{
    
    let docid = uuid();
    let _event = dispatchObject.payload;
    let now = new Date();
    let location ={lat:0, lng:0};
    let locationResult = _event.location;
    if(locationResult)
    {
        location.lat = locationResult.geometry.location.lat();
        location.lng = locationResult.geometry.location.lng(); 
    }
    
    let event: CreateEventModel = {
      category: "Activism",
      city: _event.city.toLowerCase(),
      eventAbout: _event.description,
      eventDate: moment(_event.eventStartDate).tz('America/Manaus').format(),
      eventEndDate: moment(_event.eventEndDate).tz('America/Manaus').format(),
      eventId: -1,
      eventLocation: location,
      eventTitle: _event.eventTitle,
      eventType: _event.eventType  ?? "Virtual",
      isVirtual: _event.eventType  === "Virtual",
      eventZipCode: 0,
      imageUrl:  "", 
      importDate: now,
      modified: now, 
      originalImageUrl:  "", 
      state: _event.state,
      tags: _event.tags,
      eventProfileId: _event.creatorId,
      docid:docid,
      eventTitleArray:[]
    };

    //validate model
    let validationResponse = validateEvent(event);
    if(!validationResponse.valid)
    {
        yield put({ type: ACTION.RESPONSE_CREATE_EVENT_ERROR, data: validationResponse });
        return;
    }

    
    if(dispatchObject.payload.eventImg){
        let imageUrl = yield call(ImageService.uploadImage, dispatchObject.payload.eventImg, `events/${docid}`);
        event.imageUrl = imageUrl;
        event.originalImageUrl = imageUrl;
    }
    
    let eventTitleArray =[];
    let eventTitle = _event.eventTitle;
    //from mobile app: creating eventTitleArray to search against due to firestore text search limitations. Another approach needs to be taken at a later date.
    let titleArray = eventTitle.toLowerCase().split(" ");
    titleArray.forEach((title:string) => {
      let subTitleArr = [];
      for (var i = 0; i < title.length; i++) {
        subTitleArr.push(title.charAt(i));
        if (i > 0) {
          eventTitleArray.push(subTitleArr.join(""));
        }
      }
    });
    for (var j = 0; j < eventTitle.length; j++) {
      if (j > 1) {
        eventTitleArray.push(eventTitle.substring(0, j + 1).toLowerCase());
      }
    }
    //adding city info to search by city
    if(event.city && event.city.length>0)  eventTitleArray.push(event.city.toLowerCase());
    event.eventTitleArray = eventTitleArray;
    let result = yield call(DAL.createEvent, event);
    if(result)
    {
        yield put({ type: ACTION.RESPONSE_EVENT_CREATED, data: result })
    }
}

/**
 * Validates input, uploads any images to firebase storage and adds to the user's post collection.
 * @param dispatchObject - expects a payload property that contains data necessary to make a CreatePostModel object.
 */
function* createPost(dispatchObject:any)
{
    let payload = dispatchObject.payload;
    // let prof:PostInitData = {
    //     name:payload.prof.name
    //     ,imageUrl:payload.prof.imageUrl
    // } 

    let post:CreatePostModel = {
         commentsCount:0
        ,content:payload.content
        ,images:[]
        ,likesCount:0
        ,uid:payload.uid
        ,timestamp:new Date() //mobile app uses firebase server time. Will be handled by the DAL.
        ,docid:uuid()
    };

    console.log('creating new post: post=>', post);
    let errorMessage = null;
    
    //validate
    if(post.content.length === 0)
    {
        errorMessage = errorMessage ? errorMessage + " " : "";
        errorMessage+="The post must have content.";  
    }
    if(!post.uid || post.uid.length === 0)
    {
        errorMessage = errorMessage ? errorMessage + " " : "";
        errorMessage+="User ID must be provided for the post.";
    }
    // if(!post.prof || !post.prof.name || !post.prof.imageUrl)
    // {
    //     errorMessage = errorMessage ? errorMessage + " " : "";
    //     errorMessage+="Post data must be provided.";
    // }
    
    if(errorMessage)
    {
        yield put({type:ACTION.RESPONSE_CREATE_POST_ERROR, data:errorMessage});
        return;
    }

    //upload images
    if(payload.uploads && payload.uploads.length > 0)
    {
        for(let i = 0; i < payload.uploads.length;i++)
        {
            let image = payload.uploads[i];
            let imageUrl = yield call(ImageService.uploadImage,image,`posts/${post.docid}`);
            post.images.push(imageUrl);
        }
    }
    //persist
    let result = yield call(DAL.createPost, post);
    if(result)
    {
        yield put({ type: ACTION.RESPONSE_CREATE_POST, data: result })
    }


}

function* getEventDetails(dispatchObject:any)
{
    try {
        let docid = dispatchObject.payload && dispatchObject.payload.docid;
        if(!docid)
        {
            return;
        }
        let result = yield call(DAL.fetchEvent, docid);
        if (result) {
           let normalized = Normalize.events([result]);
            yield put({ type: ACTION.RESPONSE_EVENT_DETAILS, data: normalized[0] })
        } else {
        }
    }
    catch (e) {
        yield put({ type: ACTION.RESPONSE_ERROR_EVENT_DETAILS, data: { error: e, message: "Failed to retrieve event details." } });
    }
}

function* handleRSVP(dispatchObject:any)
{
    try {
        const {userId, action, eventId} = dispatchObject.payload;
        let result = yield call(DAL.handleEventRSVP, eventId, userId, action);
        if(result)
        {
            yield put({type:ACTION.RESPONSE_HANDLE_RSVP, data:result});
        }
        else
        {
            //TODO notify of failure to rsvp
            console.error("Failed to RSVP");
        }
    }
    catch (e) {
        console.error(e);
    }

}

const EventsPostsService =
{
    fetchFeed:fetchFeed
    ,queryEvents:queryEvents
    ,createEvent:createEvent
    ,createPost:createPost
    ,getEventDetails:getEventDetails
    ,handleRSVP:handleRSVP
}

export default EventsPostsService;



