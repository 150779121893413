import * as QueryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Dropdown, DropdownButton, Form, FormControl, Modal, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTION from '../servicelayer/servicelayer-actions';
import "../styles/ProfileList.css";
import { flagPost } from '../util/util';
import DisplayPost from './DisplayPost';
import PostDetail from './PostDetail';
const PostList = (props) => {

    const dispatch = useDispatch();
    let query = QueryString.parse(props.location.search);

    const title = 'POSTS';
    const postResults = useSelector(state => state.posts ?? null);
    const last = useSelector(state => state.last ?? undefined);
    //post detail
    const [isPostDetail, setIsPostDetail] = useState(false);
    const [post, setCurrentPost] = useState(null);
    const [isShowReportDlg, setIsShowReportDlg] = useState(false);
    const [selectedReason, setSelectedReason] = useState('Other');   
      //selecting a reason
    const [reportReason, setReportReason] = useState('');
    const [posts, setPosts] = useState([]);
    const reasonData =
        [
            { value: 'Rude or Offensive' },
            { value: 'Out of Date' },
            { value: 'Incorrect' },
            { value: 'Against TurnUp’s Social Justice Values' },
            { value: 'Other' }
        ];

    const profile_id = query.uid;
    
    useEffect(() => {
        //loading follower.. 
        let filter = buildFilter();
        console.log('filter:', filter);
        dispatch({ type: ACTION.REQUEST_FEED, payload: filter });

    }, [dispatch]);

    useEffect(() => {
        console.log('postResults: ', postResults, ':last:', last);
        if(postResults){
            let _posts = [...posts, ...postResults];
            setPosts(_posts);
        }
    }, [postResults]);

    useEffect(() => {
        // console.log('query:', query);
        let p_id = query.uid;
        let type = query.type;
        console.log('profile id:', p_id,':type:', type);
    }, [dispatch, query]);
   
    const onPostFlag = (post) => {
        console.log('onPostFlag...');
        setCurrentPost(post);
        setIsShowReportDlg(true);
    }

    const fetchMorePosts = () => {
        console.log('fetchMorePosts.');
        let filter = buildFilter();
        dispatch({ type:ACTION.REQUEST_FEED, payload : filter});
    }


    //dialog related functions
    const onChangeReason = (e) => {
        setReportReason(e.target.value);
    }
    const closeModal = () => {
        setIsShowReportDlg(false);
    }

    const renderFlagDialog = () => {
        //console.log('rendering Flag dialog')
        return <Modal show={isShowReportDlg} onHide={closeModal}>
            <Modal.Header closeButton />
            <Modal.Body>
                <Container>
                    <Form.Row>
                        <Form.Group as={Col} controlId="score_edit">
                            <Form.Label>Flag this post</Form.Label>
                            {/* <ButtonGroup justified> */}
                            <DropdownButton
                                alignRight
                                title={selectedReason}
                                onSelect={onSelectReason}
                            >{
                                    reasonData.map((reason, idx) => {
                                        return <Dropdown.Item eventKey={idx}>{reason.value}</Dropdown.Item>
                                    })
                                }
                            </DropdownButton>
                            {/* </ButtonGroup> */}
                            <FormControl autoFocus
                                className={"reason-text"}
                                placeholder="Type a reason"
                                size="lg"
                                type="text"
                                value={reportReason}
                                onChange={onChangeReason} />
                        </Form.Group>
                    </Form.Row>
                    <Row>
                        <Col >
                            <div className={'dialog-button'}>
                                <Button onClick={onFlag}>Report</Button>
                            </div>
                        </Col>
                        <Col>
                            <div className={'dialog-button'}>
                                <Button onClick={onCancel}>Cancel</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    }
    const onPostUpdate = (changes) => {
        setIsPostDetail(false);
    }
    const onPostDetail = (post) => {
        setCurrentPost(post);
        setIsPostDetail(true);
    }
    const onCancel = () => {
        closeModal();
    }
    const onReport = () => {
        console.log('onReport...');
        setIsShowReportDlg(true);
    }
    const onSelectReason = (e) => {
        //console.log('onReason changed e:', e);
        setSelectedReason(reasonData[e].value);
    }
    const onFlag = () => {
        console.log('onFlag.. post:', post);
        let modalData = {};
        modalData.info = reportReason;
        modalData.reason = selectedReason;
        modalData.id = post.post_id;
        modalData.profileId = post.id;
        if (post.type === "event") {

            modalData.title = post.event.name;
            modalData.postedBy = post.event.eventProfileId;
            modalData.image = post.event.img;
            modalData.content = post.event.description;
            modalData.type = post.type;
            modalData.source = post.event.ref;
        }
        else {
            modalData.title = 'Post';
            modalData.postedBy = post.id;
            modalData.image = post.images.length > 0 ? post.images[0] : null;
            modalData.content = post.content;
            modalData.type = post.type
            modalData.source = post.ref;
        }

        flagPost(modalData, result => {
            console.log('onFlag result:', result);
            if(result) alert('The post flagged.')
        });

        setIsShowReportDlg(false);
    }
    const buildFilter = () => {
        console.log('building filter...');
        return { 
            uid : profile_id,
            view : 'mypost',
            after : last
        };
    }
    return (
        <div>
            {!isPostDetail ? <Container className={'profile-page'}>
                <Row>
                    <div className={'title'}>{title}</div>
                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col lg={9} xs={12}>
                        <InfiniteScroll initialLoad={false} loadMore={fetchMorePosts} hasMore={true}
                            loader={(<div className={'loading'}><ReactLoading type={'bubbles'} color={'#402366'} height={50} width={50} /></div>)}>
                            {
                                posts.map((post, i) => {
                                    return (<Row key={i}>
                                        <DisplayPost post={post} onPostDetail={(post) => onPostDetail(post)} onPostFlag={(post) => onPostFlag(post)} />
                                    </Row>)
                                }
                                )
                            }
                        </InfiniteScroll>
                    </Col>
                </Row>
            </Container>
                :
                <PostDetail post={post} onGoBack={(changes) => onPostUpdate(changes)} onReport={() => onReport()} />
            }
            {renderFlagDialog()}
        </div>)
}
export default PostList;