import * as firebase from "firebase/app";
// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';
import moment from 'moment';
import qs from 'qs';
import { useState } from 'react';
// import uuidv4 from 'uuid/v4';
import uuid from 'uuid-random';
import Config from '../data/Config';

const SESSION_KEY = "c1a1e421-3a79-4bdc-8e69-2927c0f5d70b";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASEKEY ?? "placeholder",
  projectId: process.env.REACT_APP_FIREBASEPROJECTID ?? "placeholder",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ?? "placeholder"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAC85WMVeObiCWaNYD8JvGOp3iSKx9EzpI",
//   authDomain: "rally-86ce1.firebaseapp.com",
//   databaseURL: "https://rally-86ce1.firebaseio.com",
//   projectId: "rally-86ce1",
//   storageBucket: "rally-86ce1.appspot.com",
//   messagingSenderId: "249887937671",
//   appId: "1:249887937671:web:5e1142063657f2744d5422"
// };
 console.log('firebaseConfig:', firebaseConfig);
export const MAPS_API_KEY = process.env.REACT_APP_MAPSAPIKEY ?? "placeholder"
firebase.initializeApp(firebaseConfig);

const firestoreDB = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const firestoreConst = firebase.firestore;

const localStorage = require('localStorage');


const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const getFieldValue = () => {
  return firebase.firestore.FieldValue;
}

const getServerTime = () => {
  return firebase.firestore.FieldValue.serverTimestamp();
}
const getFirebaseCaptchaVerifier = (element, properties) => {
  return new firebase.auth.RecaptchaVerifier(element, properties);
}

const getPhoneAuthProvider = () => {
  return new firebase.auth.PhoneAuthProvider();
}

const getPhoneAuthCredential = (verificationId, verificationCode) => {
  return firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
}

const getSessionData = () => {
  let session = localStorage.getItem(`turnup-${SESSION_KEY}`);
  if (session !== 'undefined') { return JSON.parse(session); }
  else {
    return null;
  }
}

const setSessionData = (sessionData) => {

  if (sessionData == null) {
    localStorage.removeItem(`turnup-${SESSION_KEY}`);
    return;
  }
  else {
    localStorage.setItem(`turnup-${SESSION_KEY}`, JSON.stringify(sessionData));
  }
}
const sendEmail = async (to, subject, body, options = {}) => {
  const { cc, bcc } = options;

  let url = `mailto:${to}`;

  // Create email link query
  const query = qs.stringify({
    subject: subject,
    body: body,
    cc: cc,
    bcc: bcc
  });

  if (query.length) {
    url += `?${query}`;
  }

  return window.open(url);
}
const updateNotificationSetting = (key, value, result) => {

  const uid = firebase.auth().currentUser.uid;
  console.log('uid:', uid);
  let promises = [];
  promises.push(firestoreDB.collection('profiles').doc(uid).update({
    [key]: value
  }));

  Promise.all(promises).then(results => {
    result(true);
  });

}
const useProfile = (profileId = null) => {
  // console.log('useProfile:');
  const [profile, setProfile] = useState(null);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const [didSubscribe, setDidSubscribe] = useState(false);

  if (!profileId && firebase.auth().currentUser) {
    profileId = firebase.auth().currentUser.uid;
  }
  //console.log('useProfile() profileId:', profileId);
  if (!didSubscribe && profileId) {
    setDidSubscribe(true);
    firestoreDB.collection('profiles').doc(profileId).onSnapshot(async snap => {

      if (snap.exists) {
        let p = snap.data();
        p.ref = snap.ref;
        p.id = snap.id;

        setProfile(p);
      } else {
        setProfile(undefined);
      }
      if (!gotInitialValue) {
        setGotInitialValue(true);
      }
    })
  }
  return [profile, gotInitialValue];
}
const getProfileById = async (profileId, completion) => {
  let profDoc = await firestoreDB.collection('profiles').doc(profileId).get();
  if (profDoc.exists) {
    let prof = profDoc.data();
    completion(prof);
  }
  else completion(null);
}

const updateInfluenceGoal = (score, result) => {

  let promises = [];
  const uid = auth.currentUser.uid;

  promises.push(firestoreDB.collection('profiles').doc(uid).get().then(snap => {
    if (snap.exists) {
      return firestoreDB.collection('profiles').doc(uid).set({ influenceGoal: score }, { merge: true });
    }
    else return null;
  }));

  Promise.all(promises).then(results => {
    result(results);
  });
}
const getPostCount = (profileId, result) => {
  firestoreDB.collection('profiles').doc(profileId)
    .collection('feed')
    .where('owner', '==', profileId)
    .get()
    .then(snap => {
      result(snap.docs.length);
    })
    .catch(err => {
      result(0);
    });

}
const getBadges = (completion) => {

  let badges = [];
  let promises = [];

  // const uid = auth.currentUser.uid;
  promises.push(firestoreDB.collection('badges')
    .orderBy("score")
    .get().then(async snap => {
      await asyncForEach(snap.docs, async doc => {
        //console.log('doc:',catId,':');
        let badge = doc.data();
        badge.id = doc.id;
        badges.push(badge);
      })
    }));

  Promise.all(promises).then(results => {
    completion(badges);
  })
    .catch(error => {
      completion(null);
    })
}

const likePost = (profileID, postID) => {
  // console.log('like_post profID:',profileID,':postID:', postID);
  let uid = auth.currentUser.uid;
  firestoreDB.collection('profiles').doc(profileID).collection('posts').doc(postID).collection('likes').doc(uid).set({
    uid: uid
  }, { merge: true });
}
const dislikePost = (profileID, postID) => {
  // console.log('dislike_post profID:',profileID,':postID:', postID);
  let uid = auth.currentUser.uid
  firestoreDB.collection('profiles').doc(profileID).collection('posts').doc(postID).collection('likes').doc(uid).delete();
}

//getting commentsinfo
const getComments = (profileID, postID, comments) => {
  // console.log('dislike_post profID:',profileID,':postID:', postID);
  // let uid = auth.currentUser.uid;
  firestoreDB.collection('profiles').doc(profileID).collection('posts').doc(postID).collection('comments').orderBy('timestamp', 'asc').onSnapshot(async snap => {
    let cs = []
    await asyncForEach(snap.docs, async doc => {
      let c = doc.data();
      c.ref = doc.ref;
      c.id = doc.id;
      let prof = null //await Storage.getProfile(c.uid);
      if (prof) {
        prof = JSON.parse(prof);
      } else {
        let profDoc = await firestoreDB.collection('profiles').doc(c.uid).get();
        if (profDoc.exists) prof = profDoc.data();
      }
      c.profile = prof;
      cs.push(c);
    })
    //console.log(cs)
    // setComments(cs)
    comments(cs);
  })
};
const postComment = (profileID, postID, comment, completion) => {

  let uid = auth.currentUser.uid;
  firestoreDB.collection('profiles').doc(profileID).collection('posts').doc(postID).collection('comments').doc().set({
    content: comment,
    timestamp: getServerTime(),
    uid: uid
  }, { merge: true }).then(doc => {
    completion(true)
  }).catch(err => {
    completion(false)
  })
};
const flagPost = async (modalData, result) => {
  let flag = {};
  flag.reason = modalData.reason;
  flag.info = modalData.info;
  flag.userId = firebase.auth().currentUser.uid;
  flag.title = modalData.title;
  flag.postedBy = modalData.postedBy;
  flag.image = modalData.image;
  flag.content = modalData.content;
  flag.type = modalData.type;
  flag.source = modalData.source;

  let profileDoc = await firestoreDB.collection('profiles').doc(flag.userId).get();
  let profile = profileDoc.data();

  flag.flaggedBy = profile.name ? profile.name : profile.username;

  profileDoc = await firestoreDB.collection('profiles').doc(flag.postedBy).get();
  profile = profileDoc.data();
  flag.postedBy = profile.name ? profile.name : profile.username;

  console.log('setFlagFeed() flag :', flag);
  firestoreDB.collection('flagged').doc(modalData.id).set({
    id: flag.source,
    type: flag.type,
    title: flag.title,
    postedBy: flag.postedBy,
    image: flag.image,
    content: flag.content,
    source: flag.source
  });
  firestoreDB.collection('flagged').doc(modalData.id).collection("flags").doc().set({ flag }, { merge: true }).then(_ => {
    result(true);
  }).catch(err => {
    result(false);
  })
}
const unfollowUser = async (profileID, completion) => {
  //console.log('Unfollow User.. ID:', profileID);
  let promises = [];
  const uid = firebase.auth().currentUser.uid;

  promises.push(firestoreDB.collection('profiles').doc(uid).collection('following').doc(profileID).delete())
  promises.push(firestoreDB.collection('profiles').doc(profileID).collection('followers').doc(uid).delete())
  promises.push(firestoreDB.collection('profiles').doc(profileID).collection('pendingFollowing').doc(uid).delete())

  Promise.all(promises).then(_ => {
    completion(true);
  }).catch(_ => {
    completion(false)
  })
};
const followUser = async (profileID, profileRef, completion) => {
  //check if user already blocked.
  //console.log('FollowUser.. ID:', profileID, ':', profileRef);


  const uid = firebase.auth().currentUser.uid;

  let userDoc = await firestoreDB.collection('profiles').doc(profileID).get();
  let userData = userDoc.data();
  //console.log('userData:', userData);

  if (userData.blocklist) {
    let blocklist = userData.blocklist;
    if (blocklist.indexOf(uid) !== -1) {

      completion(false);
      return;
    }

  }

  firestoreDB.collection('profiles').doc(uid).get().then(doc => {
    // console.log('profile get..')
    const userRef = doc.ref;

    let promises = [];
    promises.push(firestoreDB.collection('profiles').doc(uid).collection('following').doc(profileID).set({ ref: userDoc.ref }))
    promises.push(firestoreDB.collection('profiles').doc(profileID).collection('followers').doc(uid).set({ ref: userRef }))
    promises.push(firestoreDB.collection('profiles').doc(profileID).collection('followers').doc(uid).set({ ref: userRef }))


    Promise.all(promises).then(_ => {
      completion(true)
    }).catch(err => {
      console.log('followUser() promise error:', err);
      completion(false)
    })
  }).catch(err => {
    console.log('profile get() error:', err);
    completion(false)
  })
}

const followUserPending = async (profileID, completion) => {
  console.log('FollowingUserPending.. ID:', profileID);
  let promises = [];
  const uid = firebase.auth().currentUser.uid;
  let userDoc = await firestoreDB.collection('profiles').doc(profileID).get();
  let userData = userDoc.data();
  //console.log('userData:', userData);

  if (userData.blocklist) {
    let blocklist = userData.blocklist;
    if (blocklist.indexOf(uid) !== -1) {

      completion(false);
      return;
    }

  }

  firestoreDB.collection('profiles').doc(uid).get().then(doc => {

    const userRef = doc.ref;

    promises.push(firestoreDB.collection('profiles').doc(profileID).collection('pendingFollowing').doc(uid).set({ ref: userRef }))

    Promise.all(promises).then(_ => {
      completion(true)
    }).catch(_ => {
      completion(false)
    })
  }).catch(_ => {
    completion(false)
  })
};
const cancelFollowPending = async (profileID, completion) => {
  console.log('cancelFollowPending.. ID:', profileID);
  let promises = [];
  const uid = firebase.auth().currentUser.uid;
  let userDoc = await firestoreDB.collection('profiles').doc(profileID).get();
  let userData = userDoc.data();
  //console.log('userData:', userData);

  if (userData.blocklist) {
    let blocklist = userData.blocklist;
    if (blocklist.indexOf(uid) !== -1) {

      completion(false);
      return;
    }

  }
  promises.push(firestoreDB.collection('profiles').doc(profileID).collection('pendingFollowing').doc(uid).delete());

  Promise.all(promises).then(_ => {
    completion(true)
  }).catch(_ => {
    completion(false)
  });
}
const getOrganizations = async (profileID, completion) => {
  console.log('Getting organizations..');
  firestoreDB.collection('profiles')
    .where('isOrganization', '==', true)
    .where('followerArray', 'array-contains', profileID)
    .limit(5)
    .get()
    .then(snap => {
      let orgDocs = snap.docs;

      let orgs = [];
      orgDocs.forEach(orgDoc => {
        orgs.push(orgDoc.data());
      });

      completion(orgs);

    }).catch(_ => {
      completion(false);
    });
}
const getEventsForProfile = (profileId, completion) => {
  firestoreDB.collection('events')
    .where('eventDate', '>', moment(new Date()).tz('America/Manaus').format())
    .where("rsvps", 'array-contains', profileId)
    .orderBy('eventDate', "asc")
    .limit(Config.LIMIT_EVENT_ON_PROFILE).onSnapshot(snap => {
      let events = [];
      snap.docs.forEach(doc => {
        let event = { ...doc.data() }
        event.id = doc.id;
        event.ref = doc.ref;
        events.push(event)
      })
      completion(events);
    })
}

const getEventsForOrganization = (profileId, completion) => {
  firestoreDB.collection('events')
    .where('eventDate', '>', moment(new Date()).tz('America/Manaus').format())
    .where("rsvps", 'array-contains', profileId)
    .orderBy('eventDate', "asc")
    .limit(Config.LIMIT_EVENT_ON_PROFILE).onSnapshot(snap => {

      let events = [];
      snap.docs.forEach(doc => {
        //console.log('event doc',doc);
        let event = { ...doc.data() }
        event.id = doc.id;
        event.ref = doc.ref;
        if (doc.data().eventProfileId === profileId) {
          events.push(event)
        }
      })
      completion(events);

    })
}

const getPreviousEvents = (profileId, completion) => {
  firestoreDB.collection('events')
    .where('eventDate', '<', moment(new Date()).tz('America/Manaus').format())
    .where("rsvps", 'array-contains', profileId)
    .orderBy('eventDate', "desc")
    .limit(Config.LIMIT_EVENT_ON_PROFILE).onSnapshot(snap => {

      let events = [];
      snap.docs.forEach(doc => {
        //console.log('event doc',doc);
        let event = { ...doc.data() }
        event.id = doc.id;
        event.ref = doc.ref;
        if (doc.data().eventProfileId === profileId) {
          events.push(event)
        }
      })
      completion(events);
    })
}

const newConversation = async (content, profiles, userId = null, completion, isPost = false) => {
  //console.log('newConversation: content: ', content);
  console.log('newConversation profiles:', profiles[0]);
  console.log('newConversation userId:', userId);
  //console.log('messageProfile:', profiles);
  const uid = userId ? userId : auth.currentUser.uid;
  //console.log('messageProfile uid:', uid);
  let _selfDoc = await firestoreDB.collection('profiles').doc(uid).get();
  let _self = _selfDoc.data();
  _self.id = _selfDoc.id;

  let namesArray = [];
  let names = [];
  let avatars = [];

  //adding names array for all users as purpose of searching

  let _profiles = [...profiles, _self];
  _profiles.forEach(profile => {
    let name = profile.name;
    let username = profile.username;
    names.push(`${profile.id}_${name ? name : username}`);
    if (name) {

      let nameArray = name.toLowerCase().split(' ');
      nameArray.forEach((name, index) => {
        let subNameArr = []
        for (var i = 0; i < name.length; i++) {
          subNameArr.push(name.charAt(i));
          if (i > 0) {
            namesArray.push(subNameArr.join(''));
          }
        }
      });

      name = name.toLowerCase();

      for (var i = 0; i <= name.length; i++) {
        if (i > 0) {
          namesArray.push(name.substring(0, i));
        }
      }
    }

    if (username) {
      let nameArray = username.toLowerCase().split(' ');
      nameArray.forEach((username, index) => {
        let subNameArr = []
        for (var i = 0; i < username.length; i++) {
          subNameArr.push(username.charAt(i));
          if (i > 0) {
            namesArray.push(subNameArr.join(''));
          }
        }
      });

      username = username.toLowerCase();

      for (var j = 0; j <= username.length; j++) {
        if (j > 0) {
          namesArray.push(name.substring(0, j));
        }
      }
    }


    avatars.push(`${profile.id}_${profile.imageUrl ? profile.imageUrl : 'none'}`);

  });

  if (profiles.length > 1) {

    // Group Message
    const conversationID = uuid();
    let data = {
      admins: [
        userId,
      ],
      participants: [
        userId,
      ],
      isGroup: true,
    }
    profiles.forEach(p => {
      data.participants.push(p.id);
    })
    // console.log('newConversation() data:',data);
    firestoreDB.collection('conversations').doc(conversationID).set(data, { merge: true })
      .then(_ => {
        if ((isPost && content !== null && content !== undefined) || (!isPost && content.length > 0)) {
          firestoreDB.collection('conversations').doc(conversationID).collection('messages').doc().set({
            content: content,
            senderID: userId,
            timestamp: getServerTime(),
            type: 0,
            isPost: isPost
          })
        }
        firestoreDB.collection('conversations').doc(conversationID).get().then(doc => {
          let conv = doc.data();
          conv.id = doc.id;
          conv.ref = doc.ref;
          completion(conv);
        })
      })
  } else {
    // console.log('direct message..');
    // Direct Message
    const recipient = profiles[0];
    console.log('recipient profiles:', profiles[0]);
    // //checking if the recepient is moderator
    // let isModerator = false;
    // let profDoc = await firestoreDB.collection('profiles').doc(recipient).get();
    // if(profDoc){
    //     if(profDoc.data().isModerator) {
    //         console.log('new Conversation(). talking with a moderator.');
    //         isModerator = true;
    //     }
    // }
    console.log('useConversation() receipient:',recipient.id,':user_id:', userId);
    firestoreDB.collection('profiles').doc(userId).collection('conversations').where('participants', 'array-contains', recipient.id).get().then(snap => {
      let conversationID = uuid();
      // console.log('useConversation() snap length:',snap.docs.length);
      if (snap.docs.length > 0) {
        snap.docs.forEach((doc, i) => {
          if (doc.data().participants.length === 2) {
            conversationID = doc.id;
          }
        });
      }
      console.log('useConversation() conversationID:', conversationID);
      firestoreDB.collection('conversations').doc(conversationID).set({
        participants: [
          userId,
          profiles[0].id,
        ],
        isGroup: false,
        // namesArray : namesArray,
        // names : names,
        // avatars : avatars,
        // adminName : _self.name ? _self.name : _self.username,
        // adminAvatar : _self.imageUrl ? _self.imageUrl : null
        // isModeratorConv : isModerator
      }, { merge: true })
        .then(_ => {
          // console.log('useConversation() set');
          // console.log('new Conversation() content length:', content.length);
          console.log('newConversation: content=>', content);
          if ((isPost && content !== null && content !== undefined) || (!isPost && content.length > 0)) {
            // console.log('useConversation() length is greater than 0');
            firestoreDB.collection('conversations').doc(conversationID).collection('messages').doc().set({
              content: content,
              senderID: userId,
              timestamp: getServerTime(),
              type: 0,
              isPost: isPost
            })
              .catch(error => {
                console.log('error:', error);
              });
          }
          firestoreDB.collection('conversations').doc(conversationID).get().then(doc => {
            // console.log('useConversation() got conversation:',doc);
            let conv = doc.data();
            conv.id = doc.id;
            conv.ref = doc.ref;
            completion(conv);
          })
            .catch(error => {
              console.log('error:', error);
            });
        })
        .catch(error => {
          console.log('error:', error);
        });
    })
  }
};
const sendMessage = (content, conversationID, uid, completion) => {
  console.log('sending message: content:', content, ':conversationID:', conversationID, ':uid:', uid);
  firestoreDB.collection('conversations').doc(conversationID).collection('messages').doc().set({
    content: content,
    senderID: uid,
    timestamp: getServerTime(),
    type: 0,
  })
};
const acceptGroupRequest = (groupID, notificationID, requestID) => {
  let promises = [];
  let uid = auth.currentUser.uid;
  firestoreDB.collection('conversations').doc(groupID).set({
    requests: getFieldValue().arrayRemove(requestID),
    participants: getFieldValue().arrayUnion(requestID)
  }, { merge: true })
  promises.push(firestoreDB.collection('profiles').doc(uid).collection('notifications').doc(notificationID).set({ isProcessed: true, read: true }, { merge: true }))
};
const declineGroupRequest = (groupID, requestID) => {
  firestoreDB.collection('conversations').doc(groupID).set({
    requests: getFieldValue().arrayRemove(requestID),
  }, { merge: true })
};

const acceptFollowRequest = (followerID, followerRef, notificationID, completion) => {
  // console.log('followerID',followerID);
  let promises = [];
  const uid = auth.currentUser.uid;

  //remove this id from pending follows

  //follow this users
  firestoreDB.collection('profiles').doc(uid).get().then(doc => {

    const userRef = doc.ref;
    promises.push(firestoreDB.collection('profiles').doc(uid).collection('followers').doc(followerID).set({ ref: followerRef }))
    promises.push(firestoreDB.collection('profiles').doc(followerID).collection('following').doc(uid).set({ ref: userRef }))
    promises.push(firestoreDB.collection('profiles').doc(uid).collection('pendingFollowing').doc(followerID).delete())
    promises.push(firestoreDB.collection('profiles').doc(uid).collection('notifications').doc(notificationID).set({ isProcessed: true, read: true }, { merge: true }))

    Promise.all(promises).then(_ => {
      completion(true)
    }).catch(_ => {
      completion(false)
    })
  }).catch(_ => {
    completion(false)
  })
};

const declineFollowRequest = (followerID, notificationID, completion) => {

  let promises = [];
  const uid = auth.currentUser.uid;
  //remove this id from pending follows

  firestoreDB.collection('profiles').doc(uid).get().then(doc => {

    promises.push(firestoreDB.collection('profiles').doc(uid).collection('pendingFollowing').doc(followerID).delete())
    promises.push(firestoreDB.collection('profiles').doc(uid).collection('notifications').doc(notificationID).set({ isProcessed: true, read: true }, { merge: true }))
    Promise.all(promises).then(_ => {
      completion(true)
    }).catch(_ => {
      completion(false)
    })
  }).catch(_ => {
    completion(false)
  })
};
const acceptInvitationForEvent = (eventId, notificationID, completion) => {

  let promises = [];
  let uid = auth.currentUser.uid;
  console.log('eventId', eventId);

  promises.push(firestoreDB.collection('events').doc(eventId).set({
    rsvps: getFieldValue().arrayUnion(uid)
  }, { merge: true }));
  promises.push(firestoreDB.collection('profiles').doc(uid).collection('notifications').doc(notificationID).set({ isProcessed: true, read: true }, { merge: true }))
  promises.push(firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToEvent').where('eventId', '==', eventId).get().then(snaps => {
    snaps.docs.forEach(doc => {
      console.log('doc_id :', doc.id);
      firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToEvent').doc(doc.id).delete();
    });
  }));

  Promise.all(promises).then(_ => {
    completion(true)
  }).catch(_ => {
    completion(false)
  })
};
const rejectInvitationForEvent = (eventId, notificationID, completion) => {
  let promises = [];
  let uid = auth.currentUser.uid;

  promises.push(firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToEvent').where('eventId', '==', eventId).get().then(snaps => {
    promises.push(firestoreDB.collection('profiles').doc(uid).collection('notifications').doc(notificationID).set({ isProcessed: true, read: true }, { merge: true }))
    snaps.docs.forEach(doc => {
      console.log('doc_id :', doc.id);
      firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToEvent').doc(doc.id).delete();
    });
  }));

  Promise.all(promises).then(_ => {
    completion(true)
  }).catch(_ => {
    completion(false)
  })
};
const acceptInvitationForGroup = (groupID, notificationID, completion) => {

  let promises = [];
  let uid = auth.currentUser.uid;

  firestoreDB.collection('conversations').doc(groupID).set({
    participants: getFieldValue().arrayUnion(uid)
  }, { merge: true })

  promises.push(firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToGroupChat').where('groupId', '==', groupID).get().then(snaps => {
    snaps.docs.forEach(doc => {
      console.log('doc_id :', doc.id);
      firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToGroupChat').doc(doc.id).delete();
    });
  }));
  promises.push(firestoreDB.collection('profiles').doc(uid).collection('notifications').doc(notificationID).set({ isProcessed: true, read: true }, { merge: true }));
  Promise.all(promises).then(_ => {
    completion(true)
  }).catch(_ => {
    completion(false)
  })

};
const rejectInvitationForGroup = (groupID, notificationID, completion) => {

  let promises = [];
  let uid = auth.currentUser.uid;

  promises.push(firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToGroupChat').where('groupId', '==', groupID).get().then(snaps => {
    snaps.docs.forEach(doc => {
      console.log('doc_id :', doc.id);
      firestoreDB.collection('profiles').doc(uid).collection('pendingInvitationToGroupChat').doc(doc.id).delete();
    });
  }));
  promises.push(firestoreDB.collection('profiles').doc(uid).collection('notifications').doc(notificationID).set({ isProcessed: true, read: true }, { merge: true }))
  Promise.all(promises).then(_ => {
    completion(true)
  }).catch(_ => {
    completion(false)
  })

};
const useConversations = (uid) => {
  // console.log('useconversations: uid :', uid);
  const [conversations, setConversations] = useState([]);
  const [gotInitialValue, setGotInitialValue] = useState(false);
  const [didSubscribe, setDidSubscribe] = useState(false);

  // const uid = auth.currentUser.uid;
  //console.log('useConversations():');
  // let startTime = new Date().getTime();
  // let startTimeforTotal = new Date().getTime();

  let isModerator = false;

  if (!didSubscribe && uid) {
    setDidSubscribe(true);
    firestoreDB.collection('profiles').doc(uid).get().then(async data => {
      //console.log('data:', data);limit(config.LIMIT_CONVERSATION).
      let prof = data.data();
      if (prof.isMainModerator) isModerator = true;
      let query = firestoreDB.collection('profiles').doc(uid).collection('conversations');
      query = query.orderBy('virtualLastTimestamp', 'desc').limit(Config.LIMIT_CONVERSATION);

      // console.log('applying queeries');
      query.onSnapshot(async data => {
        // console.log('elapsed time to get conversation Docs..', new Date().getTime() - startTime);
        //console.log('docs:', data.docs);
        // startTime = new Date().getTime();
        let convs = [];
        await asyncForEach(data.docs, async doc => {
          let conv = doc.data();
          conv.id = doc.id;
          conv.ref = doc.ref;

          if (conv.isHidden) {

          } else {
            // console.log('conv is hidden:', conv.isHidden);
            //get time difference
            let duration = moment.duration(moment(conv.virtualLastTimestamp.toDate()).diff(moment(new Date())));
            if (duration.asYears() > 50) conv.starred = true;

            let blocked = false;
            if ((!conv.isModeratorConv || isModerator) && !blocked) {
              let names = [];
              // let idx = 0;
              conv.isVerified = true;
              //console.log('useConversations(): participants length:', conv.participants,':conv_id:',conv.id);
              let _participants = [];
              if (conv.namesArray) {
                //console.log('namesArray Exist:');

                conv.names.forEach(name => {
                  //console.log('useConversation() name:',name);
                  let _name = name.split('_')[1];
                  let _id = name.split('_')[0];
                  conv.participants.forEach(id => {
                    //console.log('useConversation() id:',id);
                    let p = {};
                    if (id === _id) {
                      p.id = _id;
                      p.name = _name;
                      _participants.push(p);
                    }

                  });


                  //console.log('name :',_name);
                  names.push({ 'names': _name });
                });
                conv.avatars.forEach(avatar => {
                  let id = avatar.split('_')[0];
                  let url = avatar.split('_')[1];
                  if (id !== uid) {
                    if (url === 'none') conv.imageUrl = null;
                    else conv.imageUrl = url;
                  }

                });
                conv.participants = _participants;
                conv.names = names;

                if (conv.participants.length !== conv.names.length) {
                  conv.isVerified = false;
                }

              }
              else {

                await asyncForEach(conv.participants, async (p, i) => {
                  //console.log('useConversation() participants:', p);
                  let profDoc = null;
                  try {
                    profDoc = await firestoreDB.collection('profiles').doc(p).get();
                  }
                  catch (error) {
                    console.log('error:', error)
                  };
                  //console.log('profDoc:', profDoc);
                  if (profDoc !== null && profDoc.exists) {
                    //console.log('useConversations(): profile exists: p:',p);
                    let prof = profDoc.data();
                    prof.id = profDoc.id;
                    prof.ref = profDoc.ref;
                    _participants.push(prof);

                    names.push({ 'names': prof.name ? prof.name + ',' + prof.username : prof.username });
                    // console.log('useConversation() names:', names);
                  }
                  else {
                    //  console.log('useConversations(): profile does not exists: p:',p,' convID:', conv.id);
                    conv.isVerified = false;
                  }
                })

                // console.log('elapsed time to get participants info..', new Date().getTime() - startTime);
                //console.log('useConversation() participants are added. verified:', conv.isVerified);
                conv.participants = _participants;
                if (conv.imageUrl === undefined) {
                  // console.log('useConversation() imageUrl is undefined');
                  let otherprof = _participants.find(p => p.id !== uid);
                  if (otherprof) conv.imageUrl = otherprof.imageUrl;
                }

                conv.names = JSON.stringify(names);
              }


              if (conv.isVerified) {
                convs.push(conv);
              }
            }
          }
        })
        // console.log('convs:', convs);
        //console.log('elapsed time to after processing the conv..', new Date().getTime() - startTimeforTotal);
        setConversations(convs);
        if (!gotInitialValue) {
          setGotInitialValue(true);
        }
      })
    })

  }
  return [conversations, gotInitialValue];
};
const inviteUserToEvent = (eventId, eventTitle, userId, profileId, completion) => {
  console.log('inviteUserToEvent.. eventId:', eventId, ':userId:', userId, ':eventTitle:', eventTitle, ':profileId:', profileId);
  let promises = [];
  promises.push(firestoreDB.collection('profiles').doc(profileId).collection('pendingInvitationToEvent').doc().set({
    eventId: eventId,
    eventName: eventTitle,
    eventUserId: userId
  }, { merge: true }));

  Promise.all(promises).then(_ => {
    completion(true);
  }).catch(_ => {
    completion(false);
  });

};
const getVotingInfoByState = (state, completion) => {
  console.log('getVotingInfoByState:', state);
  firestoreDB.collection('votingInfo').doc(state).get().then(async snap => {
    if (snap.exists) {
      let data = snap.data();
      completion(data);
    }
    else completion(null);
  })
    .catch(error => {
      console.log('error: ', error);
      completion(null);
    });
};
const getRegisterationInfoByState = (state, completion) => {
  console.log('getVotingInfoByState state:', state);

  firestoreDB.collection('registerationInfo').doc(state).get().then(async snap => {
    if (snap.exists) {
      let data = snap.data();
      completion(data);
    }
    else completion(null);
  })
    .catch(error => {
      console.log('error: ', error);
      completion(null);
    })
};

const loadElections = (state, type, completion) => {
  console.log('loadElections state:', state, ':type:', type);

  let query = firestoreDB.collection('electionsInfo');
  query = query.where('state', '==', state.toLowerCase());

  if (type !== 'All') {
    query = query.where('type', '==', type);
  }
  query = query.orderBy("date");

  query.get().then(async snap => {
    let elections = [];
    snap.docs.forEach(doc => {
      let election = doc.data();
      election.id = doc.id;
      elections.push(election);
    });
    completion(elections);
  })
  .catch(error => {
    console.log('loadElections error:', error);
    completion(false);
  });
};
// const session = { get: getSession, set: setSession };
//liking post
export {
  firestoreDB, firestoreConst, auth, storage, getServerTime, getFirebaseCaptchaVerifier,
  asyncForEach,
  getPhoneAuthProvider, getPhoneAuthCredential, getFieldValue,
  updateNotificationSetting,
  sendEmail,
  useProfile,
  getProfileById,
  updateInfluenceGoal,
  getSessionData,
  setSessionData,
  getPostCount,
  getBadges,
  firebase,
  likePost,
  dislikePost,
  getComments,
  postComment,
  flagPost,
  followUser,
  cancelFollowPending,
  followUserPending,
  unfollowUser,
  getOrganizations,
  acceptGroupRequest,
  declineGroupRequest,
  acceptFollowRequest,
  declineFollowRequest,
  acceptInvitationForEvent,
  rejectInvitationForEvent,
  acceptInvitationForGroup,
  rejectInvitationForGroup,
  useConversations,
  newConversation,
  sendMessage,
  getEventsForProfile,
  getEventsForOrganization,
  getPreviousEvents,
  inviteUserToEvent,
  getVotingInfoByState,
  getRegisterationInfoByState,
  loadElections
};
