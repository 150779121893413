import * as QueryString from 'query-string';
import React, { useEffect, useState } from 'react';
import AddToCalendar from 'react-add-to-calendar';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BsCalendar, BsFillTagFill } from 'react-icons/bs';
import { FiCamera } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTION from '../servicelayer/servicelayer-actions';
import '../styles/EventDetails.css';
import { MAPS_API_KEY } from '../util/util';
import InviteFollower from './InviteFollower';
import PeopleComponent from "./PeopleComponent";
const moment = require('moment-timezone');

const EventDetails = (props) => {

  const event = useSelector(state => state.eventDetails ?? {});
  const eventProfile = useSelector(state => state.fetchProfileResult);
  const loggedInUser = useSelector(state => state.user);

  const dispatch = useDispatch();
  let query = QueryString.parse(props.location.search);

  const [isRSVPd, setRSVP] = useState(false);
  //moving to invitation of followers
  const [page_follower, setPageFollower] = useState(false);
  //calendar invitation
  const [calendar, setCalendar] = useState({
    title : 'Loading..',
    description : 'descirption loading...',
    location : 'Portland, OR',
    startTime : '2010-00-00T20:00:00',
    endTime : '2010-00-00T20:00:00',
  });
  //initial fetch of event data
  useEffect(()=> {
    dispatch({type:ACTION.REQUEST_EVENT_DETAILS, payload:{docid:query.event}});
  },
  [dispatch, query.event]);


  useEffect(()=> {
    dispatch({type:ACTION.REQUEST_FETCH_PROFILE, payload:{docId:event.eventProfileId}});
  },
  [dispatch, event.eventProfileId]);

  useEffect(()=> {
    // console.log('event:',event);
    if(event && loggedInUser){
      let currentUserId = loggedInUser && loggedInUser.uid;
      let b_rsvpd = event && (event.rsvps || []).indexOf(currentUserId) > -1;
      setRSVP(b_rsvpd);
      console.log('event loggedinUser:',event);
      if(event.name){
        let _event = {
          title : event.name,
          description : event.description,
          location : `${event.city},${event.state}`,
          startTime : moment(event.eventDate).format('dddd, MMM D, YYYY'),
          endTime : moment(event.eventEndDate).format('dddd, MMM D, YYYY')
        };
  
        setCalendar(_event);
      }
    }
  },
  [event, loggedInUser]);
  //handing RSVPS
  const handleRSVP = () => {
    let currentUserId = loggedInUser && loggedInUser.uid;
    //if user is not logged in, direct to sign up
    if (!currentUserId) {
      return;
    }
    console.log('handleRSVP:', currentUserId);
    dispatch({
      type: ACTION.REQUEST_HANDLE_RSVP, payload: {
        eventId: event.id,
        userId: currentUserId,
        action: isRSVPd ? "remove" : "add"
      }
    });
    setRSVP(!isRSVPd);
  }

  const onInviteFollower = () => {
    console.log('onInviteFollower...');
    setPageFollower(true);
  }

  const onGoBack = () => {
    console.log('onGoBack()...');
    setPageFollower(false);
  }
  // format map query
  const map_api = `https://www.google.com/maps/embed/v1/place?key=${MAPS_API_KEY}`;
  const formatted_location = `${event.city},${event.state}`;
  const map_query = map_api + "&q=" + formatted_location;
  console.log('map-query:', map_query);
  // format list of tags
  const renderedTags = event.tags &&  event.tags.map((tag, i) =>  <p className="tag" key={i}><BsFillTagFill/>{tag}</p>);


  return (
    !page_follower ? 
    <Container className="event-details">
      <Row xs={1} lg={2}>
        <Col className="image"><img src={event.img} alt={event.name}/></Col>
        <Col className="map d-none d-lg-block">
          <iframe
            title="map"
            frameBorder="0"
            src={map_query} allowFullScreen>
          </iframe>
        </Col>
      </Row>
      <Row xs={1} lg={2}>
        <Col className="main-details">
          <Row xs={1}>
            <Col className="name-section">
              <h4>{event.name}</h4>
            </Col>
            <Col>
              <span>{ event && `${(event.rsvps || []).length} ${(event.rsvps || []).length === 1? 'Person' : 'People'} going`}</span>
            </Col>
            <Col className="profile-section">
            {eventProfile && <PeopleComponent profile={eventProfile} />}
              <span>Posted an event</span>
            </Col>
            <Col className="about-header"><p>About</p></Col>
            <Col><p>{event.description}</p></Col>
          </Row>
        </Col>
        <Col className="extra-details">
          <Row xs={1}>
            <Col className="details">
              <Row xs={1} sm={2}>
                <Col>
                  <Row>
                    <Col className="icon" xs={1}>
                      <BsCalendar/>
                    </Col>
                    <Col className="detail" xs={11}>
                      <p className="title">{event ? moment(event.eventDate).format('dddd, MMM D, YYYY') : 'Loading'}</p>
                      <p className="time">{event ? `${moment(event.eventDate).format('h:mm A')} - ${moment(event.eventEndDate).format('h:mm A')}` : 'Loading'}</p>
                      <AddToCalendar event={calendar}>
                        <p className="add-to-calender">Add to Calendar</p>
                      </AddToCalendar>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="icon" xs={1}>
                      <FiCamera/>
                    </Col>
                    <Col className="detail" xs={11}>
                      <p className="title">Virtual/Zoom</p>
                      {event.link ? <p><a href={event.link}>{event.link}</a></p> : <p>No link provided</p>}
                      <p className="title">Organizer's Location</p>
                      {/* <p>{event.location}</p> */}
                    </Col>
                  </Row>
                </Col>
                <Col>{renderedTags}</Col>
              </Row>
            </Col>
            <Col>
              <Row xs={1} sm={2} className="buttons">
                <Col style={{textAlign : 'center'}}><Button className={'btn-invite-follower'} onClick={onInviteFollower}>Invite followers</Button></Col>
                <Col style={{textAlign : 'center'}} ><Button className={'btn-rsvp'} onClick={handleRSVP}>{isRSVPd ? "Remove RSVP" : "RSVP"}</Button></Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="map d-block d-lg-none">
          <iframe
            title="map"
            frameBorder="0"
            src={map_query} allowFullScreen>
          </iframe>
        </Col>
      </Row>
    </Container>
    :
    <InviteFollower onGoBack = { () => onGoBack()} event={event}/>
  );
};

export default EventDetails;
