import React, { useState, useEffect} from 'react';
import {  Button, Container, FormControl, Form, Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import * as ACTION from '../servicelayer/servicelayer-actions'

const SignUpComponent = ({onSuccesCallback}) => 
{
    const newUser = useSelector(state => state.newUser);
    const requestResponse = useSelector(state => state.signupError);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validationErr, setValidationErr] = useState('');
    const dispatch = useDispatch();
    const handleSubmit = (e)=>
    {

        e.preventDefault();
        if(password !== confirmPassword)
        {
            setValidationErr("Password and password confirmation must match.");
            return;
        }
        let payload = {email:email,password};
        let _dispatchObj = {type:ACTION.REQUEST_SIGNUP, payload:payload}
        dispatch(_dispatchObj);
    }

    useEffect(()=> {
        console.log('creatAccount=newUser:', newUser);
        if(newUser) //indicates a new user was created
        {
            onSuccesCallback(newUser); //a callback, likely navigating to a different component
        }
    });
    

    const showRequestResponse = () => 
    {
        let message = (requestResponse && requestResponse.message) || validationErr;
        if (message)
        {
            return (<Form.Row><Alert variant="danger">{message}</Alert></Form.Row>)
        }
    }


    return (<Container >
        <Form onSubmit={handleSubmit}>
            {showRequestResponse()}
            <Form.Row>
            <Form.Group>
                <Form.Label className="form-label">Email</Form.Label>
                <FormControl className="form-input" type="email" placeholder="email@address.com" value={email} onChange={(e)=> setEmail(e.target.value)} />
            </Form.Group>
            </Form.Row>
           <Form.Row>
           <Form.Group>
                <Form.Label className="form-label">Password</Form.Label>
                <FormControl  className="form-input" type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} ></FormControl>
            </Form.Group>
           </Form.Row>
           <Form.Row>
           <Form.Group>
                <Form.Label className="form-label">Confirm Password</Form.Label>
                <FormControl className="form-input" type="password" placeholder="Confirm password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} ></FormControl>
            </Form.Group>
           </Form.Row>
        <Button className={'button-submit'} type="submit">Sign Up</Button>
        </Form>
    </Container>)
}


export default SignUpComponent