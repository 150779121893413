/**stylesheets */
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
/** React-redux Components */
import { Provider } from 'react-redux';
import Main from "./Main";
//Store
import configureStore from "./servicelayer/servicelayer-store";
import './styles/app.css';
import './styles/TurnUp.css';

const App = () => {

  const store = configureStore();

  return (
    <Provider store={store}>
      <Main></Main>
    </Provider>
  )
};

export default App;
