import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { REQUEST_SESSION_RESTORE } from '../src/servicelayer/servicelayer-actions';
import { getSessionData } from '../src/util/util';
import BadgesPage from "./components/BadgesPage";
import BootstrapFormikExample from "./components/BootstrapFormikExample";
import ConnectPage from "./components/ConnectPage";
import CreateAccount from "./components/CreateAccount";
import CreateEvent from "./components/CreateEvent";
import EditProfile from "./components/EditProfile";
import CreatePost from "./components/CreatePost";
import EventDetails from "./components/EventDetails";
import EventsPage from "./components/EventsPage";
import HomePage from "./components/HomePage";
import AdilPage from "./components/interns/AdilPage";
import ReactTask2 from "./components/interns/ReactTask2";
import soniaPage from "./components/interns/soniaPage";
import LandingPage from "./components/LandingPage";
import MessageScreen from "./components/MessageScreen";
import NavBarComponent from "./components/NavBarComponent";
import NotificationsPage from "./components/NotificationsPage";
import PageNotFound from "./components/PageNotFound";
import PhoneConfirm from "./components/PhoneConfirm";
/**React Components */
// import Container from "react-bootstrap/Container";
/* Custom Components*/
import Playground from "./components/Playground";
import PostList from "./components/PostList";
import PrivateRoute from "./components/PrivateRoute";
import ProfileDetail from "./components/ProfileDetail";
import ProfileList from "./components/ProfileList";
import ResourcePage from "./components/ResourcePage";
import SettingsPage from "./components/SettingsPage";
import SignUpForm from "./components/TestingForm";
import UserProfile from "./components/UserProfile";
import VoteInfo from "./components/VoteInfo";
import AppDownloadPage from "./components/AppDownloadPage";
import { isMobile } from 'react-device-detect';

const Main = () => {
  const user = useSelector(state => state.user);
  const newUser = useSelector(state => state.newUser);
  const loggedIn = user !== undefined && user !== null;
  const isRegistering = newUser !== undefined;

  const dispatch = useDispatch();
  const [showWeb, setShowWeb] = useState(false);

  const setNavBar = () => {
    if (loggedIn) {
      console.log('user:', user);
      return (
          <NavBarComponent img={user.img} loggedIn={true} user={user} />
      )
    }
    else if (isRegistering) {
      return (<NavBarComponent loggedIn={false} />)
    }
    else {
      return (<></>)
    }
  }
  useEffect(() => {
    // console.log('getting session:', ':isLoggedin:', loggedIn, ':user:', user);
    if (!loggedIn) {
      let session = getSessionData();
      if (session !== 'null') {
        // console.log('dispatching Main... session:', session);
        dispatch({ type: REQUEST_SESSION_RESTORE, payload: session });
      }
    }
  }, [dispatch, user, loggedIn]);

  // console.log('web:', window.$web)
  return (isMobile && !showWeb) ? (<AppDownloadPage onAllowWeb={() => {console.log('onAllowWeb'); setShowWeb(true); window.$web = false;}}/>) :
   (<BrowserRouter>
    {setNavBar()}
    {/* <Container className="my-4"> */}
    <Switch>

      <Route path='/' exact component={LandingPage} />
      <Route path='/Login' component={LandingPage} />
      <Route path='/playground' exact component={Playground} />
      <Route path='/interns/reacttask2' component={ReactTask2} />
      <Route path="/interns/soniaPage" component={soniaPage} />
      <Route path="/interns/AdilPage" component={AdilPage} />
      <Route path="/CreateAccount" component={CreateAccount} />
      <Route path="/TestingForm" component={SignUpForm} />
      <Route path="/ReactFormikTest" component={BootstrapFormikExample} />
      <Route path="/ResourcePage" component={ResourcePage} />
      <Route path="/VoteInfo" component={VoteInfo} />
      <Route path="/PhoneConfirm" component={PhoneConfirm} />
      <PrivateRoute path="/EventsPage" component={EventsPage} />
      <PrivateRoute path="/ConnectPage" component={ConnectPage} />
      <PrivateRoute path="/badge" component={BadgesPage} />
      <PrivateRoute path="/HomePage" component={HomePage} />
      <PrivateRoute path="/EventDetails" component={EventDetails} />
      <PrivateRoute path="/CreateEvent" component={CreateEvent} />
      <PrivateRoute path="/EditProfile" component={EditProfile} />
      <PrivateRoute path="/CreatePost" component={CreatePost} />
      <PrivateRoute path="/SettingsPage" component={SettingsPage} />
      <PrivateRoute path="/UserProfile" component={UserProfile} />
      <PrivateRoute path="/EditProfile" component={EditProfile} />
      <PrivateRoute path="/ProfileDetail" component={ProfileDetail} />
      <PrivateRoute path="/ProfileList" component={ProfileList} />
      <PrivateRoute path="/PostList" component={PostList} />
      <PrivateRoute path="/Message" component={MessageScreen} />
      <PrivateRoute path="/Notifications" component={NotificationsPage} />
      <Route component={PageNotFound} />

    </Switch>
    {/* </Container> */}
  </BrowserRouter>)

}

export default Main;