import * as QueryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Image, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { BsFillChatSquareFill } from 'react-icons/bs';
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import placeholder from '../resources/placeholder.png';
import * as ACTION from '../servicelayer/servicelayer-actions';
import "../styles/UserProfile.css";
import { followUser, followUserPending, getBadges, getEventsForOrganization, getEventsForProfile, getOrganizations, getPostCount, getPreviousEvents, unfollowUser } from '../util/util';
import Icon from './Icon';
import ProfileListItem from './ProfileListItem';

const ProfileDetail = (props) => {
  //dummy profile data
  const loggedInUser = useSelector(state => state.user);
  //const [profile, setProfile] = useProfile(loggedInUser ? loggedInUser.uid : null);
  const [postCount, setPostCount] = useState(0);
  const [influencePercent, setInfluentPercent] = useState(0);
  // const [score, setScore] = useState(0);
  // const [badges, setBadges] = useState([]);
  const [badgeImage, setBadgeImage] = useState('');
  const [badgeText, setBadgeText] = useState('');


  let query = QueryString.parse(props.location.search);
  const profileId = query.id;

  const history = useHistory();
  const dispatch = useDispatch();

  const [status, setStatus] = useState('Follow');
  const [organizations, setOrganizations] = useState([]);

  //events list
  const [events, setEvents] = useState([]);
  const [pEvents, setPEvents] = useState([]);

  //querying...
  const profile = useSelector(state => state.fetchProfileResult);

  useEffect(() => {
    console.log('query ID:', query.id);
    dispatch({ type: ACTION.REQUEST_FETCH_PROFILE, payload: { docId: query.id, isFullInfo: true } });
  },
    [dispatch, query.id]);

  useEffect(_ => {
    if (profile && loggedInUser) {
      // console.log('profile is retrieved. profile:', profile);
      updatePostCount();
      //update influent percent
      let percent = Math.floor((profile.influenceScore / profile.influenceGoal) * 100) > 100 ? 100 : Math.floor((profile.influenceScore / profile.influenceGoal) * 100);
      // console.log('percent:', percent, ':score:', profile.influenceScore)
      setInfluentPercent(percent);
      // setScore(profile.influenceGoal);

      //Getting badges
      getBadges(_badges => {
        // setBadges(_badges);

        //profile was set
        // console.log('profile =>', profile);
        var breakException = {};
        let tempBadge = (_badges || []).length > 0 ? _badges[0].image : 'BronzeBadge';
        let textBadge = (_badges || []).length > 0 ? _badges[0].name : 'BronzeBadge';

        // console.log('getBadges:', _badges);
        try {

          _badges.forEach(badge => {
            if (parseInt(badge.goal) > profile.influenceScore)
              throw breakException;
            tempBadge = badge.image;
            textBadge = badge.name;
          });

        } catch (e) {

        }
        // console.log('temp:', tempBadge, ':text:', textBadge);
        setBadgeImage(tempBadge === 'BronzeBadge' ? null : tempBadge);
        setBadgeText(textBadge);

        let uid = loggedInUser.uid;
        if (profile.followerArray) {
          if (profile.followerArray.indexOf(uid) !== -1) profile.following = true;
          else profile.following = false;
        }
        else profile.following = false;

        if (!profile.following) {
          //checking if following is allowed
          if (typeof profile.allowFollowRequest !== 'undefined') profile.allowedFollowing = !profile.allowFollowRequest;
          else profile.allowedFollowing = true;

          if (profile.pendingFollowingArray) {
            if (profile.pendingFollowingArray.indexOf(uid) !== -1) profile.pendingFollowing = true;
            else profile.pendingFollowing = false;
          }
          else profile.pendingFollowing = false;
        }
        setStatus(getStatusText(profile));

      });

      //Getting organizations
      getOrganizations(profile.id, orgs => {
        if (orgs) {
          setOrganizations(orgs);
        }
      });

      //getting events
      if (profile.isOrganization) {
        getEventsForOrganization(profile.id, es => {
          setEvents(es);
        })
      }
      else{
        getEventsForProfile(profile.id, es => {
          setEvents(es);
        })
      }

      //getting past events
      getPreviousEvents(profile.id, es => {
        setPEvents(es);
      })
    }
  }, [profile, loggedInUser]);

  const getStatusText = profile => {
    if (profile.following) return "Unfollow";
    if (profile.allowedFollowing) {

      if (!profile.following) {
        if (profile.following !== null) return "Follow";
        else return "Loading...";
      }
      else return "Unfollow";
    }
    else {
      if (profile.pendingFollowing) return "Requested";
      else return "Follow";
    }
  }

  const updatePostCount = _ => {
    //console.log('updating post count...');
    getPostCount(profileId ? profileId : profile.id, count => {
      setPostCount(count)
    });
  }

  const statDisplay = (label, num) => {
    return (
      <Row xs={1}>
        <Col className={'label-profile'}>{label}</Col>
        <Col>{num}</Col>
      </Row>
    );
  }

  const renderTooltip = (props) => {
    let type;
    if (props.popper.state) {
      type = props.popper.state.options.type;
    }
    let username;
    switch (type) {
      case "fb":
        username = profile ? profile.facebook : '';
        break;
      case "ig":
        username = profile ? profile.instagram : '';
        break;
      case "sc":
        username = profile ? profile.snapchat : '';
        break;
      default:
        username = "No username found";
    }
    return (
      <Tooltip {...props}>{username}</Tooltip>
    );
  };
 
  const onSeeAllBadges = () => {
    history.push('/badge');
  }

  const onClickFollow = () => {
    console.log('onClickFlow()....');
    if (profile.following) {
      //props.navigation.navigate('LoadingOverlaay')
      unfollowUser(profile.id, success => {
        if (success) {
          profile.following = false;
          setStatus(getStatusText(profile));
        }
      });
      return;

    }
    if (profile.allowedFollowing) {
      if (profile.following) {
        unfollowUser(profile.id, success => {

          if (success) {
            profile.following = false;
            setStatus(getStatusText(profile));
          }

        });
        return;
      } else {
        if (profile.following !== null) {
          followUser(profile.id, profile.ref, success => {

            if (success) {
              profile.following = true;
              setStatus(getStatusText(profile));
            }

          });
          return;
        }
      }
    }
    else {
      if (!profile.pendingFollowing) {
        followUserPending(profile.id, success => {
          if (success) {
            profile.pendingFollowing = true;
            setStatus(getStatusText(profile));
          }

        });
        return;
      }
    }
    // dispatch({type : ACTION.REQUEST_USER_FOLLOW, payload : {profile_id : profile.id, status}});
  }
  const onMessage = () => {
    //console.log('onMessage.. link=>', `/Message?uid=${profile.id}`);
    window.open(`/Message?mid=${profile.id}&uid=${loggedInUser.uid}`, '_self');
  }
  const onClickFollowers = () => {
    // console.log('onClickFollowers');
    window.open(`/ProfileList?type=follower&uid=${profile.id}`, '_self');
  }
  const onClickFollowings = () => {
    // console.log('onClickFollowings');
    window.open(`/ProfileList?type=following&uid=${profile.id}`, '_self');
  }

  const onClickPosts = () => {
    console.log('onClickPosts...');
    window.open(`/PostList?type=mypost&uid=${profile.id}`, '_self');
  }

  const onSeeAll = () => {
    console.log('onSeeAll..');
    window.open(`/ProfileList?type=organization&uid=${profile.id}`, '_self');
  }
  const onSeeAllEvents = () => {

  }

  const onSeeAllPastEvents = () => {

  }

  const onProfileDetail = (profile) => {
    window.open(`/ProfileDetail?id=${profile.id}`, '_self');
  }


  return (
    <div className="user-profile-page">
      <Container className="profile-info">
        <Row>
        <Col xs={{ span: 12, order: 1 }} lg={{ span: 2, order: 1 }}>
            <Image fluid roundedCircle src={profile && profile.imageUrl ? profile.imageUrl : placeholder} />
            <div className="name-location">
              <p>{profile ? profile.name ? profile.name : profile.username ? profile.username : 'Loading..' : 'Loading..'}</p>
              <p>{profile ? profile.city : 'cambridge'}, {profile ? profile.state : 'MA'}</p>
            </div>
          </Col>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 5, order: 2 }}><Row className="profile-stats">
            <Col onClick={() => onClickFollowers()}>{statDisplay("FOLLOWERS", profile ? profile.followerCount : 0)}</Col>
            <Col onClick={() => onClickFollowings()}>{statDisplay("FOLLOWING", profile ? profile.followingCount : 0)}</Col>
            <Col onClick={() => onClickPosts()}>{statDisplay("POSTS", postCount)}</Col>
          </Row>
          </Col>
          <Col xs={{ span: 12, order: 6 }} lg={{ span: 3, order: 4 }} className="action-buttons">
            <Button className={'btn-edit'} onClick={() => onClickFollow()}><p>{status}</p></Button>
            <Button onClick={() => onMessage()}><BsFillChatSquareFill /><p>Message</p></Button>
          </Col>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 12, order: 5 }} className="profile-bio">
            <p>{profile ? profile.bio : ''}</p>
          </Col>
          <Col xs={{ span: 12, order: 3 }} lg={{ span: 12, order: 6 }} className="social-media">
            {
              profile && profile.facebook != null && profile.facebook.length > 0 &&
              <OverlayTrigger popperConfig={{ type: "fb" }} overlay={renderTooltip}><span>
                <Icon><div className="circle-icon"
                  onClick={() => {
                    window.open(`https://www.facebook.com/${profile.facebook}`);
                  }}
                ><FaFacebookF />
                </div></Icon>
              </span></OverlayTrigger>
            }
            {
              profile && profile.instagram != null && profile.instagram.length > 0 &&
              <OverlayTrigger popperConfig={{ type: "ig" }} overlay={renderTooltip}><span>
                <Icon><div className="circle-icon" onClick={() => {
                  window.open(`https://www.instagram.com/${profile.instagram}`)
                }}><FaInstagram /></div> </Icon>
              </span></OverlayTrigger>
            }
            {
              profile && profile.snapchat != null && profile.snapchat.length > 0 &&
              <OverlayTrigger popperConfig={{ type: "tw" }} overlay={renderTooltip}><span>
                <Icon><div className="circle-icon"><FaTwitter /></div></Icon>
              </span></OverlayTrigger>
            }
          </Col>
        </Row>
      </Container>
      <Container className="profile-subsections">
        <Container>Impact Score
          {
            profile &&
            <p className={'score-text'}>
              {profile.influenceScore}pts
            </p>
          }
          <Row>
            <Col>
              <ProgressBar striped now={influencePercent} variant="success" key={1} />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>{influencePercent}% of their goal</p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={'auto'} className={'badgeImage'}>
              <Image width="60px" height="60px" roundedCircle src={badgeImage} />
            </Col>
            <Col xs={'auto'}>
              <Row>
                <Col><p className={'badge-text'}>{badgeText}</p></Col>
              </Row>
              <Row>
                <Col className={'action-buttons'}>
                  <Button onClick={() => onSeeAllBadges()}>See All</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className={'container-events'}>
          <p>Event's I'm attending</p>
          <Row className={'list-events'}>
              {
                events.forEach(event => {
                    return (<div>
                        Event
                    </div>);
                })
              }
          </Row>
          <Row onClick={() => onSeeAllEvents()}><p className={'see-all'}>See All</p></Row>
        </Container>
        <Container className={'container-organization'}>
          <p>My Organizations</p>
          <Row className={'list-organization'}>
            {
              organizations.map(organization => {
                return (
                  <div className={'org-list-item'}>
                    <ProfileListItem profile={organization} onClickProfile={(profile) => onProfileDetail(profile)} isVertical={true}></ProfileListItem>
                  </div>
                );

              })
            }
          </Row>
          <Row onClick={() => onSeeAll()}><p className={'see-all'}>See All</p></Row>
        </Container>
        <Container className={'container-events'}>
        <p>Previous Events</p>
          <Row className={'list-events'}>
              {
                pEvents.forEach(event => {
                    return (<div>
                        Event
                    </div>);
                })
              }
          </Row>
          <Row onClick={() => onSeeAllPastEvents()}><p className={'see-all'}>See All</p></Row>
        </Container>
      </Container>
    </div>
  );
};

export default ProfileDetail;
