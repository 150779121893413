/**
 * @file CreateAccount Component.
 * @author - Adil Merribi
 */
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, FormControl, Col, Image, InputGroup, Container, Form, Row, Nav, Modal } from "react-bootstrap";
import userAdd from '../resources/userAdd.png';
import CitySearchComponent from "./CitySearchComponent";
import CONSTANTS from '../util/data';
import { REQUEST_CREATE_PROFILE } from '../servicelayer/servicelayer-actions';
// import '../styles/CreateAccount.css';
import { setSessionData } from '../util/util'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
// import Modal from 'react-modal';
function CreateAccount() {
  const [showImageModal, setShowImageModal] = useState(false);
  const [image, setImage] = useState({ preview: "", raw: "" });

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [referralCode, setReferralcode] = useState("");
  const [bio, setBio] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");
  const [snapchat, setSnapchat] = useState("");
  const [tiktok, setTikTik] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cityState, setCityState] = useState("");
  const [locationInfo, setLocationInfo] = useState({});
  const [tags, setTags] = useState([]);
  const dispatch = useDispatch();
  const newUser = useSelector(state => state.newUser);
  const newUserProfile = useSelector(state => state.newProfile);

  //cropping image
  const imgSource = image.preview ? image.preview : userAdd;
  const [src, setSrc] = useState(null);
  // const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const imageRef = useRef(null);
  const fileUrlRef = useRef(null);
  const uploadFileRef = useRef(null);

  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    aspect: 1
  })
  const history = useHistory();

  useEffect(() => {
    if (newUserProfile) //indicates a new user was created
    {
      setSessionData(newUserProfile);
      history.push('/EventsPage');
    }
  });

  const handleCitySelect = (selection) => {
    if (!selection.address_components) return;
    let _city = null, _state = null;
    setCityState(selection.formatted_address);
    selection.address_components.forEach(address => {
      if (address.types.some(x => x === "locality")) {
        _city = address.long_name;
      }
      if (address.types.some(x => x === "administrative_area_level_1")) {
        _state = address.short_name;
      }
    });
    if (_city) setCity(_city);
    if (_state) setState(_state);
    let lat = selection.geometry.location.lat()
    let lng = selection.geometry.location.lat();
    setLocationInfo({ latitude: lat, longitude: lng });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let profile =
    {
      name: name
      , bio: bio
      , email: newUser.email
      , uid: newUser.uid
      , phone: newUser.phone
      , locationInfo: locationInfo
      , issue: tags
      , referralCode: referralCode
      , image: image.raw
      , city: city
      , state: state
      , location: cityState
      , facebook: facebook
      , instagram: instagram
      , snapchat: snapchat
      , tiktok: tiktok
    };
    console.log('handle seubmit: profile:', profile);
    dispatch({ type: REQUEST_CREATE_PROFILE, payload: profile })

  }

  const handleChange = e => {
    if (e.target.files.length) {

      // setImage({
      //   preview: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0]
      // });
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setSrc(reader.result)
      );
      reader.readAsDataURL(e.target.files[0]);

    }
  };

  const renderOptions = (items) => {
    if (!items) return;
    return items.map((item, i) => (<option key={i} >{item.value}</option>))
  }
  let self = {};
  const handleOpenImage = () => {
    console.log('fileUpload:', self.fileUpload)
    uploadFileRef.current = self.fileUpload;
    self.fileUpload.click();
  }
  const handleSaveImage = () => {
    setShowImageModal(false);
  }
  const handleClose = () => {
    setShowImageModal(false);
  }
  const openImageDialog = () => {
    setShowImageModal(true);
  }
  const onImageLoaded = image => {
    imageRef.current = image;
  };
  const onCropChange = (crop, percentCrop) => {
    // console.log('onCropChange...crop:',crop);
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };
  const onCropComplete = crop => {
    makeClientCrop(crop);
  };


  const makeClientCrop = async crop => {
    if (imageRef && crop.width && crop.height) {
      const blob = await getCroppedImg(
        imageRef.current,
        crop,
        'newFile.jpeg'
      );
      window.URL.revokeObjectURL(fileUrlRef.current);
      fileUrlRef.current = window.URL.createObjectURL(blob);

      setImage({
        preview: fileUrlRef.current ,
        raw: new File([blob], "upload.jpg")
      });
      // console.log('fileUpload:',uploadFileRef.current)
      // if(uploadFileRef.current){
      //   uploadFileRef.current.files[0] = croppedImageUrl;
      //   console.log('fileUpload:',uploadFileRef.current.files);
      // }
      // setCroppedImageUrl(fileUrlRef.current);
    }
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;

        resolve(blob);
      }, 'image/jpeg');
    });
  }


  // const customStyles = {
  //   content: {
  //     width: '50%',
  //     height: '50%',
  //   }
  // };


  return (
    <div>
      <Modal
        show={showImageModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <h6 style={{ marginLeft: 20 }}>Choose an image</h6>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '32vh' }}>
            <ReactCrop
              src={src}
              crop={crop}
              onImageLoaded={onImageLoaded}
              onComplete={onCropComplete}
              onChange={onCropChange}
              // style={customStyles}
              imageStyle={{ maxHeight: '30vh' }}
            />
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <Button style={{ marginRight: 30 }} variant="secondary" onClick={handleClose}>
              Close
          </Button>
            <Button style={{ marginRight: 30 }} variant="primary" onClick={handleSaveImage}>Save</Button>
            <Button style={{ marginRight: 30 }} variant="primary" onClick={handleOpenImage}>Open</Button>
          </div>

        </Modal.Footer>
      </Modal>

      <Form onSubmit={handleSubmit} className="create-account" >

        <Form.Group as={Container}  >
          <Row className="justify-content-md-center">
            <Col xs={6} md={4} >
              {/** Image uploading will be replaced by a library */}
              <Image width="206" height="206" roundedCircle style={{ border: "1px solid" }}
                src={imgSource} onClick={() => {
                  openImageDialog();
                  //  self.fileUpload.click(); 
                }}
              />
              <Form.File id="profileImgUpload" onChange={handleChange} hidden ref={(me) => { self.fileUpload = me }} />
            </Col>
          </Row>
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="name">
            <Form.Label>Full Name*</Form.Label>
            <FormControl autoFocus placeholder="John Doe" size="lg" type="text"
              value={name} onChange={e => setName(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col} controlId="referralcode">
            <Form.Label>Referral Code</Form.Label>
            <FormControl size="lg"
              value={referralCode}
              onChange={e => setReferralcode(e.target.value)} type="number" />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>What issues are you passionate about:</Form.Label>
            <Form.Control as="select" size="sm" multiple value={tags} onChange={(e) => {
              let value = Array.from(e.target.selectedOptions, option => option.value);
              setTags(value);
            }} >
              {renderOptions(CONSTANTS.TAGS)}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} conrolId="location">
            <Form.Label>Location</Form.Label>
            <CitySearchComponent onChange={handleCitySelect} ></CitySearchComponent>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} conrolId="username">
            <Form.Label>Username*</Form.Label>
            <FormControl type="text" placeholder="john_doe" size="lg"
              value={username}
              onChange={e => setUsername(e.target.value)} />
          </Form.Group>

        </Form.Row>

        <Form.Row >
          <Form.Group as={Col} controlId="bio">
            <Form.Label>Biography</Form.Label>
            <FormControl type="text" as="textarea" rows="8"
              value={bio}
              onChange={e => setBio(e.target.value)} />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Social Media</Form.Label>
            <Form.Group as={Container} >
              <InputGroup >
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="fab fa-instagram"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="text" placeholder="Instagram" size="lg"
                  value={instagram}
                  onChange={e => setInstagram(e.target.value)} />
              </InputGroup>
            </Form.Group>
            <Form.Group as={Container} >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="fab fa-facebook"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="text" placeholder="Facebook" size="lg"
                  value={facebook}
                  onChange={e => setFacebook(e.target.value)} />
              </InputGroup>
            </Form.Group>

            <Form.Group as={Container} >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="fab fa-snapchat"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="text" placeholder="Snapchat" size="lg"
                  value={snapchat}
                  onChange={e => setSnapchat(e.target.value)} />
              </InputGroup>
            </Form.Group>

            <Form.Group as={Container} >
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <i className="fab fa-linkedin"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="text" placeholder="TikTok" size="lg"
                  value={tiktok}
                  onChange={e => setTikTik(e.target.value)} />
              </InputGroup>
            </Form.Group>
          </Form.Group>
        </Form.Row>
        <Form.Row >
          <Col md={{ span: 6, offset: 3 }} style={{ textAlign: "center" }}>
            <Form.Label>By signing up you agree to the</Form.Label>
            <Nav.Item style={{ color: '#402366', paddingTop: 0 }}>
              <Nav.Link style={{ color: '#402366', fontWeight: "bold", paddingTop: 0 }} href="https://www.turnup.us/privacy-policy-and-terms/">privacy policy and terms</Nav.Link>
            </Nav.Item>
          </Col>
        </Form.Row>
        <Form.Row >
          <Col md={{ span: 6, offset: 3 }} style={{ textAlign: "center" }}>
            <Button style={{ width: "60%" }} size="lg" type="submit" >Continue</Button></Col>
        </Form.Row>
      </Form>
    </div>
  );
}
export default CreateAccount;