import React, { useState, useEffect } from "react";
import { Row, Container, Col, Navbar, Image } from "react-bootstrap"
import MissionsCarousel from "./MissionsCarousel";
import LoginComponent from "./LoginComponent";
import SignupComponent from "./SignupComponent";
import { useHistory } from "react-router-dom";
import logo from '../resources/logo.png'
import "../styles/LandingPage.css"
import { isMobile } from 'react-device-detect';

const LandingPage = (props) => {
    const history = useHistory();
    const [isSignUpView, setIsSignUpView] = useState(false);
    const [header, setHeader] = useState("Log In");
    useEffect(() => {
        console.log('isMobile:', isMobile);
        if(isMobile){
            let confirmed = window.confirm("Please download TurnUp app.");
            if(confirmed){
                const url = 'https://turnupactivism.page.link/download';
                var win = window.open(url, '_self');
                //win.focus();
            }
        }

    }, [isMobile]);
    

    const items = [
        {
            img: "/img/missions-1.png",
            alt: "nohate",
            caption: "TurnUP is a super-power for social justice changemakers, enabling you to connect to organizations, events and each other."

        },
        {
            img: "/img/missions-2.png",
            alt: "blm",
            caption: "TurnUP believes People’s Rights are Human Rights and Human Rights are People’s Rights"
        },
        {
            img: "/img/missions-3.png",
            alt: "blm",
            caption: "We don’t act on hate. We are stronger together. Thank you for joining us"
        }
    ]
    const renderLoginSignUp = () => {
        if (!isSignUpView) {
            return (<div>
                <LoginComponent onSuccesCallback={() => history.push("/HomePage")} />
                <span onClick={() => { setIsSignUpView(true); setHeader("Join Us") }}>Don't have an account? Click here to sign up.</span>
            </div>)
        }
        else {
            return (<div>
                <SignupComponent onSuccesCallback={(data) => { history.push('/PhoneConfirm', {data:data}); }} />
                <span onClick={() => { setIsSignUpView(false); setHeader("Log In") }}>Already have an account? Click here to log in.</span>
            </div>)
        }
    }
    return (<div className="landing-page">
        <Navbar collapseOnSelect expand="lg" className={'bg-nav justify-content-center'}>
            <Navbar.Brand href="/TurnupHome"><Image className="logo" alt="TurnUp Activism" src={logo} /></Navbar.Brand>
        </Navbar>
        <Container className="layout_login">
            <Row>
                <Col className="welcome">
                    <div className={'title'}>Welcome to TurnUp!</div>
                    {
                        !isSignUpView ? 
                        <div className="desc-welcome">TurnUp is a super-power for social justice changemakers, <br/> enabling you to connect to organizations, events and each other.</div>
                        :
                        <div className="desc-welcome">We don't act on hate. We are stronger together. <br/> Thank you for joining us.</div>
                    }
                </Col>
            </Row>
            <Row>
                {/* <Col>
                    <MissionsCarousel style={{ backgroundColor: "#f8f4fe" }} items={items} header={"Welcome to TurnUP!"}></MissionsCarousel>
                </Col> */}
                <Col>
                <Container className="login-signup"><h3 className="header">{header}</h3>{renderLoginSignUp()}</Container>
                </Col>
            </Row>
        </Container>

    </div>)
}

export default LandingPage;