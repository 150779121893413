import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BsCalendar, BsClockFill, BsFillTagFill } from 'react-icons/bs';
import { ImLocation } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTION from '../servicelayer/servicelayer-actions';
import '../styles/DisplayEvent.css';
import PeopleComponent from "./PeopleComponent";

const DisplayEvent = ({ event }) => {
  //console.log('DisplayEvent:', event);
  const dispatch = useDispatch();

  const eventProfile = useSelector(state => state.fetchProfileResult);
  const loggedInUser = useSelector(state => state.user);

  //const [eventProfile, setEventProfile] = useState(event.prof);
  const isRSVP = () => {
    
    let currentUserId = loggedInUser && loggedInUser.uid;
    return (event && event.rsvps.indexOf(currentUserId) > -1);
  }

  const [isRSVPd, setRSVP] = useState(isRSVP());



  let eventTitleElement = null;
  const setEventTitleRef = element => {
    eventTitleElement = element;
  };

  const handleRSVP = () => {
    let currentUserId = loggedInUser && loggedInUser.uid;

    //if user is not logged in, direct to sign up
    if (!currentUserId) {
      return;
    }

    dispatch({
      type: ACTION.REQUEST_HANDLE_RSVP, payload: {
        eventId: event.id,
        userId: currentUserId,
        action: isRSVPd ? "remove" : "add"
      }
    });
    setRSVP(!isRSVPd);
  }

  useEffect(() => {
    dispatch({ type: ACTION.REQUEST_FETCH_PROFILE, payload: { docId: event.eventProfileId } })
  }, [dispatch, event.eventProfileId]);

  //console.log('tags:', event.tags);
  // format list of tags
  const renderedTags = (event.tags || []).map((tag, i) => <p className="tag" key={i}><BsFillTagFill />{tag}</p>);

  /**
   * Cuts off description after 500 characters and adds "More" link
   * @param {string} desc - description of event
   */
  function cutoffDesc(desc) {
    if (desc.length > 500) {
      return (
        <p>{desc.substring(0, 500) + "..."}
          <a href={`/EventDetails?event=${event.id}`} className="more">More</a></p>
      );
    }
    else {
      return (
        <p>{desc}
          <a href={`/EventDetails?event=${event.id}`} className="more">Detail</a></p>
      );
    }
  }

  /**
   * Formats Date and Time based on if it is a single-day or multi-day event
   */
  // function formatDateTime () {
  //   if (event.eventDate && event.eventEndDate && event.eventDate.toLocaleDateString() === event.eventEndDate.toLocaleDateString()) {
  //       return (
  //         <div>
  //             <p>
  //               <BsCalendar/>
  //               {event.eventDate.toLocaleDateString([], {weekday:'long', month:'long', day:'numeric', year:'numeric'})}
  //             </p>
  //             <br></br>
  //             <p>
  //               <BsClockFill/>
  //               {event.eventDate.toLocaleTimeString([], {hour: 'numeric', minute:'numeric'})}
  //               &nbsp;-&nbsp;
  //               {event.eventEndDate.toLocaleTimeString([], {hour: 'numeric', minute:'numeric'})}
  //             </p>
  //         </div>
  //       );
  //   }
  //   else if (event.eventDate && event.eventEndDate){
  //     return (
  //       <div>
  //           <p>
  //             <BsCalendar/>
  //             {event.eventDate.toLocaleString([], {weekday:'long', month:'long', day:'numeric', year:'numeric', hour: 'numeric', minute:'numeric'})}
  //             &nbsp;to&nbsp;
  //             {event.eventEndDate.toLocaleString([], {weekday:'long', month:'long', day:'numeric', year:'numeric', hour: 'numeric', minute:'numeric'})}
  //           </p>
  //       </div>
  //     );
  //   } else if (event.eventDate) {
  //     return (
  //       <div>
  //             <p>
  //               <BsCalendar/>
  //               {event.eventDate.toLocaleDateString([], {weekday:'long', month:'long', day:'numeric', year:'numeric'})}
  //             </p>
  //             <p>
  //               <BsClockFill/>
  //               {event.eventDate.toLocaleTimeString([], {hour: 'numeric', minute:'numeric'})}
  //             </p>
  //       </div>
  //     );
  //   } else {
  //     return <div></div>
  //   }
  // }

  function formatDateTime() {
    return (<Row className="event-detail">
      <Col>
        <p>
          <BsCalendar className={'icon'} />

          <span>{event.eventDate.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' })}</span>
        </p>
      </Col>
      <Col>
        <p>
          <BsClockFill className={'icon'} />
          <span>{event.eventDate.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })}</span>
        </p>
      </Col>
      <Col>
        <p>
          <ImLocation className={'icon'} />
          {/* {event.location} */}
          <span>{`${event.city}, ${event.state}`}</span>
        </p>
      </Col>
    </Row>)
  }
  

  const handleImageError = (e) => {
    // e.target.src = "https://via.placeholder.com/500x60?text=Image+Not+Found"
    e.target.style.display = "none"
    eventTitleElement.style.position = "unset";

  }



  const renderRSVP = () => {
    if (loggedInUser && loggedInUser.uid) {
      return (<Button className="button" onClick={handleRSVP} >{isRSVPd ? "Remove RSVP" : "RSVP"}</Button>)
    }

  }


  return (
    <Container className="event">
      <Row>
        <Col>
          <a href={`/EventDetails?event=${event.id}`}>
            <div className="imageTitle">

              <img src={event.compressedImg} alt={event.name} onError={handleImageError} />
              <p ref={setEventTitleRef} className="name">{event.name}</p>
            </div>
          </a>
        </Col>
      </Row>
      <hr />
      <Row xs={1} className="info">
        <Col>
          <Row>
            <Col xs={12} md={12}>
              {eventProfile && <PeopleComponent profile={eventProfile} />}
            </Col>
            <Col xs={12} md={12} className="details">
              <Row>
                <Col className="detail">
                  {renderedTags}
                </Col>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </Row>
              <Row>
                <Col className="detail">
                  {formatDateTime()}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="title">
            <p>{event.name}</p>
          </Col>
        <Col>
          {cutoffDesc(event.description)}
        </Col>
        {/* <Col>
            <p>[people icons] # People Going</p>
          </Col> */}
        <Col>
          {renderRSVP()}

        </Col>
      </Row>
    </Container>
  );
};

export default DisplayEvent;
