import React from 'react'
import { MAPS_API_KEY } from '../util/util';
import Autocomplete from 'react-google-autocomplete';

const CitySearchComponent = ({onChange, parentRef}) => 
{
   
    return (
            <Autocomplete ref={parentRef}  className="form-control" apiKey={MAPS_API_KEY}  onPlaceSelected={(place) => { if(onChange) onChange(place);  }}  types={['(regions)']}   componentRestrictions={{country: "us"}} />
            )
}

export default CitySearchComponent
