/**
 * @description This file is responsible for data access against persisted data stores.
 * @author - Amonte
 */
import { firestoreDB as db, getServerTime, getFieldValue, firestoreConst, asyncForEach, firebase } from '../util/util'

import Config from '../data/Config';

import { DateTime } from 'luxon'
const RESULT_LIMIT = 6;
const ORDERBY_ASCENDING = "asc";
const ORDERBY_DESCENDING = "desc";



export async function getUserProfile(profileId) {
    //development
    return getUserProfileByDoc(profileId);
    //Firestore docIds can not be modified. The production mobile app searches for profiles by doc id. When working against the dev firestore instance, which is a replica of production because problematic for fetching user profiles, there's no easy set up for testing. The work around is to add a property authentication user id to the given profile document in the profiles collection under the property "uid".
    // if (process.env.REACT_APP_ENVIRONMENT === "development") {
    //     return getUserProfileByProfileProperty(profileId)
    // }
    // else {
    //     return getUserProfileByDoc(profileId);
    // }
}

// async function getUserProfileByProfileProperty(profileId) {
//     let snapshot = await db.collection('profiles').where("uid", "==", profileId).get();
//     let docs = snapshot.docs
//     if (docs[0]) {
//         let userProfile = docs[0].data();
//         userProfile.docId = docs[0].id;
//         return userProfile;
//     }
//     else {
//         return null;
//     }
// }

export async function getUserProfileByDoc(profileId) {
    console.log("getUserProfileByDoc: profileId:",profileId);
    let doc = await db.collection('profiles').doc(profileId).get();
    console.log("getUserProfileByDoc: doc:",doc,':data:', doc.data());
    if (doc.exists) {
        let p = doc.data();
        // p.ref = doc.ref;
        p.id = doc.id;
        return p;
    } else {
        return null;
    }

}

export async function fetchEvents(filter) {

    console.log('filterEvents: filter:', filter);

    let events = [];
    const collection = db.collection('events');


    let query = collection.orderBy("eventDate");
    //state
    query = filter.state ? query.where("state", "==", filter.state.toUpperCase()) : query;
    //city
    query = filter.city ? query.where("city", "==", filter.city.toLowerCase()) : query;
    //eventType
    query = filter.type ? query.where("eventType", "==", filter.type) : query;
    //category
    query = filter.category ? query.where("category", "==", filter.category) : query;

    //tag
    query = filter.tags ? query.where("tag", "==", filter.tags) : query;

    if (filter.startDate) {
        const dt = DateTime.fromISO(filter.startDate.toISOString());
        query = query.where("eventDate", ">=", dt.toISO());
    }

    //event before
    if (filter.endDate) {
        const dt = DateTime.fromISO(filter.endDate.toISOString());
        query = query.where("eventDate", "<", dt.toISO());
    }

    //search term
    if (filter.searchTerm) {
        query = query.where("eventTitleArray", "array-contains", filter.searchTerm.toLowerCase());
    }

    //paging
    if (filter.last) {
        query = query.startAfter(filter.last);
    }

    query = query.limit(RESULT_LIMIT);

    const snapshot = await query.get();
    const docs = snapshot.docs;

    console.log('docs length:', docs.length);

    let first = docs[0];
    let last = docs[docs.length - 1];
    snapshot.forEach(doc => {
        let event = doc.data();
        event.id = doc.id;
        events.push(event);
    });
    return { events: events, first: first, last: last };
}

export async function fetchFollower(searchTerm) {
   return false;
}
export async function fetchProfiles(filter) {
    // console.log('filterProfiles: filter=>', filter);
    let profiles = [];

    const collection = db.collection('profiles');

    let query = collection.orderBy('email');
    //state
    query = filter.state ? query.where("state", "==", filter.state.toUpperCase()) : query;
    //city
    query = filter.city ? query.where("city", "==", filter.city.toLowerCase()) : query;
    //name
    query = filter.searchTerm ? query.where("namesArray", "array-contains", filter.searchTerm.toLowerCase()) : query;
    //organization
    query = filter.isOrganization ? query.where("isOrganization", "==", true) : query;
    //organization name
    query = filter.type && filter.type === 'organization' ? query.where('followerArray', 'array-contains', filter.uid) : query;
    //following
    query = filter.type ? filter.type === 'follower' ? query.where("followingArray", "array-contains", filter.uid) : query.type === 'following' ? query.where("followerArray", "array-contains", filter.uid) : query : query;

    //paging
    query = filter.last ? query.startAfter(filter.last) : query;

    //limitation
    query = query.limit(RESULT_LIMIT);

    console.log('query:', query);
    //snapshot
    const snapshot = await query.get();
    const docs = snapshot.docs;
    console.log('fetchProfiles docs length:', docs.length);

    let first = docs[0];
    let last = docs[docs.length - 1];

    snapshot.forEach(doc => {
        let profile = doc.data();
        profile.id = doc.id;
        profiles.push(profile);
    });

    return { profiles: profiles, first: first, last: last };
}

export async function getFeed(userProfileId, after, view) {
    console.log('getFeed: userProfileId:', userProfileId, ':after:', after, ':view:', view);

    if (after === null) {
        return { last: null, posts: [], events: [] };
    }

    let query = db.collection('profiles').doc(userProfileId)
        .collection('feed');
    if (view === 'following') {
        // query = query.where('owner', '>', userProfileId);
        // query = query.where('owner', '<', userProfileId);
        query = query.where('owner', '!=', userProfileId);
    }
    if (view === 'you') query = query.where('owner', '==', userProfileId);
    if (view === 'mypost') {
        query = query.where('owner', '==', userProfileId);
    }
    if (view !== "following") {
        query = query.orderBy('timestamp', ORDERBY_DESCENDING);
        query = query.limit(RESULT_LIMIT);
    }
    else {
        query = query.limit(RESULT_LIMIT);
    }

    if (after) {
        query = query.startAfter(after);
    }
    return query.get().then(snapshot => {
        const feedDocuments = snapshot.docs;
        let feeds = []
        let last = feedDocuments[feedDocuments.length - 1];
        feedDocuments.forEach(doc => {
            feeds.push(doc.data());
        });
        //each feed document is really just a reference to another document
        let feedReferences = [];
        feeds.forEach(feed => {
            feedReferences.push(feed.ref.get().then(feedReference => {
                return feedReference;  //add the feed's referenced document's data (as a promise)
            }));
        });
        return Promise.all(feedReferences).then(async referenceData => { //resolve all the feed reference promises (fetch document data)
            //the reference should either be an "event" reference or "post"
            //feed "posts" can be passed directly.
            let posts = [];
            referenceData.forEach(postDoc => {
                // console.log('service-dal-post:',postDoc.data());
                let post = postDoc.data();
                if (!post) return; //item may not exist
                post.id = postDoc.id;
                post.ref = postDoc.ref;
                if (!post.idEvent) {
                    posts.push(post);
                }
            });
            await asyncForEach(referenceData, async postDoc => {
                let post = postDoc.data();
                if (!post) return;
                post.id = postDoc.id;
                post.ref = postDoc.ref;            
                // let post = post;
                if (!post.idEvent) {
                    posts.push(post);
                }
                else {
                    let eventDoc = await db.collection("events").doc(post.idEvent).get();
                    if (eventDoc.exists) {
                        let _event = eventDoc.data();
                        _event.id = eventDoc.id;
                        let _tags = _event.tags || [];
                        let tags = [];
                        _tags.forEach(tag => {
                            if(tag !== undefined) tags.push(tag);
                        })
                        console.log('getFeed: tags=>', tags);
                        _event.tags = tags;
                        _event.ref = eventDoc.ref;
                        // console.log('eventRef:',eventDoc.ref);
                        post.event = { ..._event };
                        posts.push(post);
                    }
                }
            });
            return { posts: posts, last: last, events: [] } //end of promise chain
        });
    });
}


export async function getResourceCategories() {
    let categories = []
    let query = db.collection("categories")
        .where("alias", "!=", "")
        .orderBy("alias", ORDERBY_ASCENDING);
    const snapshot = await query.get();
    snapshot.forEach(doc => {
        let resourceCategory = doc.data();
        resourceCategory.id = doc.id;
        categories.push(resourceCategory);
    });
    return categories;
}

export async function getResources(categoryId) {
    let resources = []
    let query = db.collection("resources")
        .where("category", "==", categoryId)
        .orderBy("name", ORDERBY_ASCENDING);
    const snapshot = await query.get();
    snapshot.forEach(doc => {
        let resource = doc.data();
        resource.id = doc.id;
        resources.push(resource);
    });
    return resources;
}

export async function fetchEvent(docid) {
    let doc = await db.collection('events').doc(docid).get();
    if (doc) {
        return { ...doc.data(), id: doc.id };
    }
}
export async function followUser(param) {
    let profile_id = param.profile_id;
    let status = param.status;
    const uid = firebase.auth().currentUser.uid;
    if (status === "Unfollow") {
        //unfollowing
        await db.collection('profiles').doc(uid).collection('following').doc(profile_id).delete();
        await db.collection('profiles').doc(profile_id).collection('followers').doc(uid).delete();
        await db.collection('profiles').doc(profile_id).collection('pendingFollowing').doc(uid).delete();

        return 'Follow';
    }
    else if (status === "Follow") {
        //following
        let profDoc = await db.collection('profiles').doc(profile_id).get();
        let userDoc = await db.collection('profiles').doc(uid).get();
        if (profDoc.exists) {
            await db.collection('profiles').doc(uid).collection('following').doc(profile_id).set({ ref: profDoc.ref });
            await db.collection('profiles').doc(profile_id).collection('followers').doc(uid).set({ ref: userDoc.ref });
            return "Unfollow";
        }
        return 'Follow';
    }
    else if (status === "Requested") {
        await db.collection('profiles').doc(profile_id).collection('pendingFollowing').doc(uid).delete();
        return 'Follow';
    }

    return "Unfollow";
}

export async function createEvent(event) {
    event.tag = "";
    if (event.tags && event.tags.length > 0) {
        event.tag = event.tags[0];
    }
    return await db.collection('events').doc(event.docid).set(event, { merge: true }).then(res => {
        return fetchEvent(event.docid);
    });

}

export async function fetchPost(userId, docId) {
    let doc = await db.collection('profiles').doc(userId).collection('posts').doc(docId).get();
    if (doc) {
        return doc.data();
    }
}

export async function createPost(post) {
    post.timestamp = getServerTime();
    return await db.collection('profiles').doc(post.uid).collection('posts').doc(post.docid).set(post, { merge: true }).then(res => {
        return fetchPost(post.uid, post.docid);
    });

}

/**
 * Should only be used to create profiles. 
 * @param {*} profile 
 */
export async function createProfile(profile) {
    profile.timestamp = getServerTime();
    return await db.collection('profiles').doc(profile.uid).set(profile, { merge: true }).then(async res => {
        //updating users influence score
        await db.collection('profiles').doc(profile.uid).update({ influenceScore: firestoreConst.FieldValue.increment(Config.SCORE_CREATE_ACCOUNT) });
        console.log('updating score by creating an account.');
        if (profile.facebook || profile.instagram || profile.snapchat) {
            console.log('updating influnce score thanks to the social profile adding.')
            await db.collection('profiles').doc(profile.uid).update({ influenceScore: firestoreConst.FieldValue.increment(Config.SCORE_LINK_ACCOUNT) })
        }
        //check if the referreal code is applicable
        let profDocs = await db.collection('profiles').where("referralCode", "==", profile.referralCode).get();
        console.log('referreal:', profile.referralCode, ':profDocs:', profDocs.length);
        await asyncForEach(profDocs, async doc => {
            console.log('updating score by refferal');
            let score = doc.data().influenceScore;
            score += Config.SCORE_INVITED_BY_REFERRAL;

            await db.collection('profiles').doc(doc.id).set({ influenceScore: score }, { merge: true });
            await db.collection('profiles').doc(profile.uid).set({ appliedReferal: doc.id }, { merge: true });
        })


        return getUserProfile(profile.uid).then(x => { return x; });
    });
}

export async function connectWithModerator(profile) {
    //console.log('connectWithModerator profile:',profile);
    let uid = profile.uid;

    let moderatorDocs = await db.collection('moderators').limit(1).get();
    if (moderatorDocs.docs.length > 0) {
        let moderatorDoc = moderatorDocs.docs[0];
        let profDoc = await db.collection('profiles').doc(moderatorDoc.id).get();
        let userDoc = await db.collection('profiles').doc(uid).get();
        //console.log('Getting the user document. userDocRef:', userDoc.ref);
        if (profDoc) {
            //register as friend
            let profileID = profDoc.id
            await db.collection('profiles').doc(uid).collection('following').doc(profileID).set({ ref: profDoc.ref });
            if (userDoc) await db.collection('profiles').doc(profileID).collection('followers').doc(uid).set({ ref: userDoc.ref });
        }
    }
}

export async function handleEventRSVP(eventId, userId, action) {
    // console.log('handling event RSVP: eventID:', eventId,':userid:',userId,':action:', action);
    switch (action) {
        case "add":
            await db.collection('events').doc(eventId).set({
                rsvps: getFieldValue().arrayUnion(userId)
            }, { merge: true });
            return { eventId: eventId, action: action };
        case "remove":
            await db.collection('events').doc(eventId).set({
                rsvps: getFieldValue().arrayRemove(userId)
            }, { merge: true });
            return { eventId: eventId, action: action };
        default:
            console.error("Invalid action:", action)
            return null;
    }


}

export async function getNotifications(param) {
    //getting user id
    
    let uid = param.uid;    
    //const uid = firebase.auth().currentUser.uid;
    //console.log('getting notifications: uid:', uid);
    const collection = db.collection('profiles').doc(uid).collection('notifications');

    let query = collection.orderBy('timestamp', ORDERBY_DESCENDING);
    query = query.limit(Config.LIMIT_NOTIFICATION);

    if(param.last){
        query = query.startAfter(param.last);
    }
    
    const snapshot = await query.get();
    const docs = snapshot.docs;
    console.log('snapshot docs:', docs);
    let notifications = [];
    let last = docs[docs.length - 1];
    docs.forEach(doc => {
        let notification = doc.data();
        notification.id = doc.id;
        notifications.push(notification);
    });

    return { notifications : notifications, last: last}
}