import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Container, Form, Col, FormControl, Image, Row, Button, InputGroup, Alert, Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import CitySearchComponent from '../components/CitySearchComponent';
import ConfirmationBox from '../components/ConfirmationBox';
import CONSTANTS from '../util/data';
import { REQUEST_CREATE_EVENT, CLEAR_NEW_EVENT } from '../servicelayer/servicelayer-actions';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import '../styles/EditProfile.css'
import userAdd from '../resources/userAdd.png';

const EditProfile = () => {
    const [eventImg, setEventImg] = useState({ name: "", preview: "", raw: "" });
    const [eventTitle, setEventTitle] = useState('');
    const [showImageModal, setShowImageModal] = useState(false);
    const [type, setType] = useState(CONSTANTS.EVENTS_TYPE[2].value);
    const [tags, setTags] = useState([]);
    const [description, setDescription] = useState('');
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    
    const [facebookHandle, setFacebookHandle] = useState('');
    const [instagramHandle, setInstagramhandle] = useState('');
    const [snapchatHandle, setSnapchatHandle] = useState('');
    const [tiktokHandle, setTiktokHandle] = useState('');

    const [bio, setBio] = useState('');

    const [locationResult, setLocationResult] = useState(null);
    
    const newEvent = useSelector(state => state.newEvent);
    const user = useSelector(state => state.user);
    const validationError = useSelector(state => state.createEventError);
    const dispatch = useDispatch();
    const userName = (user && user.name) ?? "User";

    const [src, setSrc] = useState(null);
    const citySearchElement = useRef();
    const imgSource = user && user.imageUrl ? user.imageUrl : userAdd
    const imageRef = useRef(null);
    const fileUrlRef = useRef(null);
    const uploadFileRef = useRef(null);

    // const renderOptions = (items) => {
    //     if (!items) return;
    //     return items.map((item, i) => (<option key={i} >{item.value}</option>))
    // }

    const handleCitySelect = (selection) => {
        if (!selection.address_components) return;
        setLocationResult(selection);
        let _city = null, _state = null;
        selection.address_components.forEach(address => {
            if (address.types.some(x => x === "locality")) {
                _city = address.long_name;
            }
            if (address.types.some(x => x === "administrative_area_level_1")) {
                _state = address.short_name;
            }
        });
    }

    const handleCreateEvent = (e) => {
        e.preventDefault();
    }

    const showValidationError = () => {
        if (validationError) {
            return (<Alert variant="danger">{validationError.errorMessage}</Alert>)
        }
    }

    const handleImgUpload = (e) => {
        if (e.target.files.length
            && (e.target.files[0].type === 'image/png'
                || e.target.files[0].type === 'image/jpg'
                || e.target.files[0].type === 'image/jpeg'
            )) {
            setEventImg({
                name: e.target.files[0].name,
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    const closeModal = () => {
        dispatch({ type: CLEAR_NEW_EVENT });

    }

    let self = {};

    const handleOpenImage = () => {
        console.log('fileUpload:', self.fileUpload)
        uploadFileRef.current = self.fileUpload;
        self.fileUpload.click();
    }
    const handleChange = e => {
        if (e.target.files.length) {

            // setImage({
            //   preview: URL.createObjectURL(e.target.files[0]),
            //   raw: e.target.files[0]
            // });
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setSrc(reader.result)
            );
            reader.readAsDataURL(e.target.files[0]);

        }
    };
    const openImageDialog = () => {
        setShowImageModal(true);
    }
    const clearForm = () => {
        setEventImg({ name: "", preview: "", raw: "" });
        setEventTitle('');
        setType(CONSTANTS.EVENTS_TYPE[2].value);
        setTags([]);
        setDescription('');
        setLocationResult(null);
        citySearchElement.current.refs.input.value = "";
    };
    
    const renderOptions = (items) => {
        if (!items) return;
        return items.map((item, i) => (<option key={i} >{item.value}</option>))
    }

    useEffect(() => {
        if (newEvent !== undefined) {
            clearForm();
        }
    }, [newEvent]);

    useEffect(() => {
        if(user){
            console.log('user retrieved:', user);
            
        }
    }, [user]);

    return (
        <div className={'create-event-layout'}>
            <Container className="create-event">
                <h3>Edit Profile</h3>
                {showValidationError()}
                <Form onSubmit={handleCreateEvent} >
                    <Row>
                        <Col xs={12} md={4}>
                            <Image width="150" height="150" roundedCircle style={{ border: "1px solid" }}
                                src={imgSource} onClick={() => {
                                    openImageDialog();
                                    //  self.fileUpload.click(); 
                                }}
                            />
                            <Form.File id="profileImgUpload" onChange={handleChange} hidden ref={(me) => { self.fileUpload = me }} />
                        </Col>
                        <Col xs={12} md={4}>  {/** picture name email*/}
                            <Form.Row>
                                <Form.Label>Name</Form.Label>
                                {/* <Form.Control value={userName} readOnly ></Form.Control> */}
                                <Form.Control onChange={(e) => setName(e.target.value)} value={number} placeholder="User Name"></Form.Control>
                            </Form.Row>
                            <Form.Row>
                                <Form.Label>Phone number </Form.Label>
                                <Form.Control onChange={(e) => setNumber(e.target.value)} value={number} placeholder="Phone number"></Form.Control>
                            </Form.Row>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Row>
                                <Form.Label>Email</Form.Label>
                                <Form.Control onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email"></Form.Control>
                            </Form.Row>
                            <Form.Row>
                                <Form.Label>Location</Form.Label>
                                <Form.Control onChange={(e) => setEventTitle(e.target.value)} value={eventTitle} placeholder="Event Title"></Form.Control>
                            </Form.Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Label>Facebook Name</Form.Label>
                            <Form.Control onChange={(e) => setFacebookHandle(e.target.value)} value={facebookHandle} placeholder="Facebook Name"></Form.Control>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Label>Instagram Handle</Form.Label>
                            <Form.Control onChange={(e) => setInstagramhandle(e.target.value)} value={instagramHandle} placeholder="Instagram Handle"></Form.Control>
                        </Col >
                        <Col xs={12} md={4}>
                            <Form.Label>Snapchat Handle</Form.Label>
                            <Form.Control onChange={(e) => setSnapchatHandle(e.target.value)} value={snapchatHandle} placeholder="Snapchat Handle"></Form.Control>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Label>Tiktok Handle</Form.Label>
                            <Form.Control onChange={(e) => setTiktokHandle(e.target.value)} value={tiktokHandle} placeholder="Tiktok Handle"></Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId="bio">
                            <Form.Label>Biography</Form.Label>
                            <FormControl type="text" as="textarea" rows="8"
                                value={bio}
                                onChange={e => setBio(e.target.value)} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>What issues are you passionate about:</Form.Label>
                            <Form.Control as="select" size="sm" multiple value={tags} onChange={(e) => {
                                let value = Array.from(e.target.selectedOptions, option => option.value);
                                setTags(value);
                            }} >
                                {renderOptions(CONSTANTS.TAGS)}
                            </Form.Control>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}></Col>
                        <Col xs={12} md={4}><Button className="btn-create-event" type="submit">Save Changes</Button></Col>
                        <Col xs={12} md={4}></Col>
                    </Row>
                    {/* <Row>
            <Col></Col>
            <Col><Button type="button" onClick={clearForm}>Clear</Button></Col>
        </Row> */}
                </Form>
                <Modal show={newEvent !== undefined} onHide={closeModal}>
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <ConfirmationBox header="Event Created" />
                    </Modal.Body>
                    <Button onClick={closeModal}>OK</Button>
                </Modal>
            </Container>
        </div>
    )
}

export default EditProfile;