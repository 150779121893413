import * as DAL from './servicelayer-dal'
import * as ACTION from './servicelayer-actions'
import {call, put} from 'redux-saga/effects'
import {CreateProfileModel, Profile} from './types'
import {toFirebaseSearchArray} from '../util/data';
import ImageService from './imageService';
import { Normalize } from './normalizer';

let turnUpProfile:Profile | null;

const TurnUpProfileId = "LRV1l3GxkEchni2fpLxwAiq2Bml1";

function* createProfile(dispatch:any)
{
    let payload = dispatch.payload;
    let profile:CreateProfileModel = 
    {
        uid:payload.uid
        ,name:payload.name 
        ,bio:payload.bio 
        ,email:payload.email 
        ,issue:payload.issue ?? [] 
        ,location_info:payload.locationInfo ?? {}
        ,location:payload.location ?? ""
        ,city:payload.city ?? ""
        ,state:payload.state ?? ""
        ,phone:payload.phone ?? ""
        ,referralCode:Math.random().toString().slice(2, 7)
        ,imageUrl:""
        ,timestamp:new Date() //will be replaced with server time.
        
        //social media
        ,facebook:payload.facebook ?? ""
        ,instagram:payload.instagram ?? ""
        ,snapchat:payload.snapchat ?? ""
        ,tiktok:payload.tiktok ?? ""
    
        ,followerCount: 0
        ,followingCount: 0
        ,influenceGoal: 0
        ,influenceScore: 0
    
        ,isNotification: true 
        ,isMsgNotification: true
        ,isEventNotification: true
        ,isInviteNotification: true 
        ,isFollowNotification: true
        ,isLikeNotification: true
        ,optIn: payload.optIn ?? true,
    
        isOrganization: false
        ,nameLowerCased:payload.name.toLowerCase()
        ,namesArray:toFirebaseSearchArray(payload.name)
    }
    
    //validate
    //upload image
    try{
        if(payload.image){
             profile.imageUrl = yield call(ImageService.uploadImage, payload.image, `/profiles/${profile.uid}/profileImages`);
        }
    }
    catch(e)
    {
        yield put({type:ACTION.RESPONSE_IMAGE_UPLOAD_ERROR, data:{error:e, action:ACTION.REQUEST_CREATE_PROFILE}});
    }
    try{
        let profileResult:string = yield call(DAL.createProfile, profile);
        
        let userProfile = Normalize.profiles([profileResult])[0];
        yield call(DAL.connectWithModerator, userProfile);
        yield put({
            type:ACTION.RESPONSE_LOGIN,
            data:userProfile
        });
        yield put({type:ACTION.RESPONSE_PROFILE_CREATED, data:profileResult});
        
        
    }
    catch(ee)
    {
        yield put({type:ACTION.RESPONSE_CREATE_PROFILE_ERROR, data:ee })
    }    
}

function* fetchProfile(dispatchObject:any)
{
    try{
        let docId = dispatchObject.payload && dispatchObject.payload.docId;
        let isFullInfo = dispatchObject.payload.isFullInfo ? true : false;

        if(docId === TurnUpProfileId)
        {
            if (turnUpProfile != null) {
                yield put({
                    type: ACTION.RESPONSE_FETCH_PROFILE,
                    data: turnUpProfile
                });
                return;
            }

        }

        if(!docId)
        {
            return;
        }
        let profileResult:string = yield call(DAL.getUserProfileByDoc, docId);
        let  userProfile = Normalize.profiles([profileResult])[0];

        
        if(docId === TurnUpProfileId)
        {
            turnUpProfile = userProfile;
        }

        yield put({
            type:ACTION.RESPONSE_FETCH_PROFILE,
            data: isFullInfo ? profileResult : userProfile
        });
    }
    catch(ee)
    {
        console.error(ee);
    }    
}
function* fetchProfiles(dispatchObject : any){
    // console.log('fetch profiles..dispatchObject:', dispatchObject);
    try{
        let filter = dispatchObject.payload;
        let result = yield call(DAL.fetchProfiles, filter);
        // console.log('profile result:', result);
        if(result.profiles){
            yield put({type : ACTION.RESPONSE_PROFILES, data : result})
        }
        else{

        }
    }
    catch(ee){
        console.log('error:', ee);
        yield put({type : ACTION.RESPONSE_PROFILES_ERROR, data : {error : ee, message : 'Failed to Fetch Profile'}})
    }    
}

function  fetchFollower(dispatchObject : any){
}

function* followUser(dispatchObject : any){
    console.log('followUser..');
    let param = dispatchObject.payload;
    try{
        let result = yield call(DAL.followUser, param);
        console.log('result:', result);
        yield put({type : ACTION.RESPONSE_USER_FOLLOW, data : { status : result}})
    }
    catch(ee){
        console.log('followUser error:', ee);
        yield put({type : ACTION.RESPONSE_FOLLOW_ERROR, data : {error : ee, message : 'Couldn\'t follow the user.'}});
    }
}

function * getNotifications(dispatchObject : any){
    console.log('getting notifications...');
    let param = dispatchObject.payload;

    try{
        let result = yield call(DAL.getNotifications, param);
        console.log('result:', result);
        yield put({type : ACTION.RESPONSE_NOTIFICATIONS, data : result})
    }
    catch(ee){
        console.log('followUser error:', ee);
        yield put({type : ACTION.RESPONSE_NOTIFICATION_ERROR, data : {error : ee, message : 'Couldn\'t follow the user.'}});
    }


}

let ProfileService = 
{
    createProfile:createProfile,
    fetchProfile:fetchProfile,
    fetchFollower : fetchFollower,
    fetchProfiles : fetchProfiles,
    followUser : followUser,
    getNotifications : getNotifications
}

export default ProfileService;