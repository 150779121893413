import React from 'react';
import Image from 'react-bootstrap/Image';
import {Container} from "react-bootstrap";
import '../styles/PeopleComponent.css';
import placeholder from '../resources/placeholder.png';

const PeopleComponent = (props) => {

    const profile = props.profile;
    return (
        <Container className="profile">
            <Image className="profile-picture" src={profile.img ? profile.img : placeholder}/>
            <h1 className="profile-name">{profile ? profile.name : 'Loading'} </h1>
            {
                profile && profile.city && 
                <h2 className="profile-location">{profile.city}</h2>
            }
        </Container>
    );
};

export default PeopleComponent;