/**
 * @description Services are responsible for normalizing data returned from the DAL and publishing the payload along with an identifying action.
 * @author - Sonia
 */
import * as DAL from './servicelayer-dal'
import * as ACTION from './servicelayer-actions'
import {call, put} from 'redux-saga/effects'
import {Normalize} from './normalizer'

let resourceCategories = null;
function* fetchResourceCategories(dispatchObject)
{
  let searchTerm = dispatchObject.searchTerm;
    try{
      if (!resourceCategories) {
        let result = yield call(DAL.getResourceCategories);
        resourceCategories = Normalize.resourceCategories(result);
      }

      //filter
      if(searchTerm)
      {
        searchTerm = searchTerm.toLowerCase();
      }

      yield put({
        type: ACTION.RESPONSE_RESOURCECATEGORIES,
        data: (searchTerm ? resourceCategories.filter(x=>x.name.toLowerCase().indexOf(searchTerm) >=0) : resourceCategories)
      });
    }
    catch(e){
      yield put({
        type:ACTION.RESPONSE_RESOURCECATEGORIES,
        data:{error:e},
        isError:true
    });
    }
}


function* fetchResources(dispatchObject)
{
  let searchTerm = dispatchObject.searchTerm;
  let category = dispatchObject.categoryId;
    try{
      
        let result = yield call(DAL.getResources, category);
        let resources = Normalize.resources(result);
    
      //filter
      if(searchTerm)
      {
        searchTerm = searchTerm.toLowerCase();
      }

      yield put({
        type: ACTION.RESPONSE_RESOURCES,
        data: (searchTerm ? resources.filter(x=>x.name.toLowerCase().indexOf(searchTerm) >=0) : resources)
      });
    }
    catch(e){
      yield put({
        type:ACTION.RESPONSE_RESOURCES,
        data:{error:e},
        isError:true
    });
    }
}


const ResourceService =
{
  fetchResourceCategories:fetchResourceCategories,
  fetchResources:fetchResources
}

export default ResourceService;



