import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import { BsArrowLeft, BsFillGridFill } from "react-icons/bs";
import { GoThreeBars } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import * as ACTION from '../servicelayer/servicelayer-actions';
import '../styles/ResourcePage.css';
import Icon from './Icon';
import ResourceGrid from './ResourceGrid';

function ResourcePage() {

  const [displayType, setType] = useState("card");
  const [categoryId, setCategoryId] = useState(null);
  const resourceCategories = useSelector(state => state.resourceCategories ?? []);
  const resources = useSelector(state => state.resources ?? []);
  const fetchError = useSelector(state => state.errorMessage ?? "");
  const [searchTerm,setSearchTerm] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {

      let type = categoryId ? ACTION.REQUEST_RESOURCES : ACTION.REQUEST_RESOURCECATEGORIES;
      dispatch({type: type, searchTerm:searchTerm, categoryId:categoryId})

  }, [dispatch, searchTerm, categoryId]);
  
  const handleCategoryClick = (category)=> {
    console.log('handle Category click.', category)
    setCategoryId(category.id);
  }

  const handleResourceClick = (resource) => 
  {
    console.log("navigate to resource detail=>resource:", resource.website);
    if(resource.website){
      window.open(resource.website, "_blank");
    }
  }

  const handleSubmit = () => {
    console.log('handleSubmit');
  }
  /**
   * Updates search term
   * @param {object} e - triggering event
   */
  const searchChange = (e) => {
     setSearchTerm(e.target.value);
  }
  if (!categoryId) {
  return (
    <Container className="resource-page">
      <Row className={'search-header'}>
                <Col className={'search-input'}>
                    <InputGroup>
                        <FormControl placeholder="Search For Resources" onChange={searchChange} />
                        <InputGroup.Append>
                            <Button type="submit" variant="outline-secondary" onClick={() => handleSubmit()}><BiSearch /></Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
      <div className="icons">
        <Icon onClick={()=> setType("card")} color="gray">
            <BsFillGridFill className={displayType === "card" ? "active" : ""}/>
          </Icon>
        <Icon onClick={() => setType("list")} color="gray">
        <GoThreeBars className={displayType === "list" ? "active" : ""}/>
        </Icon>
      </div>
      {fetchError ?
        <Container>{fetchError}</Container>:
        <ResourceGrid resources={resourceCategories} type={displayType} onClick={handleCategoryClick} />
      }
    </Container>
  );
    }
    else
  {
    return (<Container>
      <Button className={"back-button"} onClick={() => setCategoryId(null)}><span><BsArrowLeft /></span></Button>
      {fetchError ?
        <Container>{fetchError}</Container> :
        <ResourceGrid resources={resources} type={displayType} onClick={handleResourceClick} />
      }
    </Container>)
    }
}

export default ResourcePage;
