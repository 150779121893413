import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {toShortMonthName} from "../util/data";
import '../styles/EventCard.css';


export const EventCard = ({event}) => {
  return (
    <Col lg={12} xl className="mb-5">
      <Card className="event-card">
        <Container>
          <Row className="align-items-center my-4">
            <Col xs={3} className="text-center pr-0">
              <span className="align-middle" height="100%">
                <div className="font-weight-light text-danger">{toShortMonthName(event.eventDate)}</div>
                <p className="h2 mt-n1">{event.eventDate.getDate()}</p>
              </span>
            </Col>
            <Col xs={9} className="pl-0">
              <div className="mb-1">
                <b>{event.name}</b>
              </div>
              <div className="text-muted">{event.location}</div>
              <div className="text-muted">{event.city}, {event.state}</div>
              <div className="text-muted">{event.time}</div>
            </Col>
          </Row>
          <Row>
            <Container className="event-card_hero pb-3">
              <Row className="text-right text-white pt-2 px-3">
                18 people going
              </Row>
              <Row className="pt-4 px-3">
                <p className="h1 font-weight-bolder">{event.name}</p>
              </Row>
            </Container>
          </Row>
        </Container>
        <Card.Footer className="text-center">
          <p className="h5 text-white font-weight-bold">RSVP for Event</p>
        </Card.Footer>
      </Card>
    </Col>
  );
};

const MyEvents = ({events}) => 
{
  /**
   * Parses events such that a max of three columns are rendered for each row.
   * @param {*} events 
   */
  const parseEvents = (events) => 
  {
    if(!events) return (<></>)
    
    let content = [];
    let currentRow = [];
    for(let i = 0; i < events.length; i++)
    {
      let _event = events[i];
      currentRow.push(<Col><EventCard key={_event.name} event={_event} /></Col>);
      if(currentRow.length % 3 === 0)
      {
        content.push(currentRow);
        currentRow = [];
      }
    }
    if(currentRow.length > 0)
    {
      content.push(currentRow);
    }
    return content;
  };
  return (<Container>{ parseEvents(events).map(row=>(<Row>{row}</Row>))}</Container>);
}

export default MyEvents;

