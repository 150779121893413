import Axios from 'axios';
const VoteInfoHelper = {
    API_KEY : 'AIzaSyC2FEpso60zfcidl-M--aFnYYGEwuQdZ14',
    API_CIVIC_ELECTION : 'https://www.googleapis.com/civicinfo/v2/elections',
    API_CIVIC_VOTER : 'https://www.googleapis.com/civicinfo/v2/voterinfo',
    API_CIVIC_REPRESENTATIVE : 'https://www.googleapis.com/civicinfo/v2/representatives',
    
    getElections : () => {
        let url = `${VoteInfoHelper.API_CIVIC_ELECTION}?key=${VoteInfoHelper.API_KEY}`;
        return Axios.get(url);
    },
    getVoterInfo : (address, electionID = null) => {
        let electionId = '';
        if(electionID) electionId = `&electionId=${VoteInfoHelper.API_CIVIC_ELECTION}`;

        let url = `${VoteInfoHelper.API_CIVIC_VOTER}?key=${VoteInfoHelper.API_KEY}&address=${address}${electionId}`;

        return Axios.get(url);
    },
    getRepresentativeInfoByAddress : (address) => {
        let url = `${VoteInfoHelper.API_CIVIC_REPRESENTATIVE}?key=${VoteInfoHelper.API_KEY}&address=${address}`;
        console.log('getRepresentativeInfoByAddress() url:',url);
        return Axios.get(url);
    }
    
}

export default VoteInfoHelper;