import '../styles/HomePage.css'
import React, { useEffect, useState } from 'react';
import { Row, Container, Col, Image } from 'react-bootstrap'
import { getBadges } from '../util/util';
import "../styles/BadgesPage.css";
const BadgesPage = (props) => {
    const [badges, setBadges] = useState([]);

    useEffect(_ => {
        //Getting badges
        getBadges(_badges => {
            setBadges(_badges);
        });

    }, []);
    const viewOpenLink = (link) => {
        window.open(link, '_blank');
    }
    return (<Container className="badges-page">
        <Row>
            <Col><p className={'title'}>Badges</p></Col>
        </Row>
        <Row>
            {
                badges.map(badge => {
                    // return <BadgeItem badge={badge} />
                    return <Col className={'badge'} xs={4} md={3} lg={2} onClick={() => viewOpenLink(badge.link)}>
                            <Image src={badge.image} className={'badgeImage'} roundedCircle fluid />
                            <p className={'badgeName'}>{badge.name}</p>
                            <p className={'required'}>Required Point:</p>
                            <p className={'score'}>{badge.score}</p>
                        </Col>;
                })
            }
        </Row>
    </Container>)
}

export default BadgesPage;