/**
 * Collection of redux 'actions' to be used in the redux-saga dispatch/reduce flow.
 * @author - Amonte
 */

 /**
  * Requests
  */

export const REQUEST_FEED = "REQUEST_FEED";
export const REQUEST_RESOURCECATEGORIES = "REQUEST_RESOURCES_CATEGORIES";
export const REQUEST_RESOURCES = "REQUEST_RESOURCES";
export const REQUEST_LOGIN="LOGIN";
export const REQUEST_SIGNUP="SIGNUP";
export const REQUEST_EVENTS="REQUEST_EVENTS";
export const REQUEST_CREATE_EVENT="REQUEST_CREATE_EVENT";
export const REQUEST_CREATE_POST="REQUEST_CREATE_POST";
export const REQUEST_CREATE_PROFILE="REQUEST_CREATE_PROFILE";
export const REQUEST_EVENT_DETAILS="REQUEST_EVENT_DETAILS";
export const REQUEST_FETCH_PROFILE="REQUEST_FETCH_PROFILE";
export const REQUEST_HANDLE_RSVP="REQUEST_HANDLE_RSVP";
/**added for session restore */
export const REQUEST_SESSION_RESTORE="REQUEST_SESSION_RESTORE";
/**request for fetching profile */
export const REQUEST_FOLLOWER_PROFILE="REQUEST_FOLLOWER_PROFILE";
/**request for all profiles */
export const REQUEST_PROFILES="REQUEST_PROFILES";
export const REQUEST_NOTIFICATIONS = "REQUEST_NOTIFICATIONS";

export const UPDATE_SESSION="UPDATE_SESSION";
export const REQUEST_LOGOUT="REQUEST_LOGOUT";
export const REQUEST_USER_FOLLOW="REQUEST_USER_FOLLOW";
/**
 * Responses
 */

export const RESPONSE_FEED = "RESPONSE_FEED";
export const RESPONSE_RESOURCECATEGORIES = "RESPONSE_RESOURCE_CATEGORIES";
export const RESPONSE_RESOURCES = "RESPONSE_RESOURCES";
export const RESPONSE_LOGINERROR="LOGIN_ERROR";
export const RESPONSE_LOGIN="LOGIN_RESPONSE";
export const RESPONSE_LOGOUT="LOGOUT_RESPONSE";
export const RESPONSE_SIGNUP="SIGNUP_RESPONSE";
export const RESPONSE_SIGNUP_ERROR="SIGNUP_ERROR";
export const RESPONSE_EVENTS="RESPONSE_EVENTS";
export const RESPONSE_EVENTS_ERROR="RESPONSE_EVENTS_ERROR";
export const RESPONSE_EVENT_CREATED="RESPONSE_EVENT_CREATED";
export const RESPONSE_CREATE_EVENT_ERROR="RESPONSE_CREATE_EVENT_ERROR";
export const RESPONSE_CREATE_POST="RESPONSE_CREATE_POST";
export const RESPONSE_CREATE_POST_ERROR="RESPONSE_CREATE_POST_ERROR";
export const RESPONSE_PROFILE_CREATED="RESPONSE_PROFILE_CREATED";
export const RESPONSE_IMAGE_UPLOAD_ERROR="IMAGE_UPLOAD_ERROR";
export const RESPONSE_CREATE_PROFILE_ERROR="RESPONSE_CREATE_PROFILE_ERROR";
export const RESPONSE_EVENT_DETAILS="RESPONSE_EVENT_DETAILS";
export const RESPONSE_ERROR_EVENT_DETAILS="RESPONSE_ERROR_EVENT_DETAILS";
export const RESPONSE_FETCH_PROFILE="RESPONSE_FETCH_PROFILE";
export const RESPONSE_HANDLE_RSVP="RESPONSE_HANDLE_RSVP";
export const RESPONSE_PROFILES_ERROR = "RESPONSE_PROFILES_ERROR";
/**Fetch profile related information */
export const RESPONSE_FOLLOWER_PROFILE = "RESPONSE_FOLLOWER_PROFILE";
export const RESPONSE_NOTIFICATIONS = "RESPONSE_NOTIFICATIONS";
export const RESPONSE_NOTIFICATION_ERROR = "RESPONSE_NOTIFICATION_ERROR";
/**profiles response */
export const RESPONSE_PROFILES = "RESPONSE_PROFILES";
export const RESPONSE_USER_FOLLOW = "RESPONSE_USER_FOLLOW";
export const RESPONSE_FOLLOW_ERROR = "RESPONSE_FOLLOW_ERROR";
/**create new event */
export const CLEAR_NEW_EVENT="CLEAR_NEW_EVENT";

/*
Phone confirm
*/
/**
 * Initiates the firebase verifyPhoneNumber flow.
 */
export const BEGIN_PHONE_CONFIRM="BEGIN_PHONE_CONFIRM";
/**
 * Inidicates the firebase verifyPhoneNumber flow has been started and is awaiting input of a confirmation code.
 */
export const PHONE_CONFIRM_PENDING="PHONE_CONFIRM_PENDING";
/**
 * Indicates the user has provided the confirm code sent by firebase during the verifyPhoneNumber flow.
 */
export const BEGIN_PHONE_CONFIRM_COMPLETE="BEGIN_PHONE_CONFIRM_COMPLETE";
export const PHONE_CONFIRM_COMPLETE="PHONE_CONFIRM_COMPLETE";
export const PHONE_CONFIRM_COMPLETE_ERROR="PHONE_CONFIRM_COMPLETE_ERROR";

