import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import { BsArrowLeft } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_PROFILES as ACTION_REQUEST_PROFILES } from '../servicelayer/servicelayer-actions';
import ProfileListItem from './ProfileListItem';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';
import '../styles/HomePage.css';
import "../styles/InviteFollower.css";
import { newConversation } from '../util/util';
const ChooseFollowing = (props) => {

    const dispatch = useDispatch();


    //const [searchTerm, setSearchTerm] = useState('');

    const [profiles, setProfiles] = useState([]);
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const loggedInUser = useSelector(state => state.user);
    const followerFetchResult = useSelector(state => state.fetchProfileResult);
    const post = props.post ?? null;
    //console.log('postDetail post:', post);

    useEffect(_ => {
        if (loggedInUser) {
            //console.log('loggedinUser:', loggedInUser);
            let param = buildFilter();
            dispatch({ type: ACTION_REQUEST_PROFILES, payload: param });
        }
    }, [loggedInUser]);

    useEffect(() => {

        if (followerFetchResult && followerFetchResult.profiles) {
            const newProfileSet = [...profiles, ...followerFetchResult.profiles];

            setProfiles(newProfileSet);
        }
    }, [followerFetchResult]);
    useEffect(() => {

        if (followerFetchResult && followerFetchResult.profiles) {
            const newProfileSet = [...profiles, ...followerFetchResult.profiles];

            setProfiles(newProfileSet);
        }
    }, [followerFetchResult]);
    const onProfileDetail = (profile) => {
        console.log('profile detail: profile=>', profile);
        window.open(`/ProfileDetail?id=${profile.id}`, '_self');
    }
    const fetchMoreFollowers = () => {
        console.log('fetchMoreFollowers..');
        let param = buildFilter();
        dispatch({ type: ACTION_REQUEST_PROFILES, payload: param });
    };

    const buildFilter = () => {
        console.log('buildFilter:');
        let param = {
            type: 'follower',
            uid: loggedInUser.uid,
            last: followerFetchResult && followerFetchResult.last
        };

        return param;
    }

    const onInviteEvent = () => {
        console.log('onInviteEvent...');
    }
    const validatePost = () => {
        if (post.type !== 'event') {
            post.uid = post.id ?? post.profileID
            post.id = post.postID ?? post.id
        }
        else {
            post.uid = post.id
            let _tags = [];
            (post.event.tags || []).forEach(tag => {
                if (tag != undefined) _tags.push(tag);
            });

            post.event.tags = _tags;

        }
        console.log('processed post=>', post);
    }
    const onSharePost = () => {
        console.log('onSharePost==> selectedProfiles:', selectedProfiles, ':post:', post);

        if (selectedProfiles.length < 1) {
            alert('Please choose a profile.');
            return;
        }
        validatePost();
        // return;
        newConversation(post, selectedProfiles, loggedInUser.uid, conversations => {
            alert('The post has been sent successfully.');
        }, true);
    }
    const onSelected = (selected, profile) => {
        // console.log('onSelected: selected', selected, profile);
        let selected_profiles = [...selectedProfiles];
        console.log('selected_profiles:', selected_profiles, ':selected:', selected);

        if (selected) {
            if (selected_profiles.findIndex(p => p.id == profile.id) == -1) {
                selected_profiles.push(profile);
                setSelectedProfiles(selected_profiles);
                return;
            }
        }
        else {

            let idx = selected_profiles.findIndex(p => p.id == profile.id);
            if (idx != -1) {
                selected_profiles.splice(idx);
                setSelectedProfiles(selected_profiles);
                return;
            }
        }
    }

    return (<Container className="invite-follower">
        <Row style={{ justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
                <div>
                    <Button className={"back-button"} onClick={() => props.onGoBack()}><span><BsArrowLeft /></span></Button>
                </div>
                <div className="title">Send a post</div>
            </div>
            <div className={'layout-share'} onClick={() => onSharePost()}>
                <p>Share the post</p>
            </div>
        </Row>
        {/* <Row>
            <Col>
                <InputGroup>
                    <FormControl placeholder="Search for followers" onChange={(e) => setSearchTerm(e.target.value)} />
                    <InputGroup.Append>
                        <Button type="submit" variant="outline-secondary" onClick={() => handleSubmit()}><BiSearch /></Button>
                    </InputGroup.Append>
                </InputGroup></Col>
        </Row> */}
        <Row style={{ marginTop: 15 }}>
            <Col>
                <InfiniteScroll initialLoad={false} loadMore={fetchMoreFollowers} hasMore={true}
                    loader={(<div className={'loading'}><ReactLoading type={'bubbles'} color={'#402366'} height={50} width={50} /></div>)}>
                    {profiles.map((profile, i) => {
                        //console.log('Event:', Event);
                        return (
                            <ProfileListItem profile={profile}
                                onClickProfile={(profile) => onProfileDetail(profile)}
                                isMultiSelect={true}
                                onSelected={selected => onSelected(selected, profile)} />
                        );
                    })}
                </InfiniteScroll>
            </Col>
        </Row>
    </Container>)
}

export default ChooseFollowing;