import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import verifiedIcon from '../resources/checkmark.jpg';
import placeholder from '../resources/placeholder.png';
import '../styles/HomePage.css';
import "../styles/ProfileListItem.css";
import { followUser, followUserPending, unfollowUser, inviteUserToEvent } from '../util/util';
import { Checkbox, useCheckboxState } from 'pretty-checkbox-react';

const ProfileListItem = (props) => {

    const dispatch = useDispatch();

    const profile = props.profile;
    const loggedInUser = useSelector(state => state.user);

    const [status, setStatus] = useState('unknown');
    const [invited, setInvited] = useState(false);
    const [checked, setChecked] = useState(false);

    const onChecked = () => {
        console.log('checkbox state:', checkbox.state);
        props.onSelected(!checkbox.state);
        checkbox.setState(!checkbox.state);
    }

    const checkbox = useCheckboxState(onChecked);

    const event = props.event ?? null;


    useEffect(_ => {

        if (profile && loggedInUser) {
            let uid = loggedInUser.uid;
            if (profile.followerArray) {
                if (profile.followerArray.indexOf(uid) !== -1) profile.following = true;
                else profile.following = false;
            }
            else profile.following = false;

            if (!profile.following) {
                //checking if following is allowed
                if (typeof profile.allowFollowRequest !== 'undefined') profile.allowedFollowing = !profile.allowFollowRequest;
                else profile.allowedFollowing = true;

                if (profile.pendingFollowingArray) {
                    if (profile.pendingFollowingArray.indexOf(uid) !== -1) profile.pendingFollowing = true;
                    else profile.pendingFollowing = false;
                }
                else profile.pendingFollowing = false;
            }
            setStatus(getStatusText(profile));
        }

    }, [profile, loggedInUser, dispatch]);

    const getStatusText = profile => {
        if (profile.following) return "Unfollow";
        if (profile.allowedFollowing) {

            if (!profile.following) {
                if (profile.following !== null) return "Follow";
                else return "Loading...";
            }
            else return "Unfollow";
        }
        else {
            if (profile.pendingFollowing) return "Requested";
            else return "Follow";
        }
    }

    const onClickFollow = () => {
        console.log('onClickFlow()....');
        if (profile.following) {
            //props.navigation.navigate('LoadingOverlaay')
            unfollowUser(profile.id, success => {
                if (success) {
                    profile.following = false;
                    setStatus(getStatusText(profile));
                }
            });
            return;

        }
        if (profile.allowedFollowing) {
            if (profile.following) {
                unfollowUser(profile.id, success => {

                    if (success) {
                        profile.following = false;
                        setStatus(getStatusText(profile));
                    }

                });
                return;
            } else {
                if (profile.following !== null) {
                    followUser(profile.id, profile.ref, success => {

                        if (success) {
                            profile.following = true;
                            setStatus(getStatusText(profile));
                        }

                    });
                    return;
                }
            }
        }
        else {
            if (!profile.pendingFollowing) {
                followUserPending(profile.id, success => {
                    if (success) {
                        profile.pendingFollowing = true;
                        setStatus(getStatusText(profile));
                    }

                });
                return;
            }
        }
        // dispatch({type : ACTION.REQUEST_USER_FOLLOW, payload : {profile_id : profile.id, status}});
    }
    const onClickInvite = () => {
        console.log('onClickInvite...');
        if (event) {
            inviteUserToEvent(event.id, event.name, loggedInUser.uid, profile.id, completion => {
                console.log('inviteUserToEvent result:', completion);
                setInvited(completion)
            })
        }

    }
   
    return (<Row className="profile-list" onClick={() => {if(!props.isMultiSelect) props.onClickProfile(profile)}}>
        <div style={{ width: props.isVertical ? 'auto' : '100%' }}>
            <div className={'profile-wrapper'} style={{ flexDirection: props.isVertical ? 'column' : 'row' }}>
                <div>
                    <div style={{ display: 'flex', paddingLeft: 12, paddingVertical: 8, flexDirection: props.isVertical ? 'column' : 'row' }}>
                        <img src={profile.imageUrl ? profile.imageUrl : placeholder} className={'image'} alt={'placeholder'} />
                        <div style={{ justifyContent: 'space-around', paddingLeft: 12, zIndex: 10 }}>
                            <div style={{ display: 'flex' }}>
                                <p className={'text-name'}>{profile ? profile.name ? profile.name : profile.username : null}</p>
                                {
                                    profile.isVerified &&
                                    <img src={verifiedIcon} style={{ marginLeft: 2, width: 20, height: 20 }} resizeMode="cover" alt={'verified'} />
                                }
                            </div>
                            <p className={'text-location'}>{profile && profile.location ? profile.location : null}</p>
                        </div>
                    </div>

                </div>
                {
                    status !== 'unknown' && profile && loggedInUser && profile.isAdmin !== true && profile.id !== loggedInUser.uid && !props.isInvite && !props.isMultiSelect &&
                    <div onPress={props.onFollowPress} className={'follow-layout'}>
                        {
                            status !== "Follow" && status !== "Unfollow" && status !== "Requested" && status !== 'Following' &&
                            <div style={{ alignSelf: 'center', borderRadius: 10, marginRight: 10, borderColor: 'red', borderWidth: 1, padding: 4, color: 'red' }}>{status}</div>
                        }
                        <Button style={{
                            border: '1px solid rgba(0, 0, 0)',
                            borderWidth: 1,
                            paddingLeft: 20,
                            paddingRight: 20,
                            marginRight: 10,
                            borderRadius: 25,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'transparent'
                        }}

                            onClick={() => onClickFollow()}>
                            <p className={'text-status'}>{status}</p>
                            {/* <Icon size={GlobalStyle.size_action_icon} color={props.rightText === "Follow" ? '#402366' : '#ffffff'}
                         name={props.rightText === "Follow" ? 'plus' :  'check'}></Icon> */}
                        </Button>
                    </div>
                }
                {
                    props.unBlockStyle &&
                    <div onPress={props.onUnblock} className={'unblock-layout'}>
                        <div style={{ alignSelf: 'center', borderRadius: 10, marginRight: 15, borderColor: 'black', borderWidth: 1, padding: 4, color: 'black' }}>Unblock</div>
                    </div>
                }
                {
                    props.isInvite &&
                    <Button style={{
                        border: '1px solid rgba(64, 35, 102)',
                        borderWidth: 1,
                        paddingLeft: 8,
                        paddingRight: 8,
                        marginRight: 10,
                        borderRadius: 10,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'transparent'
                    }}
                        disabled={invited}
                        onClick={() => onClickInvite()}>
                        <p className={'text-status'}>{invited ? 'Invited' : 'Invite'}</p>
                        {/* <Icon size={GlobalStyle.size_action_icon} color={props.rightText === "Follow" ? '#402366' : '#ffffff'}
                     name={props.rightText === "Follow" ? 'plus' :  'check'}></Icon> */}
                    </Button>
                }
                {
                    props.isMultiSelect &&
                    <form onSubmit={onChecked}>
                        <Checkbox className={'selected-checkbox'} name="tac" value="" {...checkbox} onChange={onChecked}>
                        </Checkbox>
                    </form>

                }

            </div>
            {props.isVertical ? <div></div> : <hr></hr>}
        </div>
    </Row>)
}

export default ProfileListItem;