/**
 * @description Reducers should never mutate the state.
 * @author - Amonte
 */
import * as ACTION from './servicelayer-actions'

export const serviceLayerReducer = (state, action) => {
    switch (action.type) {
        case ACTION.RESPONSE_FEED:
            if (!action.isError) {
                return { ...state, events: action.data.events, posts: action.data.posts, last: action.data.last }
            }
            else {
                let errorMessage = `Failed to fetch ${action.type}. Error:${action.data.error}`;
                throw new Error(errorMessage);
            }
        case ACTION.RESPONSE_RESOURCECATEGORIES:
            if (!action.isError) {
                return { ...state, resourceCategories: action.data }
            }
            else {
                let errorMessage = `Failed to fetch ${action.type}. Error:${action.data.error}`;
                throw new Error(errorMessage);
            }
        case ACTION.RESPONSE_RESOURCES:
            if (!action.isError) {
                return { ...state, resources: action.data }
            }
            else {
                let errorMessage = `Failed to fetch ${action.type}. Error:${action.data.error}`;
                throw new Error(errorMessage);
            }

        case ACTION.RESPONSE_LOGINERROR:
            return { ...state, loginError: action.data }
        case ACTION.RESPONSE_LOGIN:
            return { ...state, user: action.data };
        case ACTION.RESPONSE_LOGOUT:
            return { ...state, user: null };
        case ACTION.UPDATE_SESSION:
            return { ...state, user: action.data };
        case ACTION.RESPONSE_SIGNUP:
            return { ...state, newUser: action.data };
        case ACTION.RESPONSE_SIGNUP_ERROR:
            return { ...state, signupError: action.data }
        case ACTION.RESPONSE_EVENTS:
            return { ...state, eventFetchResult: action.data };
        case ACTION.RESPONSE_EVENTS_ERROR:
            return { ...state, eventsError: action.data };
        case ACTION.RESPONSE_EVENT_CREATED:
            delete state.createEventError;
            return { ...state, newEvent: action.data };
        case ACTION.RESPONSE_CREATE_EVENT_ERROR:
            return { ...state, createEventError: action.data };
        case ACTION.RESPONSE_EVENT_DETAILS:
            return { ...state, eventDetails: action.data };
        case ACTION.RESPONSE_CREATE_POST:
            delete state.createPostError;
            return { ...state, newPost: action.data };
        case ACTION.RESPONSE_CREATE_POST_ERROR:
            return { ...state, createPostError: action.data };
        case ACTION.RESPONSE_PROFILE_CREATED:
            return { ...state, newProfile: action.data };
        case ACTION.RESPONSE_CREATE_PROFILE_ERROR:
            return { ...state, createProfileError: action.data };
        case ACTION.RESPONSE_IMAGE_UPLOAD_ERROR:
            return { ...state, imageUploadError: action.data };
        case ACTION.RESPONSE_PROFILES_ERROR:
            return { ...state, fetchprofileError: action.data };
        case ACTION.RESPONSE_PROFILES:
            return { ...state, fetchProfileResult: action.data };
        case ACTION.RESPONSE_USER_FOLLOW:
            return { ...state, status: action.data };
        case ACTION.RESPONSE_NOTIFICATIONS:
            return { ...state, notificationFetchResult: action.data };
        case ACTION.CLEAR_NEW_EVENT:
            delete state.newEvent;
            return { ...state };
        case ACTION.PHONE_CONFIRM_PENDING:
            return { ...state, verificationId: action.data };
        case ACTION.PHONE_CONFIRM_COMPLETE:
            return { ...state, newUser: action.data, verificationId: null, phoneConfirmed: true };
        case ACTION.PHONE_CONFIRM_COMPLETE_ERROR:
            return { ...state, phoneConfirmError: action.data };
        case ACTION.RESPONSE_FETCH_PROFILE:
            return { ...state, fetchProfileResult: action.data };
        case ACTION.RESPONSE_HANDLE_RSVP:
            return { ...state, handleRsvpResult: action.data };
        case ACTION.RESPONSE_FOLLOWER_PROFILE:
            return { ...state, followerProfiles: action.data };
        default:
            return { ...state };
    }

}