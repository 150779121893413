import React, { useEffect, useState } from 'react';
import { Button, Col, Container, FormControl, InputGroup, Row } from 'react-bootstrap';
import { BiSearch } from 'react-icons/bi';
import { BsArrowLeft } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_PROFILES as ACTION_REQUEST_PROFILES } from '../servicelayer/servicelayer-actions';
import ProfileListItem from './ProfileListItem';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from 'react-loading';
import '../styles/HomePage.css';
import "../styles/InviteFollower.css";
const InviteFollower = (props) => {

    const dispatch = useDispatch();

    
    //const [searchTerm, setSearchTerm] = useState('');

    const [profiles, setProfiles] = useState([]);
    const loggedInUser = useSelector(state => state.user);
    const followerFetchResult = useSelector(state => state.fetchProfileResult);
    const event = props.event;

    useEffect(_ => {
      console.log('inviteFollowers event:', event);
    }, [event]);

    useEffect(_ => {
        
        if(loggedInUser){
            console.log('loggedinUser:', loggedInUser);
            let param = buildFilter();
            dispatch({type : ACTION_REQUEST_PROFILES, payload : param});
        }

    }, [loggedInUser]);
    
    useEffect(() => {

        if (followerFetchResult && followerFetchResult.profiles) {
            const newProfileSet = [...profiles, ...followerFetchResult.profiles];

            setProfiles(newProfileSet);
        }
    }, [followerFetchResult]);

    const onProfileDetail = (profile) => {
        console.log('profile detail: profile=>', profile);
        window.open(`/ProfileDetail?id=${profile.id}`,'_self');
    }
    const fetchMoreFollowers = () => {
        console.log('fetchMoreFollowers..');
        let param = buildFilter();
        dispatch({type : ACTION_REQUEST_PROFILES, payload : param });
    };

    const buildFilter = () => {
        console.log('buildFilter:');
        let param = {
            type : 'follower',
            uid : loggedInUser.uid,
            last : followerFetchResult && followerFetchResult.last
        };

        return param;
    }

    const onInviteEvent = () => {
        console.log('onInviteEvent...');
    }


    return (<Container className="invite-follower">
        <Row>
            <div>
                <Button className={"back-button"} onClick={() => props.onGoBack()}><span><BsArrowLeft /></span></Button>
            </div>
            <div className="title">Invite Follower</div>
            {/* {
                profiles.map((profile, i) => {
                    console.log('profile:', profile);
                    return (
                        <ProfileListItem />
                    );
                })
            } */}
        </Row>
        {/* <Row>
            <Col>
                <InputGroup>
                    <FormControl placeholder="Search for followers" onChange={(e) => setSearchTerm(e.target.value)} />
                    <InputGroup.Append>
                        <Button type="submit" variant="outline-secondary" onClick={() => handleSubmit()}><BiSearch /></Button>
                    </InputGroup.Append>
                </InputGroup></Col>
        </Row> */}
        <Row style={{marginTop : 15}}>
            <Col>
                <InfiniteScroll initialLoad={false} loadMore={fetchMoreFollowers} hasMore={true}
                    loader={(<div className={'loading'}><ReactLoading type={'bubbles'} color={'#402366'} height={50} width={50} /></div>)}>
                    {profiles.map((profile, i) => {
                        //console.log('Event:', Event);
                        return (
                            <ProfileListItem profile={profile} event = {event} onClickProfile={(profile) => onProfileDetail(profile)} isInvite = {true} />
                        );
                    })}
                </InfiniteScroll>
            </Col>
        </Row>
    </Container>)
}

export default InviteFollower;