import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { Container, Form, Col, FormControl, Row, Button, InputGroup, Alert, Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import TimePicker from 'rc-time-picker';
import CitySearchComponent from '../components/CitySearchComponent';
import ConfirmationBox from '../components/ConfirmationBox';
import CONSTANTS from '../util/data';
import {REQUEST_CREATE_EVENT, CLEAR_NEW_EVENT} from '../servicelayer/servicelayer-actions';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';
import '../styles/CreateEvent.css'


const CreateEvent = () => {
    const [eventImg, setEventImg] = useState({ name: "",preview: "", raw: "" });
    const [eventTitle, setEventTitle] = useState('');
    const [type, setType] = useState(CONSTANTS.EVENTS_TYPE[2].value);
    const [tags, setTags] = useState([]);
    const [startDate, setStartDate] = useState(CONSTANTS.TODAY);
    const [endDate, setEndDate] = useState(CONSTANTS.TODAY);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [description, setDescription] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [locationResult, setLocationResult] = useState(null);
    const newEvent = useSelector(state=>state.newEvent);
    const user = useSelector(state=>state.user);
    const validationError = useSelector(state=>state.createEventError);
    const dispatch = useDispatch();
    const userName = (user && user.name) ?? "User";
    let self = {};
    const citySearchElement = useRef();
    const renderOptions = (items) => {
        if (!items) return;
        return items.map((item, i) => (<option key={i} >{item.value}</option>))
    }

  const handleCitySelect = (selection) => {
      if(!selection.address_components) return;
      setLocationResult(selection);
      let _city = null, _state = null;
      selection.address_components.forEach(address =>
          {
              if(address.types.some(x=>x === "locality"))
              {
                  _city = address.long_name;
              }
              if(address.types.some(x=> x === "administrative_area_level_1"))
              {
                  _state = address.short_name;
              }
          });
      if(_city) setCity(_city);
      if(_state) setState(_state);
  }

    const handleCreateEvent = (e) => {
        let event = {
            startTime: startTime,
            endTime: endTime,
            startDate: startDate,
            endDate: endDate,
            description: description,
            eventTitle: eventTitle,
            eventType: type,
            tags: tags,
            city: city,
            state: state,
            location: locationResult,
            eventImg: eventImg.raw,
            creatorId: (user && user.uid) ?? "LRV1l3GxkEchni2fpLxwAiq2Bml1"
        }

        event.eventStartDate = startDate;

        if (startTime) {
            event.eventStartDate.setHours(startTime.substring(0, 2), startTime.substring(3, 5), 0);
        }
        event.eventEndDate = endDate;
        if (endTime) {
            event.eventEndDate.setHours(endTime.substring(0, 2), endTime.substring(3, 5), 0);
        }
        event.eventEndDate = endDate;
        e.preventDefault();
        dispatch({ type: REQUEST_CREATE_EVENT, payload: event });
    }

    const showValidationError = () => {
        if (validationError) {
            return (<Alert variant="danger">{validationError.errorMessage}</Alert>)
        }
    }

    const handleImgUpload = (e) => {
        if (e.target.files.length
            && (e.target.files[0].type === 'image/png'
            || e.target.files[0].type === 'image/jpg'
            || e.target.files[0].type === 'image/jpeg'
        )) {
            setEventImg({
                name: e.target.files[0].name,
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    const closeModal = () => {
        dispatch({ type: CLEAR_NEW_EVENT });
        
    }
 const clearForm = () => {
    setEventImg({ name: "",preview: "", raw: "" });
    setEventTitle('');
    setType(CONSTANTS.EVENTS_TYPE[2].value);
    setTags([]);
    setStartDate(CONSTANTS.TODAY);
    setEndDate(CONSTANTS.TODAY);
    setCity('');
    setState('');
    setDescription('');
    setStartTime('');
    setEndTime('');
    setLocationResult(null);
    citySearchElement.current.refs.input.value = "";
 };

useEffect(()=>{
    if(newEvent !== undefined)
    {
        clearForm();
    }
},[newEvent]);

    return (
        <div className={'create-event-layout'}>
    <Container className="create-event">
        <h3>New Event</h3> {showValidationError()}
        <Form onSubmit={handleCreateEvent} >
            <Row>
                <Col xs={12} md={4}> {/** Name, Title, Type, Tags */}
                    <Form.Row>
                        <Form.Label>* Name</Form.Label>
                        <Form.Control value={userName} readOnly ></Form.Control>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label>* Start Date</Form.Label>
                        <div className={'layout-flex'}>
                            <DatePicker className="form-control" selected={startDate}
                            onChange={date => {
                                let d = new Date(date);
                                d.setHours(0,0,0);
                                setStartDate(d);
                            } }
                            />@<TimePicker showSecond={false} use12Hours={true} placeholder="12:00 pm" minuteStep={5} onChange={value => {
                                if (value) setStartTime(value && value.format("HH:mm"));
                            } } />
                        </div>
                    </Form.Row>
                    <Form.Row onClick={() => { self.fileUpload.click(); }} className={'layout-flex'}>
                        <Form.Label>Upload Image</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                {eventImg.raw !== "" ? <img style={{height:"calc(1.5em + .75rem + 2px)"}}  src={eventImg.preview} alt="upload"/> :
                                <InputGroup.Text>
                                    <i className="fa fa-cloud-upload"></i>
                                </InputGroup.Text>
                                }
                            </InputGroup.Prepend>
                            <FormControl placeholder="Click to upload" value={eventImg.name}></FormControl>
                            <Form.File hidden onChange={handleImgUpload} accept="image/png, image/jpg, image/jpeg" ref={(me) => { self.fileUpload = me }} />
                        </InputGroup>
                    </Form.Row>
                </Col>
                <Col xs={12} md={4}> {/** Date, Location */}
                 
                    <Form.Row>
                        <Form.Label>* Title of Event</Form.Label>
                        <Form.Control onChange={(e) => setEventTitle(e.target.value)} value={eventTitle} placeholder="Event Title"></Form.Control>
                    </Form.Row>
                  
                    <Form.Row >
                        <Form.Label>* End Date</Form.Label>
                        <div className={'layout-flex'}>
                            <DatePicker className="form-control" selected={endDate}
                            onChange={date => {
                                let d = new Date(date);
                                d.setHours(0,0,0);
                                setEndDate(d);
                            } }
                            />@<TimePicker showSecond={false} use12Hours={true} placeholder="12:00 pm" minuteStep={5} onChange={value => {
                                if (value) setEndTime(value && value.format("HH:mm"));
                            } } />
                        </div>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label>Tags</Form.Label>
                        <Form.Control as="select" size="sm" multiple value={tags} onChange={(e)=>
                        { let value = Array.from(e.target.selectedOptions, option => option.value);
                            setTags(value); }} >
                            {renderOptions(CONSTANTS.TAGS)}
                        </Form.Control>

                    </Form.Row>
                </Col>
                <Col xs={12} md={4}>
                
                    <Form.Row>
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" value={type} onChange={e => setType(e.target.value)}  >
                            {renderOptions(CONSTANTS.EVENTS_TYPE)}
                        </Form.Control>
                    </Form.Row>
                    <Form.Row>
                    </Form.Row>
                    <Form.Row>
                        <Form.Label>* Location</Form.Label>
                        <CitySearchComponent parentRef={citySearchElement} placeholder="Enter the location of your event" onChange={handleCitySelect} ></CitySearchComponent>
                    </Form.Row>
                </Col>
            </Row>
            <Row>
                    <Col xs={12} md={4}></Col>
                    <Col xs={12} md={4} className={'layout-about'}>
                            <Form.Row>
                                <Form.Label>About</Form.Label>
                                <Form.Control as="textarea" value={description} onChange={(e)=> setDescription(e.target.value)} placeholder="Tell us about your event"  ></Form.Control>
                            </Form.Row>
                    </Col>
                    <Col xs={12} md={4}></Col>
                    
            </Row>
            <Row>
                <Col xs={12} md={4}></Col>
                <Col xs={12} md={4}><Button className="btn-create-event" type="submit">Create Event</Button></Col>
                <Col xs={12} md={4}></Col>
            </Row>
        {/* <Row>
            <Col></Col>
            <Col><Button type="button" onClick={clearForm}>Clear</Button></Col>
        </Row> */}
        </Form>
        <Modal show={newEvent !== undefined} onHide={closeModal}>
            <Modal.Header closeButton/>
            <Modal.Body>
                <ConfirmationBox header="Event Created"/>
            </Modal.Body>
            <Button onClick={closeModal}>OK</Button>
        </Modal>
    </Container>
    </div>
    )
}

export default CreateEvent;