/**
 * @file DisplayPost
 * @author - Sonia Saitawdekar
 */

import React, { useEffect, useState } from 'react';
import { Button, Col, Container, ResponsiveEmbed, Row, Dropdown, DropdownButton } from 'react-bootstrap';
import { BsCalendar, BsClockFill } from 'react-icons/bs';
import { HiAnnotation, HiDotsHorizontal, HiFlag, HiReply, HiThumbUp } from "react-icons/hi";
import { ImLocation } from 'react-icons/im';
import Iframe from 'react-iframe';
import { useDispatch, useSelector } from 'react-redux';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import * as ACTION from '../servicelayer/servicelayer-actions';
import '../styles/DisplayPost.css';
import { dislikePost, getProfileById, likePost } from '../util/util';
import PeopleComponent from "./PeopleComponent";
const ActionRow = ({ post, user, onLiked, onClickedComments, onClickedFlag, onClickShare }) => {

  //state
  const [liked, toggleLike] = useState(false)
  const [activeAction, setActiveAction] = useState("none")
  const [isShowShareDropdown, setIsShowShareDropdown] = useState(false);
  useEffect(() => {

    if (post && user) {
      toggleLike(post.likes.indexOf(user.uid) !== -1);
    }

  }, [post, user]);

  const like = () => {
    if (post && user) {
      if (post.likes.indexOf(user.uid) !== -1) {
        //already liked.
        dislikePost(post.id, post.post_id);
        post.likes = post.likes.filter(id => id !== user.uid);
        toggleLike(false);
        onLiked(false);
      }
      else {
        likePost(post.id, post.post_id);
        post.likes.push(user.uid);
        toggleLike(true);
        onLiked(true);
      }
    }
  }

  const onActiveActionChange = (action) => {
    console.log(`On->ActiveActionChange`, action);
    switch (action) {
      case 'comment':
        onClickedComments();
        break;
      case 'flag':
        onClickedFlag();
        break;
      case 'share':
        console.log('clicked: share:', isShowShareDropdown);
        setIsShowShareDropdown(!isShowShareDropdown);
        break;
      default:

    }
    setActiveAction(action);
  }
  const onSelectShare = (action) => {
    console.log('onSelectShare action:', action);
    
    if(action == 'share_facebook'){
      shareToFacebook();
    }
    else if(action == 'share_instagram'){
      shareToInstagram();
    }
    else if(action == 'share_send_post'){
      shareSendPost();

    }
    else{
      console.log('default option');
    }

  }
  const shareToFacebook = () => {

  }
  const shareToInstagram = () => {

  }
  const shareSendPost = () => {
    onClickShare()
  }
 
  return (<Row className="action-row">
    <Col>
      {/** Will probably create a component */}
      {/*<IconButton> */}
      <Button variant="Light"
        onClick={() => { like(); }} >
        <HiThumbUp className={liked ? "active" : ""} /></Button>
      {/** </IconButton> */}
    </Col>
    <Col>
      <Button variant="Light"
        onClick={() => { onActiveActionChange("comment") }}>
        <HiAnnotation className={activeAction === "comment" ? "active" : ""} /></Button>
    </Col>
    <Col>
      <Button variant="Light"
        onClick={() => { onActiveActionChange("flag") }}>
        <HiFlag className={activeAction === "flag" ? "active" : ""} /></Button>
    </Col>
    <Col>
      <Dropdown onSelect={onSelectShare}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <HiReply className={activeAction === "share" ? "active" : ""} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item eventKey="share_facebook">Share to facebook</Dropdown.Item>
          <Dropdown.Item eventKey="share_instagram">Share to instagram</Dropdown.Item>          
          <Dropdown.Item eventKey="share_send_post">Send a post</Dropdown.Item>
          <Dropdown.Item eventKey="share_cancel">Cancel</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  </Row>)
}

const DisplayPost = ({ post, onPostDetail, onPostFlag, onPostShare }) => {

  const dispatch = useDispatch();

  const [profile, setProfile] = useState(null);
  const loggedInUser = useSelector(state => state.user);
  const [isRSVPd, setRSVP] = useState(false);

  const [likes, setLikes] = useState(0);
  const [reposts, setReposts] = useState(0);
  const [comments, setComments] = useState(0);
  const [favorites, setFavorites] = useState(0);



  useEffect(() => {
    // console.log('post:', post);
    if (post) {

      setLikes(post.stats.likes);
      setReposts(post.stats.reposts);
      setComments(post.stats.comments);
      setFavorites(post.stats.favorites);

      if (post.prof) {
        let _prof = post.prof;
        _prof.img = post.prof.imageUrl;
        setProfile(post.prof);
      }
      else {
        getProfileById(post.uid, prof => {
          let _prof;
          _prof.img = prof.imageUrl;
          _prof.name = prof.name ? prof.name : prof.username ? prof.username : "Loading.";
          post.prof = _prof;
          setProfile(_prof);
        });
      }

      //set RSVPD status
      if (post && post.event) {
        if (loggedInUser) {
          setRSVP(post.event.rsvps.indexOf(loggedInUser.uid) > -1);
        }
      }
    }
  }, [post]);

  // Format the images if given, otherwise format the video if given
  const renderedMedia = [];
  if (post.imgs) {
    let width = 12 / ((post.imgs.length % 4 === 0) ? 4 : post.imgs.length % 4);
    if (post.imgs.length > 1) {

      post.imgs.map((img, i) => {
        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><img src={img} alt="post media" /></div>;
      }).forEach(imgCol => {
        renderedMedia.push(imgCol)
      });

    }
    else {
      post.imgs.map((img, i) => {
        return <Col key={i} xs={width === 12 ? width : 6} md={width} className="media-col"><img src={img} alt="post media" /></Col>;
      }).forEach(imgCol => {
        renderedMedia.push(imgCol)
      });
    }


  } else if (post.video) {
    renderedMedia.push(<Col xs={12} className="media-col"><ResponsiveEmbed aspectRatio="16by9"><Iframe src={post.video} title="post media" allowFullScreen /></ResponsiveEmbed></Col>);
  }

  /**
   * Returns days, hours, and minutes since post was posted
   * @param {Date} time - Date of post
   */
  function calculateTimeSince(time) {
    // console.log('calculateTimeSince:', new Date(time.seconds * 1000),':time:', time.seconds);
    let seconds = Math.floor((new Date() - new Date(time.seconds * 1000)) / 1000);
    let days = Math.floor(seconds / (60 * 60 * 24));
    seconds = seconds % (60 * 60 * 24);
    let hours = Math.floor(seconds / (60 * 60));
    seconds = seconds % (60 * 60);
    let minutes = Math.floor(seconds / 60);
    return days + "d " + hours + "h " + minutes + "m";
  }

  //rendering RSVP button in case of event
  const renderRSVP = () => {
    if (loggedInUser && loggedInUser.uid) {
      return (<Button className="button" onClick={handleRSVP} >{isRSVPd ? "Remove RSVP" : "RSVP Event"}</Button>)
    }

  }

  const handleRSVP = () => {
    let currentUserId = loggedInUser && loggedInUser.uid;

    //if user is not logged in, direct to sign up
    if (!currentUserId) {
      return;
    }

    dispatch({
      type: ACTION.REQUEST_HANDLE_RSVP, payload: {
        eventId: post.event.id,
        userId: currentUserId,
        action: isRSVPd ? "remove" : "add"
      }
    });
    setRSVP(!isRSVPd);
  }

  const likedPost = (liked) => {
    if (liked) post.stats.likes++;
    else post.stats.likes--;

    setLikes(post.stats.likes);
  }
  const clickedComments = () => {
    console.log('clickedComments');
    onPostDetail(post);
  }
  const clickedFlag = () => {
    console.log('clickedFlag=> post:', post);
    onPostFlag(post);
  }
  const clickedShare = () => {
    onPostShare(post);
  }

  return (
    <Container className="post">
      <Row className="media">
        {post && (post.imgs || []).length < 2 ? renderedMedia :
          <Slide
            style={{ width: '100%', alignItems: 'center', justifyContent: 'center', paddingLeft: 20, paddingRight: 20 }}
            cssClass="slider-wrapper"
          > {renderedMedia}</Slide>}
      </Row>
      <Row>
        <Col xs={10}>
          {profile && <PeopleComponent profile={profile} />}
        </Col>
        <Col xs={2}>
          <HiDotsHorizontal className="dots" />
        </Col>
      </Row>
      <hr />
      {
        post && post.type !== "event" &&
        <Row className="time">
          <Col>
            <p>{calculateTimeSince(post.time)}</p>
          </Col>
        </Row>
      }
      {
        post && post.type === "event" && post.event &&
        <Row className="event-detail">
          <Col>
            <p>
              <BsCalendar className={'icon'} />

              <span>{post.event.eventDate.toLocaleDateString([], { month: 'long', day: 'numeric', year: 'numeric' })}</span>
            </p>
          </Col>
          <Col>
            <p>
              <BsClockFill className={'icon'} />
              <span>{post.event.eventDate.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric' })}</span>
            </p>
          </Col>
          <Col>
            <p>
              <ImLocation className={'icon'} />
              {/* {event.location} */}
              <span>{`${post.event.city}, ${post.event.state}`}</span>
            </p>
          </Col>
        </Row>
      }
      {

      }
      {
        post && post.type && post.type === "event" && post.event &&
        <Row className="title">
          <Col>
            <p>{post.event.name}</p>
          </Col>
        </Row>
      }
      <Row className="content">
        {
          post && post.type !== "event" &&
          <Col>
            <p>{post.content}</p>
          </Col>
        }
        {
          post && post.type && post.type === "event" && post.event &&
          <Col>
            <p>{post.event.description && post.event.description.substring(0, post.event.description.length < 80 ? post.event.description.length : 250)}...</p>
          </Col>
        }

      </Row>
      <Row className="stats">
        <Col className="icon-wrapper">
          <p><span><HiThumbUp /></span>{likes}</p>
          <p><span><HiAnnotation /></span>{comments}</p>
          <p><span><HiFlag /></span>{reposts}</p>
          {/* <p><span><HiReply /></span>{favorites}</p> */}
        </Col>
      </Row>
      {
        post && post.type && post.type === "event" && post.event &&
        <Row>
          <Col>
            {renderRSVP()}
          </Col>
        </Row>
      }
      <hr />
      <ActionRow post={post} user={loggedInUser} onLiked={(liked) => likedPost(liked)} onClickedComments={() => clickedComments()} onClickedFlag={() => clickedFlag()} onClickShare = {() => clickedShare()}></ActionRow>
    </Container>

  );
};

export default DisplayPost;